var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "absoluteModal",
    {
      staticClass: "modal",
      style: _vm.getPosition(),
      attrs: { noTitleSpace: "", darkCard: "", showShadow: "" },
      on: { close: _vm.onClickOutside },
    },
    [
      _vm.type === "numeric" && _vm.config.headerData.allowFilter
        ? _c(
            "div",
            { staticClass: "filter slider" },
            [
              _c("div", { staticClass: "row flex" }, [
                _c("div", { staticClass: "flexInner" }, [
                  _vm._v(" Min value "),
                  _c("div", { staticClass: "input" }, [
                    _c("input", {
                      attrs: { type: "text" },
                      domProps: { value: _vm.inputMin },
                      on: {
                        blur: function ($event) {
                          return _vm.throttleFilter("min", $event)
                        },
                        keyup: function ($event) {
                          return _vm.throttleFilter("min", $event)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "flexInner" }, [
                  _vm._v(" Max value "),
                  _c("div", { staticClass: "input text-right" }, [
                    _c("input", {
                      attrs: { type: "text" },
                      domProps: { value: _vm.inputMax },
                      on: {
                        blur: function ($event) {
                          return _vm.throttleFilter("max", $event)
                        },
                        keyup: function ($event) {
                          return _vm.throttleFilter("max", $event)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c(
                "p-check",
                {
                  staticClass: "p-icon p-square p-fill p-smooth",
                  attrs: { name: "check", color: "primary" },
                  on: { change: _vm.updateAbsoluteValue },
                  model: {
                    value: _vm.absoluteValue,
                    callback: function ($$v) {
                      _vm.absoluteValue = $$v
                    },
                    expression: "absoluteValue",
                  },
                },
                [
                  _c("i", {
                    staticClass: "icon icofont-check",
                    attrs: { slot: "extra" },
                    slot: "extra",
                  }),
                  _vm._v(" Absolute values "),
                ]
              ),
              _c("i", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value:
                      "Allows the above filter to be applied to both positive and negative values\n (e.g. a range of 10 to 100 with absolute values on will allow values of 10-100 and -10 to -100)",
                    expression:
                      "'Allows the above filter to be applied to both positive and negative values\\n (e.g. a range of 10 to 100 with absolute values on will allow values of 10-100 and -10 to -100)'",
                  },
                ],
                staticClass: "icofont-question-circle",
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.type === "text"
        ? _c(
            "div",
            [
              _vm.config.headerData.allowFilter
                ? _c(
                    "p-check",
                    {
                      staticClass: "p-icon p-square p-fill p-smooth",
                      staticStyle: { "margin-bottom": "5px" },
                      attrs: { name: "check", color: "primary" },
                      on: { change: _vm.updateExcludeSelection },
                      model: {
                        value: _vm.excludeSelection,
                        callback: function ($$v) {
                          _vm.excludeSelection = $$v
                        },
                        expression: "excludeSelection",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "icon icofont-check",
                        attrs: { slot: "extra" },
                        slot: "extra",
                      }),
                      _vm._v(" Exclude selection "),
                    ]
                  )
                : _vm._e(),
              _vm.config.headerData.allowFilter
                ? _c("i", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value:
                          "When the Exclude button is clicked, the selected options below dictate what not to show.",
                        expression:
                          "'When the Exclude button is clicked, the selected options below dictate what not to show.'",
                      },
                    ],
                    staticClass: "icofont-question-circle",
                  })
                : _vm._e(),
              _vm.config.headerData.allowFilter &&
              !_vm.usePlantDropdown &&
              !_vm.useFuelDropdown
                ? _c("multiSelect", {
                    staticClass: "multiselect",
                    attrs: {
                      options: _vm.possibleTextOptions,
                      showLabels: false,
                      placeholder: "Select Option",
                      openDirection: "below",
                      multiple: true,
                      "close-on-select": false,
                      "clear-on-select": false,
                    },
                    on: { input: _vm.updateFilterText },
                    model: {
                      value: _vm.selectedOptions,
                      callback: function ($$v) {
                        _vm.selectedOptions = $$v
                      },
                      expression: "selectedOptions",
                    },
                  })
                : _vm._e(),
              _vm.config.headerData.allowFilter && _vm.usePlantDropdown
                ? [
                    _c("buttonHolder", {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: {
                        initialSelectedId: _vm.plantOption,
                        options: _vm.plantSelector,
                      },
                      on: {
                        select: function (o) {
                          return _vm.selectPlantOption(o.id)
                        },
                      },
                    }),
                    _vm.plantOption === "plant"
                      ? _c("multiselectPlant", {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: {
                            allowMultiple: "",
                            variableMultiselectHeight: "",
                            categoryOverrides: ["BmPlant", "NonBmPlant"],
                            initialArraySelect: _vm.selectedOptions,
                            fullWidth: "",
                            openDirection: "below",
                          },
                          on: { select: _vm.selectPlant },
                        })
                      : _c("multiSelect", {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: {
                            options: _vm.myPlantGroups,
                            showLabels: false,
                            placeholder: "Select group",
                            openDirection: "below",
                            label: "name",
                            trackBy: "id",
                          },
                          on: { input: _vm.updatePlantGroup },
                          model: {
                            value: _vm.selectedPlantGroup,
                            callback: function ($$v) {
                              _vm.selectedPlantGroup = $$v
                            },
                            expression: "selectedPlantGroup",
                          },
                        }),
                  ]
                : _vm._e(),
              _vm.config.headerData.allowFilter && _vm.useFuelDropdown
                ? _c("multiselectFuel", {
                    attrs: {
                      allowMultiple: "",
                      initialArraySelect: _vm.selectedOptions,
                      useId: false,
                      openDirection: "below",
                    },
                    on: { select: _vm.selectFuel },
                  })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.type === "date" && _vm.config.headerData.allowFilter
        ? _c(
            "div",
            [
              _c("dateRangePicker", {
                attrs: {
                  info:
                    this.config.currentFilters == null
                      ? {}
                      : _vm.config.currentFilters.options,
                  hideButtons: "",
                  includeNone: "",
                },
                on: { dateChange: _vm.dateChange },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.config.headerData.allowSort && _vm.allowSort
        ? _c("div", { staticClass: "filter slider" }, [
            _c("div", { staticClass: "filter-title" }, [
              _c("span", [_vm._v("Sort")]),
            ]),
            _c("div", { staticClass: "filter-content" }, [
              _c("div", { staticClass: "row flex" }, [
                _c("div", { staticClass: "flexInner" }, [
                  _c(
                    "div",
                    {
                      staticClass: "button",
                      class: _vm.sortingOrder === true ? "active" : "",
                      on: {
                        click: function ($event) {
                          return _vm.updateSort(true)
                        },
                      },
                    },
                    [_vm._v(" Low to High ")]
                  ),
                ]),
                _c("div", { staticClass: "flexInner" }, [
                  _c(
                    "div",
                    {
                      staticClass: "button",
                      class: _vm.sortingOrder === false ? "active" : "",
                      on: {
                        click: function ($event) {
                          return _vm.updateSort(false)
                        },
                      },
                    },
                    [_vm._v(" High to Low ")]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }