var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "headerRight" },
    [
      _vm.$store.state.ui.feedbackOpen
        ? _c("feedback", {
            staticClass: "nonMobile",
            on: { close: _vm.toggleFeedback },
          })
        : _vm._e(),
      _vm.dashboardShareOpen
        ? _c("dashboardShare", {
            staticClass: "nonMobile",
            on: { closeShare: _vm.toggleShare },
          })
        : _vm._e(),
      _vm.dashboardSaveOpen
        ? _c("dashboardSave", {
            staticClass: "nonMobile",
            on: { closeShare: _vm.toggleSave },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "routerLinks nonMobile" },
        [
          _vm._l(_vm.iconsToShow, function (icon, key) {
            return _c(
              "div",
              {
                key: key,
                staticClass: "headerIcon",
                staticStyle: { cursor: "pointer" },
              },
              [
                _vm.shouldShowOnHeader(icon)
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: icon.name,
                            expression: "icon.name",
                          },
                        ],
                        on: {
                          click: function ($event) {
                            return icon.click()
                          },
                        },
                      },
                      [
                        icon.fad
                          ? _c("font-awesome-icon", {
                              staticStyle: { marginLeft: "10px" },
                              attrs: { icon: ["fad", icon.icon] },
                            })
                          : _c("font-awesome-icon", {
                              staticStyle: { marginLeft: "10px" },
                              attrs: { icon: ["fas", icon.icon] },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            )
          }),
          _vm.sharePopoutOpen
            ? _c("sharePopout", {
                on: {
                  share: _vm.toggleShare,
                  save: _vm.toggleSave,
                  close: function ($event) {
                    _vm.sharePopoutOpen = false
                  },
                },
              })
            : _vm._e(),
        ],
        2
      ),
      _vm.hasNotifications().hasAccess &&
      _vm.pageName !== "NotificationsPage" &&
      _vm.$store.state.ui.selectedEnactView === "Enact"
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Notifications",
                  expression: "`Notifications`",
                },
              ],
              staticClass: "notificationsIcon animated nonMobile",
              class: [
                { active: _vm.unseenNotifications > 0 },
                _vm.heartbeatClass,
              ],
              style: _vm.$store.state.ui.notificationsOpen
                ? "z-index: 101"
                : "",
              on: { click: _vm.toggleNotifications },
            },
            [
              _c("font-awesome-icon", { attrs: { icon: ["fas", "bell"] } }),
              _vm.unseenNotifications > 0
                ? _c("span", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.unseenNotifications)),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      !_vm.hasNotifications().hasAccess
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value:
                    "Notifications (Requires " +
                    _vm.hasNotifications().accessRequired +
                    ")",
                  expression:
                    "`Notifications (Requires ${hasNotifications().accessRequired})`",
                },
              ],
              staticClass: "notificationsIcon iconDisabled nonMobile",
            },
            [
              _c("span", { staticClass: "icofont-alarm icon_disable" }),
              _c("disabled-icon", {
                staticClass: "disabledOverlay",
                attrs: {
                  accessLevel: _vm.hasNotifications().accessRequired,
                  showTooltip: false,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.loggedIn
        ? _c(
            "div",
            [
              _c("font-awesome-icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "My Account",
                    expression: "`My Account`",
                  },
                ],
                staticClass: "userIcon",
                attrs: { icon: ["fad", "user"] },
                on: { click: _vm.toggleUserModal },
              }),
              _vm.userModalOpen
                ? _c("userModal", { on: { close: _vm.toggleUserModal } })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.loggedIn
        ? _c(
            "div",
            { staticClass: "plantSearch nonMobile" },
            [
              _vm.$store.state.ui.selectedEnactView === "Enact"
                ? _c("enactSearch2", {
                    attrs: {
                      light: "",
                      categoryOverrides: ["BmPlant", "NonBmPlant"],
                    },
                    on: { select: _vm.goToPlantPage },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      !_vm.loggedIn
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "button smaller loginBtn",
                attrs: { id: "login" },
                on: { click: _vm.login },
              },
              [_vm._v(" Login ")]
            ),
            _c(
              "a",
              {
                staticClass: "button smaller loginBtn grapefruit",
                attrs: { href: "http://lcpenact.com", target: "_blank" },
              },
              [_vm._v(" Get Access ")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }