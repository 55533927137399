import { render, staticRenderFns } from "./loader.vue?vue&type=template&id=f93a6a90&scoped=true&"
import script from "./loader.vue?vue&type=script&lang=js&"
export * from "./loader.vue?vue&type=script&lang=js&"
import style0 from "./loader.vue?vue&type=style&index=0&id=f93a6a90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f93a6a90",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\Enact.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f93a6a90')) {
      api.createRecord('f93a6a90', component.options)
    } else {
      api.reload('f93a6a90', component.options)
    }
    module.hot.accept("./loader.vue?vue&type=template&id=f93a6a90&scoped=true&", function () {
      api.rerender('f93a6a90', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/layout/loader.vue"
export default component.exports