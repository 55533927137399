var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "niceScroll",
      class: _vm.colourModeClass,
      attrs: { id: "app" },
    },
    [
      _vm.$store.state.ui.dataLoaded &&
      !_vm.termsAgreed &&
      _vm.$route.name !== "denied"
        ? _c("terms")
        : _vm._e(),
      _vm.firstRouteRan
        ? _c("top-header", { on: { toggleFullscreen: _vm.toggleFullscreen } })
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "in-out" } },
        [!_vm.$store.state.ui.dataLoaded ? _c("loader") : _vm._e()],
        1
      ),
      _vm.$store.state.ui.accessDeniedModal.shown
        ? _c("accessDeniedModal")
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.$store.state.ui.notificationsOpen
            ? _c("notifications")
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.showGlobalNotification
            ? _c("globalNotification", {
                on: { closeModal: _vm.closeGlobalNotifications },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.firstRouteRan && _vm.termsAgreed ? _c("sideNav") : _vm._e(),
      _vm.termsAgreed || !_vm.loggedIn
        ? _c(
            "fullscreen",
            {
              ref: "fullscreen",
              staticClass: "appFullscreenWrapper",
              class: { hiddenOnDesktop: _vm.hideSidenav },
              attrs: {
                background:
                  _vm.colourMode === "Dark"
                    ? "#151517"
                    : _vm.colourMode === "Blue"
                    ? "#21416d"
                    : "#dcdcdc",
              },
              on: { change: _vm.toggleFullscreenChange },
            },
            [
              _c(
                "div",
                { attrs: { id: "main" } },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [_c("router-view")],
                    1
                  ),
                  _vm.$store.state.ui.slideOut.slideOutOpen
                    ? _c(
                        "mainModal",
                        [
                          _c(
                            _vm.slideComp,
                            _vm._b(
                              { tag: "component" },
                              "component",
                              _vm.slideProps,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("vue-snotify"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }