<template>
	<multiselect
		v-model="currentPlant"
		:options="allPlants"
		:custom-label="customLabel"
		:searchable="true"
		:close-on-select="true"
		:show-labels="false"
		:optionsLimit="2500"
		:multiple="allowMultiple"
		label="name"
		track-by="id"
        :disabled="disabled"
		:placeholder="placeholderOverride"
        :openDirection="openDirection"
		:class="autoWidth ? 'autoWidth' : ''"
		@input="emitValue"
	>
        <span class="multiselect_arrow" slot="caret">
            <font-awesome-icon :icon="['fas', 'search']" />
            </span>
        <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
            </span>
        </template>
		<template class="dropDownOption" slot="option" slot-scope="props">
			<div class="option__icon">
				<span
					class="icon"
					:class="`icofont-${props.option.icon}`"
					:style="{ background: props.option.colour }"
				></span>
			</div>
			<div class="option__desc">
				<span class="option__id">{{ props.option.name }}</span>
			</div>
		</template>
	</multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'multiselectFuel',
    props: {
        placeholderOverride: {
            type: String,
            default: 'Search for a fuel',
            required: false,
        },
        autoWidth: {
            type: Boolean,
        },
        allowMultiple: {
            type: Boolean,
            required: false,
            default: false,
        },
        initialArraySelect: {
            type: Array,
            required: false,
            default: () => [],
        },
        useId: {
            type: Boolean,
            default: false,
        },
        openDirection: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        onlyShowName: {
            type: Boolean,
            default: false,
        },
        excludeInterconnectors: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Multiselect,
    },
    data () {
        return {
            currentPlant: '',
        };
    },
    created () {
        if (this.initialArraySelect) {
            this.currentPlant = [];
            this.initialArraySelect.forEach(s => {
                const foundFuel = this.allPlants.find((e) => (this.useId ? e.id === s : e.name === s));
                if (foundFuel != null) this.currentPlant.push(foundFuel);
            });
        }
    },
    methods: {
        emitValue (value) {
            this.$emit('select', value);
        },
        customLabel ({ id, name }) {
            return this.onlyShowName ? name : `${id} - ${name}`;
        },
    },
    computed: {
        allPlants () {
            let fuels = this.$staticStore.state.data.fuelTypes.filter(el => el.searchable);
            if (this.excludeInterconnectors) fuels = fuels.filter(el => !el.id.toLowerCase().includes("interconnector"));
            return fuels;
        },
    },
};
</script>

<style lang="scss" scoped>
.dropDownOption {
	display: flex;
	.option__id {
		margin-top: 0px;
	}
}
::v-deep .multiselect__option {
	display: flex;
}

::v-deep .option__icon {
	margin-top: 10px;
	line-height: 30px;
}

.autoWidth {
	::v-deep .multiselect__content-wrapper {
		width: 300px;
	}
}
</style>
