var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "table", staticClass: "relative" }, [
    _c(
      "div",
      {
        ref: "scrollableTable",
        staticClass: "tableWrapper niceScroll",
        class: { curved: !_vm.sitsOnCard },
        style: {
          maxHeight: "calc(100% - " + _vm.footerHeight + "px)",
          minHeight: _vm.minHeightIs100
            ? "calc(100% - " + _vm.footerHeight + "px)"
            : "200px",
        },
        on: { scroll: _vm.tableScroll },
      },
      [
        _c(
          "table",
          { staticClass: "table" },
          [
            _vm.tableFilterBox.visible
              ? _c("genericHeaderFilter", {
                  staticClass: "filter",
                  style: {
                    top: _vm.secondHeaders.length > 0 ? "75px" : "30px",
                  },
                  attrs: {
                    config: _vm.tableFilterBox,
                    allowSort: _vm.shouldSort,
                  },
                  on: {
                    sortUpdate: _vm.sortUpdate,
                    filterUpdate: _vm.filterUpdate,
                    closeFilter: _vm.closeFilter,
                    resetFilter: _vm.filterReset,
                  },
                })
              : _vm._e(),
            _c("thead", [
              _c(
                "tr",
                { staticClass: "tr", staticStyle: { padding: "10px" } },
                [
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tableDataData.length === 0,
                          expression: "tableDataData.length === 0",
                        },
                      ],
                      staticClass: "td",
                    },
                    [_vm._v(" " + _vm._s(_vm.noDataMessage) + " ")]
                  ),
                  _vm._l(_vm.firstHeadersCalculated, function (header, key) {
                    return _c(
                      "th",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.tableDataData.length !== 0 &&
                              _vm.getColSpan(header, key) !== 0,
                            expression:
                              "tableDataData.length !== 0 && getColSpan(header, key) !== 0",
                          },
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: header.hoverText,
                              disabled: header.hoverText == null,
                            },
                            expression:
                              "{\n                                content: header.hoverText,\n                                disabled: header.hoverText == null\n                            }",
                          },
                        ],
                        key: "header_" + key,
                        staticClass: "th first fixedWidth",
                        class: {
                          nowrap: true,
                          hoverable: header.allowFilter || header.allowSort,
                          clickable: header.allowFilter || header.allowSort,
                          disabled:
                            (header.allowFilter || header.allowSort) &&
                            _vm.lockedAccount,
                          selectedRow: header.selected,
                          curveFirst: key === 0 && !_vm.sitsOnCard,
                          stickLeft: _vm.shouldStickLeft(header),
                        },
                        style: {
                          borderRight: header.borderRight,
                          fontSize: header.fontSizePixels + 1 + "px",
                        },
                        attrs: {
                          rowspan: header.isHeaderParent ? 1 : header.rowSpan,
                          colspan: _vm.getColSpan(header, key),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.showFilter(header, $event)
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.getHeaderTitle(header)) + " "),
                        !header.isHeaderParent &&
                        _vm.headerToSort.id == header.id
                          ? _c("span", {
                              staticClass: "filterSort",
                              class: _vm.headerToSort.ascending
                                ? "icofont-rounded-up"
                                : "icofont-rounded-down",
                            })
                          : _vm._e(),
                        !header.isHeaderParent &&
                        _vm.headerToSort.id == header.id &&
                        _vm.showMapIcon
                          ? _c("span", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: "Shown on map",
                                  expression: "'Shown on map'",
                                },
                              ],
                              staticClass: "filterSort",
                              class: "icofont-google-map",
                            })
                          : _vm._e(),
                        !header.isHeaderParent &&
                        _vm.currentFiltersByHeading.hasOwnProperty(header.id) &&
                        !_vm.currentFiltersByHeading[header.id].noFilter
                          ? _c("span", {
                              staticClass: "filterSort",
                              class: "icofont-filter",
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              ),
              _c(
                "tr",
                { staticClass: "tr" },
                [
                  _vm.showRowSelectors && _vm.secondHeaders.length > 0
                    ? _c("th", { staticClass: "th second fixedWidth" })
                    : _vm._e(),
                  _vm._l(_vm.secondHeaders, function (header, key) {
                    return _c(
                      "th",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.tableDataData.length !== 0,
                            expression: "tableDataData.length !== 0",
                          },
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: header.hoverText,
                              disabled: header.hoverText == null,
                            },
                            expression:
                              "{\n                                content: header.hoverText,\n                                disabled: header.hoverText == null\n                            }",
                          },
                        ],
                        key: "header_" + key,
                        staticClass: "th second fixedWidth",
                        class: {
                          nowrap: true,
                          hoverable: header.allowFilter || header.allowSort,
                          clickable: header.allowFilter || header.allowSort,
                          disabled:
                            (header.allowFilter || header.allowSort) &&
                            _vm.lockedAccount,
                          selectedRow: header.selected,
                          stickLeft: _vm.shouldStickLeft(header),
                          lastChild:
                            header.parentHeaderId &&
                            (key === _vm.secondHeaders.length - 1 ||
                              header.parentHeaderId !==
                                _vm.secondHeaders[key + 1].parentHeaderId),
                        },
                        style: {
                          borderRight: header.borderRight,
                          fontSize: header.fontSizePixels + 1 + "px",
                          top:
                            _vm.firstHeadersUnfiltered[0].fontSizePixels +
                            11 +
                            "px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.showFilter(header, $event)
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.getHeaderTitle(header)) + " "),
                        _vm.headerToSort.id == header.id
                          ? _c("span", {
                              staticClass: "filterSort",
                              class: _vm.headerToSort.ascending
                                ? "icofont-rounded-up"
                                : "icofont-rounded-down",
                            })
                          : _vm._e(),
                        !header.isHeaderParent &&
                        _vm.headerToSort.id == header.id &&
                        _vm.showMapIcon
                          ? _c("span", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: "Shown on map",
                                  expression: "'Shown on map'",
                                },
                              ],
                              staticClass: "filterSort",
                              class: "icofont-google-map",
                            })
                          : _vm._e(),
                        _vm.currentFiltersByHeading.hasOwnProperty(header.id) &&
                        !_vm.currentFiltersByHeading[header.id].noFilter
                          ? _c("span", {
                              staticClass: "filterSort",
                              class: "icofont-filter",
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c(
              "tbody",
              _vm._l(
                _vm.filteredData.slice(_vm.startToShow, _vm.endToShow),
                function (tableItem, key) {
                  return _c(
                    "tr",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            content: tableItem.hoverOver,
                            disabled: tableItem.hoverOver == null,
                          },
                          expression:
                            "{ content: tableItem.hoverOver, disabled: tableItem.hoverOver == null }",
                        },
                      ],
                      key: "row_" + key,
                      staticClass: "tr",
                      class: {
                        hoverable: _vm.showHover,
                        showCursor: _vm.showCursor,
                        selected: tableItem.rowSelected && _vm.showRowSelectors,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.rowClicked(tableItem, _vm.el)
                        },
                      },
                    },
                    [
                      _vm.showRowSelectors
                        ? _c(
                            "td",
                            [
                              _c(
                                "p-check",
                                {
                                  key: _vm.filteredData.length,
                                  staticClass: "p-icon p-fill p-smooth",
                                  on: {
                                    change: function (el) {
                                      return _vm.selectRow(tableItem, el)
                                    },
                                  },
                                  model: {
                                    value: tableItem.rowSelected,
                                    callback: function ($$v) {
                                      _vm.$set(tableItem, "rowSelected", $$v)
                                    },
                                    expression: "tableItem.rowSelected",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon icofont-check",
                                    attrs: { slot: "extra" },
                                    slot: "extra",
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(
                        _vm.orderedRow(tableItem.row),
                        function (item2, key) {
                          return _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: item2.tooltip,
                                    disabled: item2.tooltip == null,
                                  },
                                  expression:
                                    "{\n                                content: item2.tooltip,\n                                disabled: item2.tooltip == null\n                            }",
                                },
                              ],
                              key: "cell_" + key,
                              staticClass: "td",
                              class: {
                                selectedRow: tableItem.selected,
                                nowrap: !item2.allowWrap,
                                stickLeft: item2.freeze,
                                "flex-between": item2.isPlant,
                                flash1:
                                  item2.flash && item2.flashCounter % 2 === 0,
                                flash2:
                                  item2.flash && item2.flashCounter % 2 !== 0,
                                "td light": _vm.lightHover,
                                iconHolder: item2.valueToIcons,
                              },
                              style: {
                                borderRight: item2.borderRight,
                                color: _vm.getTextColour(item2),
                                backgroundColor: _vm.getBackgroundColour(item2),
                              },
                            },
                            [
                              item2.percent
                                ? _c("div", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "5px",
                                      "margin-bottom": "2px",
                                      "margin-top": "-7px",
                                    },
                                    style: {
                                      backgroundImage:
                                        _vm.getBackgroundImage(item2),
                                    },
                                  })
                                : _vm._e(),
                              item2.valueToIcons
                                ? _c(
                                    "span",
                                    _vm._l(
                                      _vm.getIcons(item2),
                                      function (icon, key) {
                                        return _c("span", {
                                          key: key,
                                          class: "iconCell " + icon.icon,
                                          style: {
                                            color: _vm.getIconTextColour(
                                              item2,
                                              icon.value
                                            ),
                                            "padding-right": "4px",
                                          },
                                        })
                                      }
                                    ),
                                    0
                                  )
                                : _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.getRealValue(item2, true, false)
                                      ),
                                    },
                                  }),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  )
                }
              ),
              0
            ),
          ],
          1
        ),
        _vm.showMoreData
          ? _c("div", { staticClass: "capped" }, [
              _vm._v(
                " Data displayed is capped. Find out how to access the full list "
              ),
              _c(
                "a",
                {
                  staticClass: "link",
                  attrs: { href: "http://lcpenact.com", target: "_blank" },
                },
                [_vm._v("here")]
              ),
              _vm._v(". "),
            ])
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "tableFooter hidden-sm hidden-xs",
        class: { floatingFooter: _vm.floatingFooter },
        style: { height: _vm.footerHeight + "px" },
      },
      [
        _c("div", [_vm._t("footerLeft")], 2),
        _c(
          "div",
          { staticClass: "defaultFooter" },
          [
            _c(
              "span",
              [
                _vm.showFooterInternal && !_vm.lockedAccount
                  ? _c("span", {
                      staticClass: "icofont-ui-edit editDownloadIcon",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.setTableFilter.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
                _c("genericTableFilter", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showTableFilter,
                      expression: "showTableFilter",
                    },
                  ],
                  attrs: {
                    internalFooterTeleport: _vm.internalFooterTeleport,
                    disableReorder: _vm.disableReorder,
                    tableUnit: _vm.unitLocationOverride,
                    firstHeaders: _vm.firstHeadersUnfiltered,
                    secondHeaders: _vm.secondHeadersUnfiltered,
                    headersToHide: _vm.columnsToHide,
                  },
                  on: {
                    orderChange: _vm.headerOrderChange,
                    tableUnitValue: _vm.tableUnitChange,
                    resetAllHeaderFilters: _vm.resetAllHeaderFilters,
                    close: function ($event) {
                      _vm.showTableFilter = false
                    },
                    hide: _vm.setColumnsToHide,
                  },
                }),
              ],
              1
            ),
            _c(
              _vm.internalFooterTeleport ? "portal" : "span",
              { tag: "component", attrs: { to: "tableDownload" } },
              [
                (_vm.internalFooterTeleport || _vm.showFooterInternal) &&
                _vm.allowExcel &&
                _vm.tableDataData.length !== 0
                  ? _c(
                      "download-excel",
                      {
                        attrs: {
                          fetch: _vm.getExcelData,
                          type: _vm.$store.state.user.excelAsCsv
                            ? "csv"
                            : "xls",
                          worksheet: "Data",
                          name:
                            _vm.excelFilename +
                            " at " +
                            this.$moment().format() +
                            (_vm.$store.state.user.excelAsCsv
                              ? ".csv"
                              : ".xls"),
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: "Download table",
                              expression: "'Download table'",
                            },
                          ],
                          staticClass: "editDownloadIcon",
                          attrs: {
                            icon: ["fas", "download"],
                            "aria-label": "Download table",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showInputSelector = true
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }