<template>

<!-- <div class="bg"> -->
<div class="modal" :style="`top: ${$staticStore.state.data.headerHeightPlusMargin - 10}px`">
    <i class="icofont-close closeModal" @click="closeModal" />
    <div class="niceScroll scrollWrapper">
        <div class="textToShow" v-for="(value, key) in textToShow" :key="key">
            <span class="date">{{value.date.format('DD/MM/YYYY h:mm a')}}: </span>
            <span class="text" v-html="value.text"></span>
        </div>
    </div>
    <transition name="fade" mode="out-in"></transition>
</div>
<!-- </div> -->

</template>

<script>

export default {
    props: {

    },
    data () {
        return {
        };
    },
    mounted () {
    },
    computed: {
        textToShow () {
            return this.$store.state.ui.globalNotificationToShow;
        },
    },
    methods: {
        closeModal () {
            this.$emit('closeModal');
        },
    },
};
</script>

<style lang="scss" scoped>
.modal {
    left: 0;
    right: 0;

    position: fixed;

    padding: 10px;
    z-index: 99;
    background-color: $ALTERNATIVE1TINT3;
    box-shadow: 0 8px 6px -6px black;

    .closeModal {
        cursor: pointer;
        float: right;
        font-size: 30px;
    }
    .scrollWrapper {
        max-height: 60vh;
        .textToShow {
            font-size: 20px;
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-start;
            .date {
                font-weight: 600;
                // padding-right: 10px;
                min-width: 250px;
                // align-self: stretch;
            }
            .text {

            }
        }
    }
}
</style>
