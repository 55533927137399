import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import moment from 'moment-timezone';
import series from '@/storeModules/series';
import liveTable from '@/storeModules/liveTable';
import subscriptions from '@/storeModules/subscriptions';
import user from '@/storeModules/user';
import auth from '@/storeModules/auth';
import realTimeConnection from '@/realTimeConnection';
import { DatePickerMethods } from '@/components/inputs/datePicker/datePickerMethods';
import { staticStore } from './staticStore';

Vue.use(staticStore);

Vue.use(require('vue-moment'), {
    moment,
});

Vue.moment.tz.setDefault('Europe/London');

Vue.use(realTimeConnection);

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        series,
        liveTable,
        subscriptions,
        user,
    },
    state: {
        ui: {
            pageAccessLevels: {},
            firstRouteRan: false,
            selectedEnactView: 'Enact',
            fullscreen: false,
            dashboardHasBoa: false,
            dashboardHasDatePicker: false,
            dashboardHasPeriodPicker: false,
            dashboardHasDayRange: false,
            bmLeaderboardHistoric: false,
            dataLoaded: false,
            feedbackOpen: false,
            feedbackPlantChange: false,
            notificationsOpen: false,
            menuOpen: false,
            slideOut: {
                slideOutObject: {
                    title: '',
                    width: 33,
                },
                slideOutOpen: false,
                slideOutComponent: null,
                slideOutProps: {},
            },
            globalNotificationToShow: [],
            showGlobalNotification: false,
            enableHistoricForecasts: false,
            isFreeUser: true,
            accessDeniedModal: {
                shown: false,
                text: '',
                type: null,
            },
            isLongTerm: false,
        },
        data: {
            seriesListening: [],
            modulesListening: [],
            // IF ADDING ANYTHING TO PAGE DATA - NEED TO ADD TO THE RESET OPTIONS TOO
            pages: {
                boaDashboard: {
                    analyticsMode: false,
                    analyticsOptions: {
                        edit: true,
                        addition: true,
                        minSel: true,
                        nonBm: true,
                        key: 0,
                        noticeDeviateFilter: -1,
                    },
                    data: {},
                    newBoa: [],
                    selectedPlantId: '',
                },
                boaComparer: {
                    data: {},
                },
                terreDashboard: {
                    data: {
                        dailyMode: true,
                    },
                },
                bingo: {
                    data: {
                        plantData: {},
                    },
                },
                map: {
                    showDatePicker: true,
                },
                windForecasting: {
                    showDatePicker: false,
                },
            },
            analysisDate: {
                date: null,
                period: null,
                periodsInDay: 48,
                periodStatus: 'present',
                livePeriod: null,
                realTimePeriod: null,
                ancillaryContracts: {
                    showDate: false,
                },
                historicForecast: Vue.moment().add(-1, 'days').toDate(),
                windForecastDate: {
                    from: Vue.moment().toDate(),
                    to: Vue.moment().add(7, 'days').toDate(),
                },
                dashboard: {
                    overwriteDashboardDate: false,
                },
                canActionWatchOnBothDayAndPeriod: true,
            },
            interconnectorLines: [],
            europeInterconnectorLines: [],
            lastHeartbeat: Vue.moment(),
            freqOutScoreSubmitCounter: 0,
        },
    },
    getters: {
        boaWindowType (state, getters) {
            const pageName = state.ui.pageName;
            if (getters.boaWindowTypeByPage.hasOwnProperty(pageName)) return getters.boaWindowTypeByPage[pageName];
            else return getters.boaWindowTypeByPage.all;
        },
        boaWindowTypeByPage (state) {
            if (state.user.boaWindowType == null) {
                if (state.user.useTradingPeriod) return { all: 'trading' };
                else return { all: 'current' };
            } else {
                if (typeof state.user.boaWindowType === 'string') {
                    return { all: state.user.boaWindowType };
                } else {
                    if (state.user.boaWindowType.hasOwnProperty('all')) return state.user.boaWindowType;
                    else return { all: 'current' };
                }
            }
        },
        potentiallyHasBoa: state => state.ui.pageName === 'boa' || state.ui.pageName === 'currentHolder',
        hasBoa: (state, getters) => getters.hasBoaNotLite || state.ui.pageName === 'currentHolder',
        hasBoaNotLite: state => state.ui.pageName === 'boa' || (state.ui.pageName === 'dashboard' && state.ui.dashboardHasBoa),
        hasBoaNotAnalytics: state => !state.data.pages.boaDashboard.analyticsMode || state.ui.pageName === 'dashboard' || state.ui.pageName === 'currentHolder',
        hasBoaAndNotAnalytics: state => (state.ui.pageName === 'boa' && !state.data.pages.boaDashboard.analyticsMode)
            || (state.ui.pagename === 'dashboard' && state.ui.dashboardHasBoa) || state.ui.pageName === 'currentHolder',

        fromHeaderDate: (state, getters) => DatePickerMethods.getDatesFromHeaderConfigOrOld(getters.headerDates, 'from').toDate(),
        toHeaderDate: (state, getters) => DatePickerMethods.getDatesFromHeaderConfigOrOld(getters.headerDates, 'to').toDate(),
        fromToHeaderDate: (state, getters) => ({ from: getters.fromHeaderDate, to: getters.toHeaderDate }),
        headerDates (state) {
            const pageName = state.ui.pageName;
            const pageDates = state.user.pages.pageDates;

            let dateObject = null;

            if (pageDates.hasOwnProperty(pageName)) {
                dateObject = pageDates[pageName].dateObject;
            } else {
                let toMoment;
                switch (pageName) {
                case 'windForecasting':
                    toMoment = Vue.moment().add(7, 'd');
                    break;
                case 'monthlySummary':
                    toMoment = Vue.moment().add(-1, 'month').endOf('month');
                    break;
                case 'dayAheadDashboard':
                    toMoment = Vue.moment().add(-1, 'd');
                    break;
                default:
                    toMoment = Vue.moment();
                }

                let fromMoment;
                switch (pageName) {
                case 'windForecasting':
                    fromMoment = Vue.moment().add(-7, 'd');
                    break;
                case 'monthlySummary':
                    fromMoment = Vue.moment().add(-3, 'month').startOf('month');
                    break;
                case 'dayAheadDashboard':
                    fromMoment = Vue.moment().add(-1, 'M');
                    break;
                default:
                    fromMoment = Vue.moment();
                }

                dateObject = {
                    from: { type: 'date', value: Vue.moment.unix(fromMoment) / 1000 },
                    to: { type: 'date', value: Vue.moment.unix(toMoment) / 1000 },
                    recentHour: false,
                    fromHours: 1,
                    toHours: 1,
                };
            }

            return { dateObject };
        },
    },
    mutations: {
        updateCountryAccess (state, accessList) {
            Vue.set(state.ui, 'pageAccessLevels', accessList);
        },
        incrementFreqOutScoreSubmit (state) {
            state.data.freqOutScoreSubmitCounter++;
        },
        selectEnactType (state, type) {
            state.ui.selectedEnactView = type;
        },
        accessDeniedModalText (state, type) {
            state.ui.accessDeniedModal.type = type.type;
            state.ui.accessDeniedModal.text = type.text;
            state.ui.accessDeniedModal.shown = true;
        },
        hideAccessDeniedModal (state) {
            state.ui.accessDeniedModal.shown = false;
        },
        setFreeUser (state, isFreeUser) {
            state.ui.isFreeUser = isFreeUser;

            if (isFreeUser) {
                state.user.terms.agreed = true;
            }
        },
        ranFirstRouteCode (state) {
            Vue.set(state.ui, 'firstRouteRan', true);
        },
        setDashboardHasBoa (state, data) {
            Vue.set(state.ui, 'dashboardHasBoa', data);
        },
        setDashboardHasDate (state, data) {
            Vue.set(state.ui, 'dashboardHasDatePicker', data);
        },
        setDashboardHasDayRange (state, data) {
            Vue.set(state.ui, 'dashboardHasDayRange', data);
        },
        setDashboardHasPeriod (state, data) {
            Vue.set(state.ui, 'dashboardHasPeriodPicker', data);
        },
        receivedGlobalNotification (state, data) {
            let dataToShow = data;
            if (data.bmrsDelay) {
                if (state.user.minutesDelayToReport > data.minutes) return;
                dataToShow = data.message;
            }

            // Remove any existing "Warning: Refresh Needed" notifications
            if (dataToShow.includes("Warning: Refresh Needed"))
            {
                state.ui.globalNotificationToShow = state.ui.globalNotificationToShow.filter(notification => !notification.text.includes("Warning: Refresh Needed"));
            }

            Vue.set(state.ui, 'showGlobalNotification', true);
            state.ui.globalNotificationToShow.push({
                date: moment(),
                text: dataToShow,
            });
        },
        changeForecastHistoryEnabled (state, data) {
            Vue.set(state.ui, 'enableHistoricForecasts', data);
        },
        removeGlobalNotification (state) {
            Vue.set(state.ui, 'showGlobalNotification', false);
            Vue.set(state.ui, 'globalNotificationToShow', []);
        },
        toggleFullscreen (state) {
            Vue.set(state.ui, 'fullscreen', !state.ui.fullscreen);
        },
        changeNoticeDeviateFilter (state, value) {
            Vue.set(state.data.pages.boaDashboard.analyticsOptions, 'noticeDeviateFilter', value);
        },
        clearBoaDashboardData (state) {
            state.data.pages.boaDashboard.data = {};
            state.data.pages.boaDashboard.newBoa = [];
        },
        clearBoaComparerData (state) {
            state.data.pages.boaComparer.data = {};
        },
        clearBingoData (state) {
            state.data.pages.bingo.data.plantData = {};
        },
        updateBoa (state, data) {
            state.data.pages.boaDashboard.newBoa.push(data);
        },
        updateHistoricForecastDate (state, date) {
            Vue.set(state.data.analysisDate, 'historicForecast', date);
        },
        updateWindForecastDate (state, date) {
            Vue.set(state.data.analysisDate, 'windForecastDate', date);
        },
        showAncillaryContractsDateChoice (state, shouldShow) {
            state.data.analysisDate.ancillaryContracts.showDate = shouldShow;
        },
        toggleSlideOutMenu (state) {
            Vue.set(state.ui.slideOut, 'slideOutOpen', !state.ui.slideOut.slideOutOpen);
        },
        closeSlideOutMenu (state) {
            Vue.set(state.ui.slideOut, 'slideOutOpen', false);
        },
        setPage (state, page) {
            Vue.set(state.ui, 'pageName', page);
        },
        overwriteDashboardDateChanged (state, overwriteStatus) {
            state.data.analysisDate.dashboard.overwriteDashboardDate = overwriteStatus;
        },
        updateBoaAnalyticsOptions (state, data) {
            Vue.set(state.data.pages.boaDashboard.analyticsOptions, 'edit', data.edit);
            Vue.set(state.data.pages.boaDashboard.analyticsOptions, 'addition', data.addition);
            Vue.set(state.data.pages.boaDashboard.analyticsOptions, 'minSel', data.minSel);
            Vue.set(state.data.pages.boaDashboard.analyticsOptions, 'nonBm', data.nonBm);
        },
        updatePageData (state, data) {
            if (data.hasBoa) {
                if (state.data.analysisDate.period === null) {
                    Vue.set(state.data.analysisDate, 'period', data.pageData.currentPeriod);
                    Vue.set(state.data.analysisDate, 'periodsInDay', data.pageData.periodsInDay);
                }
                Vue.set(state.data.pages[data.page].data, 'stackData', data.pageData.stackData.item);
                Vue.set(state.data.pages[data.page].data, 'keyMetrics', data.pageData.keyMetrics.item);
                Vue.set(state.data.pages.boaDashboard.data, 'nonBmUpdate', data.pageData.nonBmData);

                Vue.set(state.data.pages.boaDashboard.data, 'sentLast', data.pageData.sentLast);
                Vue.set(state.data.pages.boaDashboard.data, 'lastDate', data.pageData.lastDate);
                Vue.set(state.data.pages.boaDashboard.data, 'lastPeriod', data.pageData.lastPeriod);
                Vue.set(state.data.pages.boaDashboard.data, 'interconnectorBoas', data.pageData.interconnectorBoaAvailability);
                let lastStack = null;
                if (data.pageData.sentLast) {
                    lastStack = data.pageData.lastStack;
                }
                Vue.set(state.data.pages[data.page].data, 'stackDataFromLast', lastStack);

                if (state.data.pages.boaDashboard.analyticsMode) {
                    if (state.data.pages.boaDashboard.data.sentLast) {
                        Vue.set(state.data.pages.boaDashboard.data, 'stackData', state.data.pages.boaDashboard.data.stackDataFromLast);
                    }
                } else {
                    Vue.set(state.data.pages.boaDashboard.data, 'stackData', state.data.pages.boaDashboard.data.stackData);
                }
            } else if (state.ui.pageName === 'boaComparer') {
                if (state.data.analysisDate.period === null) {
                    Vue.set(state.data.analysisDate, 'period', data.pageData.currentPeriod);
                    Vue.set(state.data.analysisDate, 'periodsInDay', data.pageData.periodsInDay);
                }
                Vue.set(state.data.pages.boaComparer.data, 'stackDataCurrent', data.pageData.tableCurrent);
                Vue.set(state.data.pages.boaComparer.data, 'keyMetricsCurrent', data.pageData.metricsCurrent);
                Vue.set(state.data.pages.boaComparer.data, 'stackDataPlusOne', data.pageData.tablePlusOne);
                Vue.set(state.data.pages.boaComparer.data, 'keyMetricsPlusOne', data.pageData.metricsPlusOne);
                Vue.set(state.data.pages.boaComparer.data, 'stackDataPlusTwo', data.pageData.tablePlusTwo);
                Vue.set(state.data.pages.boaComparer.data, 'keyMetricsPlusTwo', data.pageData.metricsPlusTwo);
                Vue.set(state.data.pages.boaComparer.data, 'nonBmUpdate', data.pageData.nonBmPing);
                Vue.set(state.data.pages.boaComparer.data, 'datePeriodCurrent', data.pageData.datePeriodCurrent);
                Vue.set(state.data.pages.boaComparer.data, 'dateperiodPlusOne', data.pageData.datePeriodPlusOne);
                Vue.set(state.data.pages.boaComparer.data, 'dateperiodPlusTwo', data.pageData.datePeriodPlusTwo);
            } else {
                Vue.set(state.data.pages[data.page], 'data', data.pageData);
            }
        },
        toggleMenu (state) {
            state.ui.menuOpen = !state.ui.menuOpen;
        },
        openMenu (state) {
            state.ui.menuOpen = true;
        },
        switchType (state, isLongTerm) {
            state.ui.isLongTerm = isLongTerm;
        },
        closeMenu (state) {
            state.ui.menuOpen = false;
        },
        toggleTradingOptions (state) {
            state.ui.tradingOptions = !state.ui.tradingOptions;
        },
        setDataLoaded (state, data) {
            state.ui.dataLoaded = data;
        },
        setCanActionWatchOnBothDayAndPeriod (state, data) {
            state.data.analysisDate.canActionWatchOnBothDayAndPeriod = data;
        },
        toggleFeedback (state, plantChange = false) {
            state.ui.feedbackOpen = !state.ui.feedbackOpen;
            state.ui.feedbackPlantChange = plantChange;
        },
        toggleNotifications (state) {
            state.ui.notificationsOpen = !state.ui.notificationsOpen;
        },
        toggleNotificationsOff (state) {
            state.ui.notificationsOpen = false;
        },
        resetBeforeRoute (state) {
            // state.ui.menuOpen = false;
            state.data.pages.boaDashboard.analyticsMode = false;
            state.ui.tradingOptions = false;
        },
        updateAnalysisDate (state, data) {
            state.data.analysisDate.date = data;
        },
        updatePeriodsInDay (state, data) {
            state.data.analysisDate.periodsInDay = data;
        },
        updateAnalysisPeriod (state, period) {
            state.data.analysisDate.period = period;
        },
        keyMarketMetricsMergeBoa (state, data) {
            const period = state.data.analysisDate.period;
            const dateString = state.data.analysisDate.date.format('YYYY-MM-DDT00:00:00');
            if (data.datePeriod.period === period && data.datePeriod.date === dateString) {
                Vue.set(state.data.pages.boaDashboard.data, 'keyMetrics', data.item);
            }
        },
        keyMarketMetricsMergeComparer (state, data) {
            const periodBefore = state.data.pages.boaComparer.data.datePeriodCurrent.period;
            const periodNow = state.data.pages.boaComparer.data.dateperiodPlusOne.period;
            const periodAfter = state.data.pages.boaComparer.data.dateperiodPlusTwo.period;
            const dateBefore = state.data.pages.boaComparer.data.datePeriodCurrent.date;
            const dateNow = state.data.pages.boaComparer.data.dateperiodPlusOne.date;
            const dateAfter = state.data.pages.boaComparer.data.dateperiodPlusTwo.date;

            if (data.datePeriod.period === periodBefore && data.datePeriod.date === dateBefore) {
                Vue.set(state.data.pages.boaComparer.data, 'keyMetricsCurrent', data.item);
            }
            if (data.datePeriod.period === periodNow && data.datePeriod.date === dateNow) {
                Vue.set(state.data.pages.boaComparer.data, 'keyMetricsPlusOne', data.item);
            }
            if (data.datePeriod.period === periodAfter && data.datePeriod.date === dateAfter) {
                Vue.set(state.data.pages.boaComparer.data, 'keyMetricsAfter', data.item);
            }
        },
        nonBmStorUpdateMerge (state, data) {
            // only proccess if not in analytics Mode
            Vue.set(state.data.pages.boaDashboard.data, 'nonBmUpdate', data);
        },
        updateVisibleCharts (state, chartName) {
            const index = state.user.pages.dashboard.visibleCharts.findIndex((i) => i === chartName);
            if (index > -1) state.user.pages.dashboard.visibleCharts.splice(index, 1);
        },
        setPeriodStatus (state, data) {
            const periodInfo = data.periodInfo;
            const boaWindowType = data.boaWindowType;

            let livePeriod = null;
            state.data.analysisDate.realTimePeriod = periodInfo.current.period;

            livePeriod = periodInfo[boaWindowType].period;
            if (state.data.analysisDate.period === null) {
                state.data.analysisDate.period = livePeriod;
            }
            state.data.analysisDate.livePeriod = livePeriod;
            state.data.analysisDate.realTimeDate = Vue.moment(periodInfo[boaWindowType].date);

            if (state.data.analysisDate.period !== null) {
                if (livePeriod === state.data.analysisDate.period && state.data.analysisDate.date.isSame(Vue.moment(), 'day')) {
                    state.data.analysisDate.periodStatus = 'present';
                } else if (livePeriod > state.data.analysisDate.period && state.data.analysisDate.date.isSame(Vue.moment(), 'day')
                    || state.data.analysisDate.date.isAfter(Vue.moment(), 'day')) {
                    state.data.analysisDate.periodStatus = 'future';
                } else {
                    state.data.analysisDate.periodStatus = 'past';
                }
            }
        },
        toggleAnalyticsMode (state) {
            state.data.pages.boaDashboard.analyticsMode = !state.data.pages.boaDashboard.analyticsMode;
            if (state.data.pages.boaDashboard.analyticsMode) {
                if (state.data.pages.boaDashboard.data.sentLast) {
                    Vue.set(state.data.pages.boaDashboard.data, 'stackData', state.data.pages.boaDashboard.data.stackDataFromLast);
                }
            } else {
                Vue.set(state.data.pages.boaDashboard.data, 'stackData', state.data.pages.boaDashboard.data.stackData);
            }
        },
        restartAnalytics (state) {
            state.data.pages.boaDashboard.analyticsOptions.key++;
        },
        initiateSlideOutContent (state, data) {
            Vue.set(state.ui.slideOut, 'slideOutObject', data.data);
            Vue.set(state.ui.slideOut, 'slideOutComponent', data.component);
            Vue.set(state.ui.slideOut, 'slideOutProps', data.props);
            Vue.set(state.ui.slideOut, 'slideOutOpen', true);
        },
        setSharedDataState (state, data) {
            Vue.set(state.data, 'analysisDate', data.analysisDate);
            Vue.set(state.data.pages, data.pageType, data.pageData);
        },

        setBingoData (state, data) {
            Vue.set(state.data.pages.bingo.data, 'plantData', data);
        },
        addBingoData (state, data) {
            Vue.set(state.data.pages.bingo.data.plantData, data.plantId, data);
        },
        removeSinglePlantBingo (state, data) {
            Vue.delete(state.data.pages.bingo.data.plantData, data);
        },
        updateBingoObject (state, data) {
            Vue.set(state.data.pages.bingo.data.plantData, data.plantId, data);
        },
        setRealTimeHeartbeat (state, data) {
            state.data.lastHeartbeat = data;
        },
        jumpToCurrentPeriod (state) {
            Vue.set(state.data.analysisDate, 'period', state.data.analysisDate.livePeriod);
            Vue.set(state.data.analysisDate, 'periodStatus', 'present');
            Vue.set(state.data.analysisDate, 'date', state.data.analysisDate.realTimeDate);
        },
        addInterconnectorLines (state, data) {
            state.data.interconnectorLines = data;
        },
        addEuropeInterconnectorLines (state, data) {
            state.data.europeInterconnectorLines = data;
        },
        showMapPeriodPicker (state, show) {
            state.data.pages.map.showDatePicker = show;
        },
        showWindForecastingDatePicker (state, show) {
            state.data.pages.windForecasting.showDatePicker = show;
        },
        subscribeToPage (state, data) {
            realTimeConnection.addPageSubscription(data);
        },
        unsubscribeToPage (state, data) {
            realTimeConnection.removePageSubscription(data);
        },
        subscribeToModule (state, data) {
            realTimeConnection.addModuleSubscription(data);
            if (data.includes('otification')) return;
            const index = state.data.modulesListening.find((i) => i === data);
            if (index < 0 || index == null) {
                state.data.modulesListening.push(data);
            }
        },
        unsubscribeToModule (state, data) {
            realTimeConnection.removeModuleSubscription(data);
            const index = state.data.modulesListening.find((i) => i === data);
            if (index >= 0) {
                state.data.modulesListening.splice(index, 1);
            }
        },
        addSeriesToList (state, data) {
            realTimeConnection.addToSeries(data);

            const index = state.data.seriesListening.find((i) => i === data);
            if (index < 0 || index == null) {
                state.data.seriesListening.push(data);
            }
        },
        wipeSeriesList (state) {
            Vue.set(state.data, 'seriesListening', []);
            Vue.set(state.series, 'chart', {});
            Vue.set(state.series, 'plant', {});
        },
        wipeModuleList (state) {
            Vue.set(state.data, 'modulesListening', []);
        },
        setTerreDailyStatus (state, status) {
            Vue.set(state.data.pages.terreDashboard.data, 'dailyMode', status);
        },
        resetBoa (state) {
            state.data.pages.boaDashboard.newBoa = [];
        },
        // ### realtimeMerge action mutations - Start ###
        // Boa
        updateBoaStackAcceptedBids (state, data) {
            Vue.set(state.data.pages.boaDashboard.data.stackData.acceptedBids, data.index, data.data);
        },
        updateBoaStackAcceptedOffers (state, data) {
            Vue.set(state.data.pages.boaDashboard.data.stackData.acceptedOffers, data.index, data.data);
        },
        addBoaStackTableBids (state, data) {
            state.data.pages.boaDashboard.data.stackData.tableBids.data.push(data);
        },
        addBoaStackAcceptedBids (state, data) {
            state.data.pages.boaDashboard.data.stackData.acceptedBids.push(data);
        },
        addBoaStackAcceptedOffers (state, data) {
            state.data.pages.boaDashboard.data.stackData.acceptedOffers.push(data);
        },
        // tableBids
        // modifyTable - boaComparer
        updateStackDataCurrentTableBids (state, data) {
            Vue.set(state.data.pages.boaComparer.data.stackDataCurrent.tableBids.data, data.index, data.data);
        },
        updateStackDataPlusOneTableBids (state, data) {
            Vue.set(state.data.pages.boaComparer.data.stackDataPlusOne.tableBids.data, data.index, data.data);
        },
        updateStackDataPlusTwoTableBids (state, data) {
            Vue.set(state.data.pages.boaComparer.data.stackDataPlusTwo.tableBids.data, data.index, data.data);
        },
        addStackDataCurrentTableBids (state, data) {
            state.data.pages.boaComparer.data.stackDataCurrent.tableBids.data.push(data);
        },
        addStackDataPlusOneTableBids (state, data) {
            state.data.pages.boaComparer.data.stackDataPlusOne.tableBids.data.push(data);
        },
        addStackDataPlusTwoTableBids (state, data) {
            state.data.pages.boaComparer.data.stackDataPlusTwo.tableBids.data.push(data);
        },
        // modifyTable - boaDashboard
        updateStackDataBoaTableBids (state, data) {
            Vue.set(state.data.pages.boaDashboard.data.stackData.tableBids.data, data.index, data.data);
        },
        addStackDataBoaTableBids (state, data) {
            state.data.pages.boaDashboard.data.stackData.tableBids.data.push(data);
        },
        // tableOffers
        // modifyTable - boaComparer
        updateStackDataCurrentTableOffers (state, data) {
            Vue.set(state.data.pages.boaComparer.data.stackDataCurrent.tableOffers.data, data.index, data.data);
        },
        updateStackDataPlusOneTableOffers (state, data) {
            Vue.set(state.data.pages.boaComparer.data.stackDataPlusOne.tableOffers.data, data.index, data.data);
        },
        updateStackDataPlusTwoTableOffers (state, data) {
            Vue.set(state.data.pages.boaComparer.data.stackDataPlusTwo.tableOffers.data, data.index, data.data);
        },
        addStackDataCurrentTableOffers (state, data) {
            state.data.pages.boaComparer.data.stackDataCurrent.tableOffers.data.push(data);
        },
        addStackDataPlusOneTableOffers (state, data) {
            state.data.pages.boaComparer.data.stackDataPlusOne.tableOffers.data.push(data);
        },
        addStackDataPlusTwoTableOffers (state, data) {
            state.data.pages.boaComparer.data.stackDataPlusTwo.tableOffers.data.push(data);
        },
        // modifyTable - boaDashboard
        updateStackDataBoaTableOffers (state, data) {
            Vue.set(state.data.pages.boaDashboard.data.stackData.tableOffers.data, data.index, data.data);
        },
        addStackDataBoaTableOffers (state, data) {
            state.data.pages.boaDashboard.data.stackData.tableOffers.data.push(data);
        },
        bingoPlantDataUpdateFalse (state, plantId) {
            Vue.set(state.data.pages.bingo.data.plantData[plantId], 'update', 0);
        },
        // ### realtimeMerge action mutations - End ###
    },
    actions: {
        setDefaultCountryAndPageAccess ({
            dispatch,
        }, country) {
            dispatch('user/setDefaultCountry', country).then(() => {
                dispatch('getCountryPageAccess');
            });
        },
        getCountryPageAccess ({
            commit,
            state,
        }) {
            axios.get(`${process.env.VUE_APP_STATIC_API}/staticApi/pageAccess/${state.user.country}`).then((response) => {
                commit('updateCountryAccess', response.data);
            });
        },
        selectEnactType ({
            commit,
        }, enactType) {
            commit('selectEnactType', enactType);
            // dispatch('updateBasicUserSettings');
            localStorage.setItem('enactType', enactType);
        },
        setDate ({
            commit, dispatch, getters, state,
        }, data) {
            if (state.ui.pageName !== 'mapPage' && state.ui.pageName !== 'dashboard' && state.ui.pageName !== 'terreEuropeView'
                && state.ui.pageName !== 'plantActivityBoard' && state.ui.pageName !== 'europeMapPage' && state.ui.pageName !== 'zonalBuildUp') {
                state.ui.dataLoaded = false;
            }
            commit('updateAnalysisPeriod', data.period);
            if (data.changingDateAndPeriod) commit('setCanActionWatchOnBothDayAndPeriod', false);
            return dispatch('updateAnalysisDate', data.date).then(() => {
                dispatch('getCurrentPeriod')
                    .then(() => {
                        if (getters.hasBoa) {
                            dispatch('getBoaData').then(() => {}).catch(e => {}).finally(() => {
                                commit('setDataLoaded', true);
                            });
                        }
                        switch (state.ui.pageName) {
                        case 'terreDashboard':
                            commit('setDataLoaded', true);
                            break;
                        case 'boaComparer':
                            dispatch('getBoaComparerData')
                                .then(() => {
                                    commit('setDataLoaded', true);
                                });
                            break;
                        default:
                        }
                    });
            });
        },
        keyMarketMetricsMerge ({ commit, state, getters }, data) {
            // only proccess if not in analytics Mode
            if (getters.hasBoaNotAnalytics) {
                if (getters.hasBoa) {
                    commit('keyMarketMetricsMergeBoa', data);
                }
                if (state.ui.pageName === 'boaComparer') {
                    commit('keyMarketMetricsMergeComparer', data);
                }
            }
        },
        clearPageData ({ commit, getters }, pageName) {
            if (!getters.potentiallyHasBoa) commit('clearBoaDashboardData');
            switch (pageName) {
            case ('boaComparer'):
                commit('clearBoaComparerData');
                break;
            case ('bingoCard'):
                commit('clearBingoData');
                break;
            }
        },
        feedUpdate ({ commit }, data) {
            commit('updateBmFeed', data);
        },
        initiateSlideOutPlant ({ commit }, data) {
            commit('initiateSlideOutContent', {
                data: { title: 'Plant Information' },
                component: 'components/plant/plantPageComponent.vue',
                props: {
                    plantId: data.plantId,
                    boaDate: data.boaDate ? data.boaDate : false,
                    dateFrom: data.fromDate ? data.fromDate : null,
                    dateTo: data.toDate ? data.toDate : null,
                    plantDetailsToShowInitial: data.plantDetailsToShowInitial,
                },
            });
        },
        changeTerreDailyStatus ({
            state,
            commit,
            dispatch,
        }, status) {
            commit('setTerreDailyStatus', status);
        },
        realtimeTableMerge ({
            state,
            dispatch,
            getters,
        }, data) {
            if (getters.hasBoaAndNotAnalytics) {
                const period = state.data.analysisDate.period;
                const dateString = state.data.analysisDate.date.format('YYYY-MM-DDT00:00:00');
                // check period
                if (data.period == period && data.date == dateString) {
                    dispatch('getBoaData');
                }
            }
            if (state.ui.pageName === 'boaComparer') {
                const periodBefore = state.data.pages.boaComparer.data.datePeriodCurrent.period;
                const periodNow = state.data.pages.boaComparer.data.dateperiodPlusOne.period;
                const periodAfter = state.data.pages.boaComparer.data.dateperiodPlusTwo.period;
                const dateBefore = state.data.pages.boaComparer.data.datePeriodCurrent.date;
                const dateNow = state.data.pages.boaComparer.data.dateperiodPlusOne.date;
                const dateAfter = state.data.pages.boaComparer.data.dateperiodPlusTwo.date;
                // check period
                if (data.period === periodBefore && data.date === dateBefore) {
                    dispatch('getBoaComparerData');
                }
                if (data.period === periodNow && data.date === dateNow) {
                    dispatch('getBoaComparerData');
                }
                if (data.period === periodAfter && data.date === dateAfter) {
                    dispatch('getBoaComparerData');
                }
            }
        },
        realtimeMerge ({
            commit,
            state,
            getters,
        }, data) {
            // only proccess if not in analytics Mode
            function modifyTable (stackData, value, period, page, option) {
                if (value.item.tableBids.data.length > 0) {
                    value.item.tableBids.data.forEach(v => {
                        const index = stackData.tableBids.data.findIndex(el =>
                            el.bodId === v.bodId
                            && el.plantId === v.plantId
                        );
                        if (index !== -1) {
                            if (getters.hasBoa) {
                                commit('updateStackDataBoaTableBids', {
                                    index,
                                    data: v,
                                });
                            } else if (page === 'boaComparer') {
                                switch (option) {
                                case 'stackDataCurrent':
                                    commit('updateStackDataCurrentTableBids', {
                                        index,
                                        data: v,
                                    });
                                    break;
                                case 'stackDataPlusOne':
                                    commit('updateStackDataPlusOneTableBids', {
                                        index,
                                        data: v,
                                    });
                                    break;
                                case 'stackDataPlusTwo':
                                    commit('updateStackDataPlusTwoTableBids', {
                                        index,
                                        data: v,
                                    });
                                    break;
                                default:
                                    break;
                                }
                            }
                        } else {
                            if (getters.hasBoa) {
                                commit('addStackDataBoaTableBids', v);
                            } else if (page === 'boaComparer') {
                                switch (option) {
                                case 'stackDataCurrent':
                                    commit('addStackDataCurrentTableBids', v);
                                    break;
                                case 'stackDataPlusOne':
                                    commit('addStackDataPlusOneTableBids', v);
                                    break;
                                case 'stackDataPlusTwo':
                                    commit('addStackDataPlusTwoTableBids', v);
                                    break;
                                default:
                                    break;
                                }
                            }
                        }
                        commit('updateBoa', {
                            data: v,
                            period,
                        });
                    });
                }
                if (value.item.tableOffers.data.length > 0) {
                    value.item.tableOffers.data.forEach(v => {
                        const index = stackData.tableOffers.data.findIndex(el =>
                            el.bodId === v.bodId
                            && el.plantId === v.plantId
                        );
                        if (index !== -1) {
                            if (getters.hasBoa) {
                                commit('updateStackDataBoaTableOffers', {
                                    index,
                                    data: v,
                                });
                            } else if (page === 'boaComparer') {
                                switch (option) {
                                case 'stackDataCurrent':
                                    commit('updateStackDataCurrentTableOffers', {
                                        index,
                                        data: v,
                                    });
                                    break;
                                case 'stackDataPlusOne':
                                    commit('updateStackDataPlusOneTableOffers', {
                                        index,
                                        data: v,
                                    });
                                    break;
                                case 'stackDataPlusTwo':
                                    commit('updateStackDataPlusTwoTableOffers', {
                                        index,
                                        data: v,
                                    });
                                    break;
                                default:
                                    break;
                                }
                            }
                        } else {
                            if (getters.hasBoa) {
                                commit('addStackDataBoaTableOffers', v);
                            } else if (page === 'boaComparer') {
                                switch (option) {
                                case 'stackDataCurrent':
                                    commit('addStackDataCurrentTableOffers', v);
                                    break;
                                case 'stackDataPlusOne':
                                    commit('addStackDataPlusOneTableOffers', v);
                                    break;
                                case 'stackDataPlusTwo':
                                    commit('addStackDataPlusTwoTableOffers', v);
                                    break;
                                default:
                                    break;
                                }
                            }
                        }
                        commit('updateBoa', {
                            data: v,
                            period,
                        });
                    });
                }
                return stackData;
            }
            const pageName = state.ui.pageName;
            if (getters.hasBoaAndNotAnalytics) {
                const period = state.data.analysisDate.period;
                const dateString = state.data.analysisDate.date.format('YYYY-MM-DDT00:00:00');

                data.forEach(value => {
                    // check period
                    if (value.datePeriod.period == period && value.datePeriod.date == dateString) {
                        const stackData = state.data.pages.boaDashboard.data.stackData;

                        if (value.item.acceptedBids.length > 0) {
                            value.item.acceptedBids.forEach(v => {
                                const index = stackData.acceptedBids.findIndex(el =>
                                    el.bodId === v.bodId
                                    && el.plantId === v.plantId
                                );
                                if (index !== -1) {
                                    commit('updateBoaStackAcceptedBids', {
                                        index,
                                        data: v,
                                    });
                                } else {
                                    commit('addBoaStackAcceptedBids', v);
                                }
                                commit('updateBoa', {
                                    data: v,
                                    period,
                                });
                            });
                        }
                        if (value.item.acceptedOffers.length > 0) {
                            value.item.acceptedOffers.forEach(v => {
                                const index = stackData.acceptedOffers.findIndex(el =>
                                    el.bodId === v.bodId
                                    && el.plantId === v.plantId
                                );
                                if (index !== -1) {
                                    commit('updateBoaStackAcceptedOffers', {
                                        index,
                                        data: v,
                                    });
                                } else {
                                    commit('addBoaStackAcceptedOffers', v);
                                }
                                commit('updateBoa', {
                                    data: v,
                                    period,
                                });
                            });
                        }
                        modifyTable(
                            stackData,
                            value,
                            period,
                            pageName,
                            'stackData',
                        );
                    }
                });
                // reset after 3 seconds
            }

            if (state.ui.pageName === 'boaComparer') {
                const periodBefore = state.data.pages.boaComparer.data.datePeriodCurrent.period;
                const periodNow = state.data.pages.boaComparer.data.dateperiodPlusOne.period;
                const periodAfter = state.data.pages.boaComparer.data.dateperiodPlusTwo.period;
                const dateBefore = state.data.pages.boaComparer.data.datePeriodCurrent.date;
                const dateNow = state.data.pages.boaComparer.data.dateperiodPlusOne.date;
                const dateAfter = state.data.pages.boaComparer.data.dateperiodPlusTwo.date;

                data.forEach(value => {
                    if (value.datePeriod.period == periodBefore && value.datePeriod.date == dateBefore) {
                        modifyTable(
                            state.data.pages.boaComparer.data.stackDataCurrent,
                            value,
                            periodBefore,
                            'boaComparer',
                            'stackDataCurrent',
                        );
                    }
                    if (value.datePeriod.period == periodNow && value.datePeriod.date == dateNow) {
                        modifyTable(
                            state.data.pages.boaComparer.data.stackDataPlusOne,
                            value,
                            periodNow,
                            'boaComparer',
                            'stackDataPlusOne',
                        );
                    }
                    if (value.datePeriod.period == periodAfter && value.datePeriod.date == dateAfter) {
                        modifyTable(
                            state.data.pages.boaComparer.data.stackDataPlusTwo,
                            value,
                            periodAfter,
                            'boaComparer',
                            'stackDataPlusTwo',
                        );
                    }
                });
            }
            setTimeout(() => {
                commit('resetBoa');
            }, 5000);
        },
        removeExistingModuleSubscriptions ({
            commit,
            state,
        }) {
            state.data.modulesListening.forEach((series) => {
                realTimeConnection.removeModuleSubscription(series);
            });

            commit('wipeModuleList');
        },
        removeExistingSeriesSubscriptions ({
            commit,
            state,
        }) {
            state.data.seriesListening.forEach((series) => {
                realTimeConnection.removeFromSeries(series);
            });
            commit('wipeSeriesList');
        },
        async getBoaData ({
            commit,
            state,
            getters,
        }) {
            const day = state.data.analysisDate.date.date();
            const month = state.data.analysisDate.date.month() + 1;
            const year = state.data.analysisDate.date.year();
            const period = state.data.analysisDate.period;
            const requestUrl = process.env.VUE_APP_BASEADDRESS + (period !== null ? `/api/boa/${day}/${month}/${year}/${period}` : '/api/boa');

            const today = new Date();
            const toLog = (day >= today.getDate() && month === (today.getMonth() + 1) && year === today.getFullYear()) ? 'Accessed Boa Dashboard (realtime)' : 'Accessed Boa Dashboard (historic)';
            axios.post(`${process.env.VUE_APP_STATIC_API}/staticApi/frontendLog/logFrontendEvent`, {
                frontendEvent: toLog,
            });

            await axios.get(requestUrl).then((response) => {
                commit('updatePageData', {
                    page: 'boaDashboard', // This is the page to update in the store, so includes boaDashboard module on dashboard page
                    pageData: response.data,
                    hasBoa: getters.hasBoa,
                });
            });
        },
        async getBoaComparerData ({
            commit,
            state,
            getters,
        }) {
            const day = state.data.analysisDate.date.date();
            const month = state.data.analysisDate.date.month() + 1;
            const year = state.data.analysisDate.date.year();
            const period = state.data.analysisDate.period;
            const requestUrl = process.env.VUE_APP_BASEADDRESS + (period !== null ? `/api/boaComparer/${day}/${month}/${year}/${period}` : '/api/boaComparer');

            const today = new Date();
            const toLog = (day >= today.getDate() && month === (today.getMonth() + 1) && year === today.getFullYear()) ? 'Accessed Boa Comparer (realtime)' : 'Accessed Boa Comparer (historic)';
            axios.post(`${process.env.VUE_APP_STATIC_API}/staticApi/frontendLog/logFrontendEvent`, {
                frontendEvent: toLog,
            });

            return axios.get(requestUrl).then((response) => {
                commit('updatePageData', {
                    page: 'boaComparer',
                    pageData: response.data,
                    hasBoa: getters.hasBoa,
                });
            });
        },
        async getInterconnectorLines ({
            commit,
        }) {
            return axios.get(`${process.env.VUE_APP_BASEADDRESS}/api/interconnector`).then((response) => {
                commit('addInterconnectorLines', response.data);
            });
        },
        async getEuropeInterconnectorLines ({
            commit,
        }) {
            return axios.get(`${process.env.VUE_APP_ENTSOE_API}/entsoeApi/europeMap`).then((response) => {
                commit('addEuropeInterconnectorLines', response.data);
            });
        },
        updateAnalysisDate ({
            commit,
        }, data) {
            commit('updateAnalysisDate', data);
            commit('setCanActionWatchOnBothDayAndPeriod', true);
            const requestUrl = `${process.env.VUE_APP_STATIC_API}/staticApi/period/${data.date()}/${data.month() + 1}/${data.year()}`;
            return axios.get(requestUrl).then((response) => {
                commit('updatePeriodsInDay', response.data);
            });
        },
        getCurrentPeriod ({
            dispatch,
        }) {
            const requestUrl = `${process.env.VUE_APP_STATIC_API}/staticApi/tradingperiod`;
            return axios.get(requestUrl).then((response) => {
                dispatch('setCurrentPeriod', response.data);
            });
        },
        setCurrentPeriod ({
            commit, getters,
        }, periodInfo) {
            const boaWindowType = getters['boaWindowType'];
            commit('setPeriodStatus', {
                boaWindowType,
                periodInfo,
            });
        },
        fromTableToAccepted ({
            state,
            dispatch,
        }, toBeMoved) {
            toBeMoved.forEach(value => {
                const stackData = state.data.pages.boaDashboard.data.stackData;
                const parentStack = value.isBid ? stackData.tableBids.data : stackData.tableOffers.data;
                const targetStack = value.isBid ? stackData.acceptedBids : stackData.acceptedOffers;
                const parentData = parentStack.find(o =>
                    o.plantId === value.plantId && o.isBid === value.isBid && o.bodId === value.bodId);
                const newValue = { ...value };
                newValue.isAnalytic = true;
                newValue.timeAccepted = new Date();

                newValue.bodId = value.bodId;

                // check is bodId is already lurking in stack
                const existingValue = targetStack.find((o) => o.plantId === value.plantId
                    && o.isBid === value.isBid && o.bodId === value.bodId && o.isAccepted === value.isAccepted);
                if (existingValue != null) {
                    Vue.set(existingValue, 'volume', existingValue.volume + newValue.volume);
                } else {
                    newValue.minVolume = 0;
                    if (value.initialVolume !== undefined) {
                        newValue.maxVolume = Math.round(value.initialVolume);
                    } else {
                        newValue.maxVolume = Math.round(value.volume);
                    }
                    targetStack.push(newValue);
                }
                Vue.set(parentData, 'volume', 0);
                Vue.set(parentData, 'selected', false);
            });

            dispatch('newMetricsFromAnalyticUpdate');
        },
        addNewPlantToAccepted ({
            state,
            dispatch,
        }, value) {
            const stackData = state.data.pages.boaDashboard.data.stackData;
            const targetStack = value.volume < 0 ? stackData.acceptedBids : stackData.acceptedOffers;

            const toAdd = {
                bidOnTableTypeEnum: 'TurnOn',
                bodId: '1',
                cadlFlag: value.cadlFlag,
                colour: 'rgb(149, 148, 232)',
                isAccepted: 1,
                isAnalytic: true,
                isBid: value.volume < 0 ? 1 : 0,
                mainPrice: value.price,
                nonBmPrediction: value.nonBmFlag,
                plantId: value.name,
                selected: false,
                show: true,
                soFlag: value.soFlag,
                storFlag: value.storFlag,
                terreFlag: value.terreFlag,
                theoretical: false,
                fakePlant: true,
                timeAccepted: Date.now(),
                volume: value.volume,
                maxVolume: 5000,
                minVolume: -5000,
                volumeComparison: null,
                terreAccepted: null,
            };

            targetStack.push(toAdd);

            dispatch('newMetricsFromAnalyticUpdate');
        },
        fromAcceptedToTable ({
            state,
            dispatch,
        }, value) {
            const newValue = { ...value };
            const stackData = state.data.pages.boaDashboard.data.stackData;
            const childStack = value.isBid ? stackData.acceptedBids : stackData.acceptedOffers;
            childStack.splice(childStack.indexOf(value), 1);

            if (!newValue.fakePlant) {
                const parentStack = value.isBid ? stackData.tableBids.data : stackData.tableOffers.data;

                const parentData = parentStack.find(o => o.plantId === value.plantId && o.isBid === value.isBid && o.bodId === value.bodId);

                newValue.isAnalytic = false;
                newValue.isAnalyticEdit = false;
                newValue.timeAccepted = null;
                newValue.selected = false;

                if (newValue.hasOwnProperty('newPrice')) newValue.newPrice = newValue.mainPrice;

                newValue.volume = newValue.initialVolume ? newValue.initialVolume : newValue.volume;
                Vue.set(parentStack, parentStack.indexOf(parentData), newValue);
            }
            dispatch('newMetricsFromAnalyticUpdate');
        },
        updateAcceptedVolume ({
            state,
            dispatch,
        }, value) {
            const stackData = state.data.pages.boaDashboard.data.stackData;

            if (!value.fakePlant) {
                const newValue = { ...value };

                newValue.isAnalytic = false;
                newValue.timeAccepted = null;
                newValue.selected = false;

                if (newValue.hasOwnProperty('newPrice')) newValue.newPrice = newValue.mainPrice;
                newValue.volume = newValue.initialVolume - newValue.volume;

                const parentStack = value.isBid == 1 ? stackData.tableBids.data : stackData.tableOffers.data;

                const parentData = parentStack.find(o => o.plantId === value.plantId && o.isBid === value.isBid && o.bodId === value.bodId);

                Vue.set(parentStack, parentStack.indexOf(parentData), newValue);
            } else if (value.isBid == 1) {
                stackData.acceptedOffers.splice(stackData.acceptedOffers.indexOf(value), 1);
                if (stackData.acceptedBids.findIndex((el) => el.plantId === value.plantId) === -1) {
                    stackData.acceptedBids.push(value);
                }
            } else {
                stackData.acceptedBids.splice(stackData.acceptedBids.indexOf(value), 1);
                if (stackData.acceptedOffers.findIndex((el) => el.plantId === value.plantId) === -1) {
                    stackData.acceptedOffers.push(value);
                }
            }

            dispatch('newMetricsFromAnalyticUpdate');
        },
        updateAcceptedPrice ({
            dispatch,
        }, value) {
            dispatch('newMetricsFromAnalyticUpdate');
        },
        updateAccepted ({
            dispatch,
            commit,
        }, value) {
            commit('updateBoaAnalyticsOptions', value);
            dispatch('newMetricsFromAnalyticUpdate');
        },
        toggleAnalyticsMode ({
            commit,
            dispatch,
            state,
        }) {
            const analytics = state.data.pages.boaDashboard.analyticsMode;
            if (analytics) {
                dispatch('getBoaData').then(() => {
                    commit('toggleAnalyticsMode');
                });
            } else {
                commit('toggleAnalyticsMode');
            }
        },
        restartAnalytics ({
            commit,
            dispatch,
        }) {
            dispatch('getBoaData').then(() => {
                commit('changeNoticeDeviateFilter', -1);
                commit('restartAnalytics');
            });
        },
        newMetricsFromAnalyticUpdate ({
            state,
        }) {
            const accepted = state.data.pages.boaDashboard.data.stackData.acceptedBids.concat(state.data.pages.boaDashboard.data.stackData.acceptedOffers);
            const combinedArray = [];
            const analyticsOptions = state.data.pages.boaDashboard.analyticsOptions;
            accepted.forEach(value => {
                const bidOfferObj = {};
                bidOfferObj.plantId = value.plantId;
                bidOfferObj.bodId = value.bodId;
                bidOfferObj.price = value.mainPrice;
                bidOfferObj.isBid = value.isBid;
                bidOfferObj.volume = value.volume;
                bidOfferObj.storFlag = value.storFlag;
                bidOfferObj.soFlag = value.soFlag;
                bidOfferObj.cadlFlag = value.cadlFlag;
                bidOfferObj.nonBmPrediction = value.hasOwnProperty('nonBmPrediction') ? value.nonBmPrediction : false;
                bidOfferObj.terreFlag = value.terreFlag;
                // now push to the array
                const shouldNotSend = (!analyticsOptions.edit && value.isAnalyticEdit)
                    || (!analyticsOptions.addition && value.isAnalytic)
                    || (!analyticsOptions.nonBm && value.nonBmPrediction)
                    || (!analyticsOptions.minSel && value.theoretical);
                if (!shouldNotSend) combinedArray.push(bidOfferObj);
            });
            const dataToSend = {
                boaArray: combinedArray,
                day: state.data.analysisDate.date.date(),
                month: state.data.analysisDate.date.month() + 1,
                year: state.data.analysisDate.date.year(),
                period: state.data.analysisDate.period,
            };

            return axios
                .post(`${process.env.VUE_APP_BASEADDRESS}/api/boa`, dataToSend)
                .then((response) => {
                    Vue.set(state.data.pages.boaDashboard.data.keyMetrics.systemPrice, 'priceString', response.data.priceString);
                    Vue.set(state.data.pages.boaDashboard.data.keyMetrics, 'totalBidAccepted', response.data.totalBidsAccepted);
                    Vue.set(state.data.pages.boaDashboard.data.keyMetrics, 'totalOfferAccepted', response.data.totalOffersAccepted);
                    Vue.set(state.data.pages.boaDashboard.data.keyMetrics, 'maxAcceptedBidPrice', response.data.minBidPrice);
                    Vue.set(state.data.pages.boaDashboard.data.keyMetrics, 'maxAcceptedOfferPrice', response.data.maxOfferPrice);
                });
        },
        getBingoCardData ({
            state,
            commit,
            dispatch,
        }) {
            const plantList = [];
            const thisBoard = state.user.pages.bingoNew.boards[state.user.pages.bingoNew.activeBoard];
            if (thisBoard == null) return;
            thisBoard.cards.forEach(card => {
                if (card.type === 'plant') {
                    plantList.push(card.value);
                }
            });

            const data = {
                plants: plantList,
                melBelowPnToleranceToUse: state.user.melBelowPnToleranceToUse,
            };
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASEADDRESS}/api/bingo/`, data).then((response) => {
                    const keyedObjects = {};
                    response.data.forEach(value => {
                        keyedObjects[value.plantId] = value;
                    });
                    commit('setBingoData', keyedObjects);
                    resolve();
                });
            });
        },
        getSinglePlantBingo ({
            commit,
        }, plantList) {
            return new Promise((resolve, reject) => {
                const data = {
                    plants: plantList,
                };
                axios.post(`${process.env.VUE_APP_BASEADDRESS}/api/bingo/`, data).then((response) => {
                    commit('addBingoData', response.data[0]);
                    resolve();
                }).catch((error) => {
                    alert('network error - plant not added please try again');
                    reject();
                });
            });
        },
        realTimeBingoMerge ({
            state,
            commit,
        }, data) {
            if (state.ui.pageName === 'bingoCard') {
                if (data.plantId in state.data.pages.bingo.data.plantData) {
                    commit('updateBingoObject', data);
                }
            }
        },

    },
});
