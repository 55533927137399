var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "multiselect",
    {
      class: _vm.autoWidth ? "autoWidth" : "",
      attrs: {
        options: _vm.allPlants,
        "custom-label": _vm.customLabel,
        searchable: true,
        "close-on-select": true,
        "show-labels": false,
        optionsLimit: 2500,
        multiple: _vm.allowMultiple,
        label: "name",
        "track-by": "id",
        disabled: _vm.disabled,
        placeholder: _vm.placeholderOverride,
        openDirection: _vm.openDirection,
      },
      on: { input: _vm.emitValue },
      scopedSlots: _vm._u([
        {
          key: "singleLabel",
          fn: function (props) {
            return [
              _c("span", { staticClass: "option__desc" }, [
                _c("span", { staticClass: "option__title" }, [
                  _vm._v(_vm._s(props.option.name)),
                ]),
              ]),
            ]
          },
        },
        {
          key: "option",
          fn: function (props) {
            return [
              _c("div", { staticClass: "option__icon" }, [
                _c("span", {
                  staticClass: "icon",
                  class: "icofont-" + props.option.icon,
                  style: { background: props.option.colour },
                }),
              ]),
              _c("div", { staticClass: "option__desc" }, [
                _c("span", { staticClass: "option__id" }, [
                  _vm._v(_vm._s(props.option.name)),
                ]),
              ]),
            ]
          },
        },
      ]),
      model: {
        value: _vm.currentPlant,
        callback: function ($$v) {
          _vm.currentPlant = $$v
        },
        expression: "currentPlant",
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "multiselect_arrow",
          attrs: { slot: "caret" },
          slot: "caret",
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "search"] } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }