import moment from 'moment';

class DatePickerMethods {
    static getDatesFromHeaderConfigOrOld (dbObject, fromOrTo) {
        if (dbObject.dateObject) {
            const dates = DatePickerMethods.getDatesFromConfig(dbObject.dateObject);
            return fromOrTo === 'from' ? dates.from : dates.to;
        } else {
            return fromOrTo === 'from' ? moment(dbObject.from) : moment(dbObject.to);
        }
    }

    static getTitle (fromDate, toDate, minimumView = 'day', fromHours = null, toHours = null) {
        const format = {
            day: 'll',
            month: 'MMM YYYY',
            year: 'YYYY',
        }[minimumView];
        let title;
        if (fromHours && toHours) {
            title = `Previous ${fromHours}h to Next ${toHours}h`;
        } else {
            const momentFrom = moment(fromDate, 'DDMMYYYY');
            const momentTo = moment(toDate, 'DDMMYYYY');

            if (moment(toDate).isBefore(fromDate) || fromDate === toDate) {
                title =  `${momentFrom.format(format)}`;
            } else if (momentFrom.isSame(momentFrom.clone().startOf('month'), 'day') && momentTo.isSame(momentTo.clone().endOf('month'), 'day') && momentFrom.clone().startOf('month').isSame(momentTo.clone().startOf('month'), 'day')) {
                title =  `${momentFrom.format('MMM YYYY')}`;
            } else if (momentFrom.isSame(momentFrom.clone().startOf('year'), 'day') && momentTo.isSame(momentTo.clone().endOf('year'), 'day') && momentFrom.clone().startOf('year').isSame(momentTo.clone().startOf('year'), 'day')) {
                title =  `${momentFrom.format('YYYY')}`;
            } else if (momentFrom.year() === momentTo.year()) {
                title =  `${momentFrom.format('MMM D')} - ${momentTo.format('ll')}`;
            } else {
                if (toDate == null) title = momentFrom.format(format);
                else title =  `${momentFrom.format(format)} - ${momentTo.format(format)}`;
            }
        }
        return title;
    }

    static getDatesFromConfig (dateObject, fromDateOverride = null, toDateOverride = null) {
        const fromObject = dateObject.from;
        const toObject = dateObject.to;

        if (fromDateOverride || toDateOverride) {
            return { from: moment(fromDateOverride), to: moment(toDateOverride) };
        }
        let from = moment();
        let to = moment();
        if (fromObject.value === 'StartWeek') {
            from = moment().startOf('week');
        } else if (fromObject.value === 'StartMonth') {
            from = moment().startOf('month');
        } else if (fromObject.type === 'date' && parseInt(fromObject.value) <= 5) { // currentDay should be limited to a value of 0, but this catches for incorrect DB settings
            from = moment().startOf('day');
        } else if (fromObject.type === 'dynamic') {
            from = moment().startOf('day').subtract(fromObject.value, 'days');
        } else {
            if (fromObject.value === '' || fromObject.value === null) {
                fromObject.value = toObject.value;
            }
            from = fromObject.value === 'none' ? null : moment(fromObject.value);
        }
        if (toObject.value === 'EndWeek') {
            to = moment().endOf('week');
        } else if (toObject.value === 'EndMonth') {
            to = moment().endOf('month');
        } else if (toObject.type === 'date' && parseInt(toObject.value) <= 5) { // currentDay should be limited to a value of 0, but this catches for incorrect DB settings
            to = moment().endOf('day');
        } else if (toObject.type === 'dynamic') {
            to = moment().endOf('day').add(toObject.value, 'days');
        } else if (toObject.value === '' || toObject.value === null) {
            if (fromObject.value === '' || fromObject.value === null) {
                fromObject.value = 0;
                toObject.value = 0;
                from = moment();
            } else {
                toObject.value = fromObject.value;
            }
            to = moment();
        } else {
            to = toObject.value === 'none' ? null : moment(toObject.value);
        }

        if (dateObject.recentHour) {
            if (dateObject.fromHours) {
                const currentHourStart = moment().add(-1 * dateObject.fromHours, 'hours');
                from = currentHourStart;
            }
            if (dateObject.toHours) {
                const currentHourEnd = moment().add(dateObject.toHours, 'hours');
                to = currentHourEnd;
            }
        }
        return { from, to };
    }
}

export { DatePickerMethods };
