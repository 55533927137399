var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "logoHolder" }, [
    _c(
      "a",
      {
        attrs: {
          href: _vm.loggedIn
            ? "https://enact.lcp.energy"
            : "https://lcpenact.com",
          target: "_blank",
        },
      },
      [
        _c("img", {
          staticClass: "logo",
          style: { height: _vm.fontSize + "px" },
          attrs: {
            src: require("@/assets/imgs/Logos/" +
              (_vm.userSettings.colour === "Dark" ? "Dark" : "Dark") +
              "/" +
              (_vm.selectedEnact === "Enact" ? "enact.svg" : "envision.svg")),
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }