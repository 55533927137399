import axios from 'axios';
import Vue from 'vue';
import moment from 'moment-timezone';

Vue.use(require('vue-moment'), {
    moment,
});

Vue.moment.tz.setDefault('Europe/London');

export default {
    namespaced: true,
    state: {
        allSubscriptions: [],
        notifications: [],
        browserNotifications: [],
    },
    getters: {

    },
    mutations: {
        addBrowserUpdate (state, data) {
            state.browserNotifications.push(data);
        },
    },
    actions: {
        updateBackendAboutSubscription ({ commit, state, rootstate }) {
            axios.get(`${process.env.VUE_APP_STATIC_API}/staticApi/userOnline`);
        },
        postToBackendAboutSubscription ({ commit, state, rootstate }, toPost) {
            axios.post(`${process.env.VUE_APP_STATIC_API}/staticApi/userOnline/newSubscriptions`, toPost);
        },
    },

};
