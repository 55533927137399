<template>
    <div class="wrapper">
        <div class="loader">
            <slot></slot>
            <img v-if="showLoader"
                class="image"
                :src="require(`@/assets/imgs/Logos/${userSettings.colour === 'Dark' ? 'Dark' : 'Light'}/enact-animated.svg`)"
            />
        </div>
    </div>
</template>

<script>

export default {
    name: 'loading',
    props: {
        showLoader: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        userSettings () {
            return this.$store.state.user;
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    background-image: linear-gradient(315deg, var(--darkGrad1) 0%, var(--darkGrad2) 74%);
    bottom:0;
    left:0;
    position: absolute;
    right:0;
    text-align: center;
    top:0;//40px;
    z-index: 11;

    // .loader {
    //     background-color: #ddd;
    //     height: 4px;
    //     overflow: hidden;
    //     position: relative;
    //     width: 100%;
    // }
    // .loadingText{
    //     font-size:40px;
    //     position: absolute;
    //     text-align: center;
    //     top:40%;
    //     width: 100%;
    // }
    .loader {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        @include responsive('sm') {
            width: 95%;
        }
        // .image {
        //     width: 80%;
        // }
    }
    // .loaderSvg {
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transform: translate(-45%, -50%);
    //     font-size: 28px;
    //     .loaderText {
    //     transform: translate(5%, 0%);
    //     }
    // }
    // .loader:before {
    //     animation: loading 2s linear infinite;
    //     background-color: #2980b9;
    //     content: "";
    //     display: block;
    //     height: 4px;
    //     left: -200px;
    //     position: absolute;
    //     width: 100%;
    // }
    // @keyframes loading {
    //     from {left: -200px; width: 30%;}
    //     50% {width: 30%;}
    //     70% {width: 70%;}
    //     80% { left: 50%;}
    //     95% {left: 120%;}
    //     to {left: 100%;}
    // }
}
// .dark{
//     .wrapper {
//         color: white;
//     }
// }
</style>
