<template>
    <span
        :v-tooltip="tooltip"
        class="disableIcon"
        :class="`icofont-lock ${accessLevelClass}`">
    </span>
</template>

<script>
export default {
    name: 'disabledIcon',
    props: {
        accessLevel: {
            type: String,
            required: true,
        },
        showTooltip: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        accessLevelClass () {
            switch (this.accessLevel.toLowerCase().replace('enact ', '')) {
            case 'bronze':
            case 'visualise':
                return 'bronzeIcon';
            case 'gold':
            case 'goldforecasts':
            case 'analyse':
                return 'goldIcon';
            case 'silver':
            case 'trade':
            case 'trade with forecasts':
                return 'silverIcon';
            case 'enterprise':
                return 'enterpriseIcon';
            default:
                return 'enterpriseIcon';
            }
        },
        tooltip () {
            return this.showTooltip ? `Requires ${this.accessLevel}` : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.disableIcon {
    border-radius: 4px;
    font-size:30px;
    line-height: 30px;
    padding:5px;
}
.bronzeIcon {
    color: burlywood;
}
.silverIcon {
    color: #d9d9d9;
    background: linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 74%);
    -webkit-background-clip: text; -webkit-text-fill-color: transparent;
}
.goldIcon {
    color: #facc6b;
    background: linear-gradient(315deg, #facc6b 0%, #fabc3c 74%);
    -webkit-background-clip: text; -webkit-text-fill-color: transparent;
}
.enterpriseIcon {
    color: #352384;
    background: linear-gradient(315deg, $primary 0%, #f3f3f5 74%);
    -webkit-background-clip: text; -webkit-text-fill-color: transparent;
}
</style>
