var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _c("div", [
      _c("div", { staticClass: "modal", on: { click: _vm.closeSlideOut } }),
      _c(
        "div",
        {
          staticClass: "card",
          style: "" + _vm.height + _vm.width,
          attrs: { id: "wrapper" },
        },
        [
          _c("div", { staticClass: "inner" }, [
            _c("div", { staticClass: "tabHeader" }, [
              _vm.selectedComponent.newTitle
                ? _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.selectedComponent.newTitle)),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "close-wrapper" }, [
                _c("span", {
                  staticClass: "icofont-ui-close",
                  on: { click: _vm.closeSlideOut },
                }),
              ]),
            ]),
            _c(
              "div",
              { staticStyle: { height: "calc(100% - 25px)" } },
              [_vm._t("default")],
              2
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }