<template>
    <div>
        <div class="bmuFilter" v-if="showFilters && isGb">
            <template v-for="(catObject, catKey) in $staticStore.state.data.allPlants">
                <p-check :key="catKey" v-if="filterCategoriesToShow.includes(catKey)" name="check" class="p-icon p-square p-fill p-smooth" color="primary"
                    v-model="categoryToShow[catKey]">
                    <i slot="extra" class="icon icofont-check"></i>
                    {{catObject.categoryName}}
                </p-check>
            </template>
        </div>
        <multiselect
            v-model="selectedPlant"
            :options="allPlants"
            :custom-label="idAndName"
            :disabled="disabled"
            track-by="searchQuery"
            :searchable="true"
            :close-on-select="!allowMultiple"
            :show-labels="false"
            :optionsLimit="100"
            :multiple="allowMultiple"
            :placeholder="placeholderOverride"
            :resetAfter="resetAfter"
            :style="{ width: fullWidth ? '100%' : '' }"
            :class="{ 'autoWidth': autoWidth, 'light': light, 'extraHeight': variableMultiselectHeight }"
            :openDirection="openDirection"
            ref="plantTrigger"
            @open="clearSelected"
            @input="emitValue"
            label="searchQuery"
        >
            <span class="multiselect_arrow" slot="caret">
                <font-awesome-icon :icon="['fas', 'search']" />
            </span>
            <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                    <span class="option__title">{{ props.option.id }}</span>
                </span>
            </template>
            <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="option__desc" v-for="(value, key) in values" :key="key">
                        <span class="option__title">{{ value.id }}</span>
                    </span>
            </template> -->
            <template class="dropDownOption" slot="option" slot-scope="props">
                <div class="option__icon">
                    <span
                        class="icon"
                        :class="`icofont-${props.option.icon}`"
                        :style="{ background: props.option.colour }"
                    ></span>
                </div>
                <div class="option__desc">
                    <span class="option__id">{{ props.option.id }}</span>
                    <span class="option__name">
                        {{
                            aggregateType === 'owner' || aggregateType === 'group' ? '' : props.option.name !== null &&
                            props.option.name !== 'Unknown'
                                ? props.option.name
                                : '-'
                        }}
                        <br />
                        {{
                            props.option.owner !== null &&
                            props.option.name !== 'Unknown'
                                ? props.option.owner
                                : aggregateType === 'owner' || aggregateType === 'group' ? '' : '-'
                        }}
                    </span>
                </div>
            </template>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Vue from 'vue';
import isEqual from 'lodash/isEqual';

export default {
    name: 'multiselectPlant',
    props: {
        showFilters: {
            type: Boolean,
            required: false,
            default: false,
        },
        filterCategoriesToShow: {
            type: Array,
            required: false,
            default: () => ['BmPlant', 'NonBmPlant'],
        },
        categoryOverrides: {
            type: Array,
            required: false,
            default: () => ['BmPlant'],
        },
        placeholderOverride: {
            type: String,
            default: 'Search for a plant',
            required: false,
        },
        autoWidth: {
            type: Boolean,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        clearSelectedInteger: {
            type: Number,
        },
        preselectedId: {
            type: String,
            required: false,
        },
        initialArraySelect: {
            type: Array,
            required: false,
            default: () => [],
        },
        customPlantsToDisplay: {
            type: Array,
            required: false,
            default: () => [],
        },
        allowMultiple: {
            type: Boolean,
            required: false,
            default: false,
        },
        resetAfter: {
            type: Boolean,
            required: false,
            default: false,
        },
        openDirection: {
            type: String,
            default: null,
        },
        country: {
            type: String,
            required: false,
            default: 'Gb',
        },
        aggregateType: {
            type: String,
            default: 'plain',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        light: {
            type: Boolean,
            required: false,
            default: false,
        },
        variableMultiselectHeight: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    created () {
        this.setCategoriesToShow();

        if (this.preselectedId != null) {
            this.selectedPlant = this.allPlants.find(
                (x) => x.id == this.preselectedId,
            );
        } else {
            if (this.initialArraySelect) {
                this.selectedPlant = [];
                this.initialArraySelect.forEach((s) => this.selectedPlant.push(this.allPlants.find((e) => e.id === s)));
            }
        }
    },
    components: {
        Multiselect,
    },
    data () {
        return {
            selectedPlant: null,
            categoryToShow: { },
        };
    },
    methods: {
        setCategoriesToShow () {
            const categoriesToUse = this.categoryOverrides.length === 0 ? ['BmPlant'] : this.categoryOverrides;
            this.categoryToShow = {};
            categoriesToUse.forEach(category => {
                Vue.set(this.categoryToShow, category, true);
            });
        },
        emitValue (value) {
            if (value !== null) {
                this.$emit('select', value);
                this.$emit('overrideUpdated', {
                    original: this.preselectedId,
                    new: value.id,
                });
            }
        },
        idAndName ({ id, searchQuery }) {
            return this.allowMultiple ? id : searchQuery;
        },
        clearSelected () {
            if (!this.allowMultiple) this.selectedPlant = '';
        },
    },
    computed: {
        isGb () {
            return this.country === 'Gb';
        },
        allCategories () {
            return Object.keys(this.isGb ? this.$staticStore.state.data.allPlants : {});
        },
        allPlants () {
            let listToFilter = [];
            if (this.isGb) {
                if (this.aggregateType === 'group') {
                    Object.keys(this.$store.state.user.chosenPlantsByGroup).forEach(groupName => {
                        listToFilter.push({
                            name: groupName,
                            searchable: true,
                            id: groupName,
                            plantsForGroup: this.$store.state.user.chosenPlantsByGroup[groupName],
                            icon: 'group',
                            colour: 'rgb(233, 63, 111)',
                        });
                    });
                } else {
                    Object.keys(this.$staticStore.state.data.allPlants).forEach(category => {
                        if (this.categoryToShow[category]) listToFilter.push(...this.$staticStore.state.data.allPlants[category].plants);
                    });
                }
            } else {
                const plantsByEndpoint = this.$staticStore.state.data.plantsByEndpoint;
                Object.keys(plantsByEndpoint).forEach(endpoint => {
                    const plantsByCountry = plantsByEndpoint[endpoint];
                    Object.keys(plantsByCountry).forEach(country => {
                        if (country === this.country) {
                            listToFilter = plantsByCountry[country];
                        }
                    });
                });
            }
            if (this.customPlantsToDisplay.length !== 0) {
                listToFilter = listToFilter.filter(plant => this.customPlantsToDisplay.includes(plant.id));
            }
            return listToFilter.filter(plant => {
                if (!plant.searchable) return false;
                return true;
            });
        },
    },
    watch: {
        clearSelectedInteger () {
            this.clearSelected();
        },
        categoryOverrides (newVal, oldVal) {
            this.setCategoriesToShow();

            if (!isEqual(oldVal, newVal) && oldVal.length !== 0) {
                this.selectedPlant = null;
            }
        },
        country () {
            this.clearSelected();
        },
    },
};
</script>

<style lang="scss" scoped>
.bmuFilter {
    padding-bottom: 10px;
}
.dropDownOption {
	display: flex;
	.option__id {
		margin-top: 0px;
	}
}

.multiselect {
    height: unset;
}
::v-deep .multiselect__option {
	display: flex;
}

::v-deep .multiselect__tags-wrap {
	display: flex;
    flex-wrap: wrap;
    .multiselect__tag {
        padding: 4px 18px 4px 8px;
    }
}

::v-deep .option__icon {
	margin-top: 10px;
	line-height: 30px;
}
::v-deep .option__id {
	margin-top: 8px;
}

.extraHeight {
    ::v-deep .multiselect__tags {
        max-height: 65px;
        height: unset;
        overflow-y: auto;
        .multiselect__placeholder {
            margin-bottom: 0;
        }
    }
}

// .variableHeight {
//     max-height: 100px;
//     height: unset;
//     ::v-deep .multiselect__tags-wrap {
//         overflow: initial;
//     }
//     ::v-deep .multiselect__tags {
//         max-height: 100px;
//         height: unset;
//         overflow-y: auto;
//         display: flex;
//         flex-direction: column;
//         .multiselect__tags-wrap {
//             display: flex;
//             flex-wrap: wrap;
//         }
//         .multiselect__tag {
//             margin-right: 5px;
//         }
//     }
// }

.autoWidth {
	::v-deep .multiselect__content-wrapper {
		width: 300px;
	}
}
</style>
