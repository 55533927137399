<template>
    <fixedModal title="Add your dashboards to the company portfolio" showDarkBackground @close="onClickOutside">
        <div class="shareWrapper row">
            <div class="dashboards niceScroll col-md-8">
                <div v-for="(dashboard, key) in selectedDashboards" :key="key">
                    <div class="card">
                        <p-check name="check" class="p-icon p-square p-fill p-smooth" color="primary"
                            v-model="dashboard.selected">
                            <i slot="extra" class="icon icofont-check"></i>
                            <div class="wrapper">
                                <div class="dbIcon">
                                    <span
                                        :class="`${dashboard.icon}`"
                                        :style="{background : dashboard.colour}"
                                    />
                                </div>
                                <div class="option__desc">
                                    <span class="option__id">{{ dashboard.name }}</span>
                                    <span class="option__name">
                                        {{dashboard.description}}
                                    </span>
                                </div>
                            </div>
                        </p-check>

                    </div>
                </div>
            </div>
             <div class="col-md-4">
                <div class="bottomAbsolute">
                    <div class="warningText">Please note this will add your dashboards to your company portfolio.</div>
                    <div class="button" :class="{ 'disabled': buttonDisabled }" @click="sendDashboards">Send Dashboards</div>
                </div>
            </div>
        </div>
    </fixedModal>
</template>

<script>
import fixedModal from '@/components/layout/fixedModal';

export default {
    data () {
        return {
            selectedDashboards: [],
        };
    },
    components: {
        fixedModal,
    },
    props: {
        saveDashboard: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    created () {
        this.selectedDashboards = this.dashboards.map(d => ({
            id: d.id,
            icon: d.icon,
            colour: d.colour,
            name: d.name,
            description: d.description,
            selected: false,
        }));
    },
    computed: {
        dashboards () {
            return this.$store.state.user.pages.userDashboard.user.views;
        },
        buttonDisabled () {
            return this.selectedDashboards.filter(d => d.selected).length === 0;
        },
    },
    methods: {
        onClickOutside () {
            this.$emit('closeShare');
        },
        sendDashboards () {
            if (this.buttonDisabled) return;
            const dashboardsToPost = [];
            const selectedDashboards = this.selectedDashboards.filter(d => d.selected);
            selectedDashboards.forEach((d) => {
                dashboardsToPost.push(d.id);
            });
            const numberOfUniqueIdsNeeded = dashboardsToPost.length;
            const uniqueIds = [];
            for (let i = 0; i < numberOfUniqueIdsNeeded; i++) {
                uniqueIds.push(this.$uuid.v4());
            }
            const toPost = {
                dashboards: dashboardsToPost,
                uniqueIds,
            };
            this.$axios.post(`${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/saveCompanyDashboards`, toPost).then(() => {
                this.$emit('closeShare');
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 99;
}
.shareWrapper {
    display: flex;
    width: 1000px;
    height: 80vh;
    .dashboards {
        font-size: 20px;
        height: 100%;
        padding-right: 10px;
        .card {
            margin-top: 10px;
            margin-bottom: 10px;
            background: var(--mid);
            .wrapper {
                display: flex;
                .dbIcon {
                    margin-left: 10px;
                    span {
                        font-size: 44px;
                        padding: 3px;
                    }
                }
                .option__desc {
                    display: block;
                    width: 90%;
                }
            }
            ::v-deep .pretty.p-smooth label:after, ::v-deep .pretty.p-smooth label:before, ::v-deep .icon {
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .bottomAbsolute {
        position: absolute;
        padding-left: 30px;
        bottom: 20px;
        right: 20px;
        width: 100%;
        .button {
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .warningText {
            padding-left: 30px;
            text-align: right;
            position: absolute;
            bottom: 60px;
            right: 0;
        }
    }
}
</style>
