var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { card: !_vm.hideCard } },
    [
      !_vm.showTableOnly
        ? _c(
            "moduleHeader",
            {
              attrs: {
                title: _vm.hideTitle ? "" : _vm.moduleName,
                accentColour: _vm.moduleInfo.moduleColour,
                canClose: _vm.canClose,
                showHeaderIcons: _vm.showHeader,
                canEdit: true,
                showNoData: false,
                showChartIcons: false,
              },
              on: {
                close: function ($event) {
                  return _vm.$emit("close")
                },
                edit: function ($event) {
                  return _vm.$refs.table.setTableFilter()
                },
              },
            },
            [
              _vm.addSpacing
                ? [
                    _c(
                      "div",
                      { staticClass: "filterWrapper" },
                      _vm._l(_vm.selectedFilters, function (item, key) {
                        return _c(
                          "span",
                          { key: key, staticClass: "filters" },
                          [_vm._v(" " + _vm._s(item) + " ")]
                        )
                      }),
                      0
                    ),
                    _vm.moduleInfo.showRefreshRealtime
                      ? _c("div", [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "700" } },
                            [_vm._v("Table Updated:")]
                          ),
                          _vm._v(" " + _vm._s(_vm.tablePulledDate) + " "),
                          _c("i", {
                            staticClass: "icofont-refresh refresh",
                            on: { click: _vm.getInitialData },
                          }),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "loaderSvg" }, [
            _c("img", { attrs: { src: _vm.moduleChartLoader } }),
            _c("div", { staticClass: "loaderText" }, [_vm._v("Loading...")]),
          ])
        : _c(
            "div",
            { staticClass: "tableWrap", style: { height: _vm.heightString } },
            [
              _vm.tableData !== null
                ? _c("genericTable", {
                    ref: "table",
                    attrs: {
                      excelFilename: _vm.moduleName,
                      allowExcel: _vm.allowExcel,
                      uniqueNameId: "liveTable_" + _vm.config.id,
                      showHover:
                        _vm.moduleInfo.moduleType !== "SOSOTradesTable" &&
                        _vm.moduleInfo.moduleType !== "EpexMessages",
                      tableData: _vm.tableData,
                      noDataMessage: "No Data",
                      backendPredefinedFilters: _vm.dataAndConfig.presetFilters,
                      frontendPredefinedFilters:
                        _vm.dataAndConfig.frontendPredefinedFilters,
                      minHeightIs100: "",
                      sitsOnCard: "",
                      showFooter: _vm.allowFooter,
                      lightHover: _vm.lightHover,
                    },
                    on: {
                      filterChange: _vm.filtersChanged,
                      plantSelect: _vm.plantSelect,
                      rowSelect: _vm.rowSelect,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }