const endpoints = [
    {
        id: 'gb',
        name: 'GB',
        endpoint: `${process.env.VUE_APP_BASEADDRESS}/api`,
        plantPageComponent: 'components/plant/plantPageComponent.vue',
        liveTableLocation: 'api',
        plantTableFromEntsoe: false,
        remitTableFromEntsoe: false,
        getEuropePlant: false,
    },
    {
        id: 'europe', // entsoe
        name: 'Europe',
        endpoint: `${process.env.VUE_APP_ENTSOE_API}/entsoeApi`,
        plantPageComponent: 'components/plant/europePlant.vue',
        liveTableLocation: 'entsoeApi',
        plantTableFromEntsoe: true,
        remitTableFromEntsoe: true,
        getEuropePlant: true,
    },
    {
        id: 'france',
        name: 'France',
        endpoint: `${process.env.VUE_APP_FRANCE_API}/franceApi`,
        plantPageComponent: 'components/plant/francePlant.vue',
        liveTableLocation: 'franceApi',
        plantTableFromEntsoe: false,
        remitTableFromEntsoe: true,
        getEuropePlant: true,
    },
    {
        id: 'belgium',
        name: 'Belgium',
        endpoint: `${process.env.VUE_APP_BELGIUM_API}/belgiumApi`,
        plantPageComponent: 'components/plant/europePlant.vue',
        plantTableFromEntsoe: true,
        remitTableFromEntsoe: true,
        getEuropePlant: true,
    },
    {
        id: 'ireland',
        name: 'Ireland',
        endpoint: `${process.env.VUE_APP_SEMO_API}/irelandApi`,
        plantPageComponent: 'components/plant/semoPlant.vue',
        plantTableFromEntsoe: false,
        remitTableFromEntsoe: true,
        getEuropePlant: true,
    },
    {
        id: 'netherlands',
        name: 'Netherlands',
        endpoint: `${process.env.VUE_APP_NETHERLANDS_API}/netherlandsApi`,
        plantPageComponent: 'components/plant/europePlant.vue',
        plantTableFromEntsoe: true,
        remitTableFromEntsoe: true,
        getEuropePlant: true,
    },
    {
        id: 'epex',
        liveTableLocation: 'epexApi',
        endpoint: `${process.env.VUE_APP_EPEX_API}/epexApi`,
        getEuropePlant: false,
    },
    {
        id: 'static',
        liveTableLocation: 'staticApi',
        endpoint: `${process.env.VUE_APP_STATIC_API}/staticApi`,
        getEuropePlant: false,
    }];

function getEndpointFromCountry (countryId) {
    const countryInfo = endpoints.find(x => x.id === countryId.toLowerCase());
    return countryInfo === undefined ? endpoints.find(x => x.id === 'europe').endpoint : countryInfo.endpoint;
}

function getInfoFromCountryId (countryId) {
    const countryInfo = endpoints.find(x => x.id === countryId.toLowerCase());
    return countryInfo === undefined ? endpoints.find(x => x.id === 'europe') : countryInfo;
}

export {
    endpoints,
    getEndpointFromCountry,
    getInfoFromCountryId,
};
