var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.url
        ? _c(
            "a",
            {
              staticClass: "link animate",
              class: {
                "hidden-xs": !_vm.route.availableOnMobile,
                selected: _vm.selectedRoute,
              },
              attrs: { href: _vm.url, target: "_blank" },
            },
            [
              _c(
                "span",
                [
                  _c("font-awesome-icon", {
                    staticClass: "iconLink",
                    style: { marginLeft: (_vm.indent ? 20 : 10) + "px" },
                    attrs: {
                      icon: [_vm.iconType, _vm.route.icon],
                      "aria-label": _vm.route.name,
                      title: _vm.route.name,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _c(
            _vm.getComponent(),
            {
              tag: "component",
              staticClass: "link animate",
              class: {
                "hidden-xs": !_vm.route.availableOnMobile,
                disabledRouter: !_vm.getAccessLevel(_vm.link).hasAccess,
                selected: _vm.selectedRoute,
              },
              attrs: { to: "/" + (_vm.link == "current" ? "" : _vm.link) },
              on: { click: _vm.selectRoute },
            },
            [
              _c(
                "span",
                [
                  _c("font-awesome-icon", {
                    staticClass: "iconLink",
                    style: { marginLeft: (_vm.indent ? 20 : 10) + "px" },
                    attrs: {
                      icon: [_vm.iconType, _vm.route.icon],
                      "aria-label": _vm.route.name,
                      title: _vm.route.name,
                    },
                  }),
                  _vm.$store.state.ui.menuOpen
                    ? [
                        _c("span", { staticClass: "textLink" }, [
                          _vm._v(_vm._s(_vm.route.name)),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              !_vm.getAccessLevel(_vm.link).hasAccess
                ? _c("disabled-icon", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value:
                          "Requires " +
                          _vm.getAccessLevel(_vm.link).accessRequired,
                        expression:
                          "\n                    `Requires ${\n                        getAccessLevel(link).accessRequired\n                    }`\n                ",
                      },
                    ],
                    staticClass: "disabledIcon",
                    attrs: {
                      accessLevel: _vm.getAccessLevel(_vm.link).accessRequired,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }