import axios from 'axios';
import moment from 'moment';
// import store from './store';

const state = {
    data: {
        headerHeightPlusMargin: 50,
        maxSeriesOnCustomChart: 100,
        historyOfForecastsSerialised: [],
        allPlants: [],
        plantsByEndpoint: {}, // Dictionary of endpoints to plants (except gb, which is in allPlants above)
        fuelTypes: [],
        fuelNames: [],
        bingoEnums: {},
        bingoNamesToEnums: {},
        colours: [],
        iconList: [],
        bingoOverrideOptions: [
            { id: 'default', text: 'Default' },
            { id: 'textNdo', text: 'NDO' },
            { id: 'textNdb', text: 'NDB' },
            { id: 'textNdz', text: 'NDZ' },
            { id: 'textHeadroom', text: 'Headroom' },
            { id: 'textSel', text: 'Sel' },
            { id: 'textMelBelowExpectedOutput', text: 'MEL below expected outturn' },
            { id: 'textCurrentAcceptancePrice', text: 'Current acceptance price' },
            { id: 'textFirstOffer', text: 'First offer' },
            { id: 'textFirstBid', text: 'First bid' },
	        { id: 'textExpectedGeneration', text: 'Expected generation' },
	        { id: 'textAcceptedVolume', text: 'Accepted BM volume' },
        ],
        dynamicPresets: {},
        plantPresets: {},
        dynamicSeriesNew: [],
        plantSeries: [],
        dashboardPresets: [],
        plantsByZone: {},
        countries: [],
        mapCountries: [],
        oldSeriesIdToNew: {},
        liveTableOptions: {},
        liveTableSearch: [],
        endpointToEarliestBufferDate: {},
        onTableFilterConfig: {
            fuelType: {
                id: 'fuelType',
                title: 'Fuel Type',
                views: ['fuelTypes'],
            },
            plantId: {
                id: 'plantId',
                title: 'Plant / Interconnectors',
                views: ['search'],
            },
            mainPrice: {
                id: 'mainPrice',
                title: 'Price',
                views: ['rangeSlider', 'sort'],
            },
            volume: {
                id: 'volume',
                title: 'Remaining Volume',
                views: ['rangeSlider', 'sort'],
            },
            volumeComparison: {
                id: 'volumeComparison',
                title: 'Accepted BM Volume',
                views: ['rangeSlider', 'sort'],
            },
            terreAccepted: {
                id: 'terreAccepted',
                title: 'Accepted TERRE Volume',
                views: ['rangeSlider', 'sort'],
            },
            cost: {
                id: 'cost',
                title: 'Cost',
                views: ['rangeSlider', 'sort'],
            },
            bidTypeOffer: {
                id: 'bidTypeOffer',
                title: 'Cost',
                views: ['bidTypeOffer'],
            },
            bidTypeBid: {
                id: 'bidTypeBid',
                title: 'Cost',
                views: ['bidTypeBid'],
            },
            mnzt: {
                id: 'mnzt',
                title: 'MNZT',
                views: ['rangeSlider', 'sort'],
            },
            mzt: {
                id: 'mzt',
                title: 'MZT',
                views: ['rangeSlider', 'sort'],
            },
            ndb: {
                id: 'ndb',
                title: 'NDB',
                views: ['rangeSlider', 'sort'],
            },
            ndo: {
                id: 'ndo',
                title: 'NDO',
                views: ['rangeSlider', 'sort'],
            },
            ndz: {
                id: 'ndz',
                title: 'NDZ',
                views: ['rangeSlider', 'sort'],
            },
            sel: {
                id: 'sel',
                title: 'SEL',
                views: ['rangeSlider', 'sort'],
            },
        },
    },
};

async function getStaticStore (selectedEnactView) {
    await axios.post(`${process.env.VUE_APP_STATIC_API}/staticApi/staticStore`, { website: selectedEnactView }).then((response) => {
        // state.data.allPlants = response.data.allPlants;
        state.data.fuelTypes = response.data.fuelTypes;
        for (let i = 0; i < response.data.fuelTypes.length; i++) {
            state.data.fuelNames.push(response.data.fuelTypes[i].name);
        }
        state.data.bingoEnums = response.data.bingoEnums;
        state.data.bingoNamesToEnums = response.data.bingoNamesToEnums;
        state.data.colours = response.data.colours;
        state.data.iconList = response.data.iconList;

        state.data.dynamicPresets = response.data.dynamicPresets;
        state.data.plantPresets = response.data.plantPresets;
        state.data.dynamicSeriesNew = response.data.dynamicSeriesNew;
        state.data.plantSeries = response.data.plantSeries;

        state.data.dashboardPresets = response.data.dashboardPresets;

        state.data.countries = response.data.countries;
        state.data.mapCountries = response.data.mapCountries;

        state.data.currencies = response.data.currencies;

        state.data.oldSeriesIdToNew = response.data.oldSeriesIdToNew;

        state.data.liveTableOptions = response.data.liveTableOptions;
        state.data.liveTableSearch = response.data.liveTableSearch;
        state.data.historyOfForecastsSerialised = response.data.historyOfForecastsSerialised;

        response.data.jointSeriesEndpoints.forEach(endpoint => {
            axios.get(`${endpoint}/EarliestDateInBuffer`).then(result => {
                const earliestDayInBuffer = result.data.day === null ? null : moment({ year: result.data.year, month: result.data.month - 1, day: result.data.day });
                state.data.endpointToEarliestBufferDate[endpoint] = { earliestDayInBuffer };
            }).catch(err => {});
        });
    }).catch(e => console.log(`Please contact Enact Helpdesk - failed to get Static Store (${e})`));
}

async function getPlantStore (enactUser) {
    const enactUserUrl = `${process.env.VUE_APP_BASEADDRESS}/api/plantStore`;
    const freeUserUrl = `${process.env.VUE_APP_STATIC_API}/staticApi/plantStore`;

    let enactSuccess = false;
    if (enactUser) {
        enactSuccess = await getPlants(enactUserUrl);
    }
    if (!enactSuccess || !enactUser) {
        await getPlants(freeUserUrl);
    }
}

async function getPlants (endpoint) {
    let success = false;
    await axios.get(endpoint, {
        signal: newAbortSignal(7000),
    }).then((response) => {
        state.data.allPlants = response.data.allPlants;
        state.data.plantsByZone = response.data.plantsByZone;
        success = true;
    }).catch(e => console.log(`Please contact Enact Helpdesk - failed to get Plant Store (${e})`));
    return success;
}

function newAbortSignal (timeoutMs) {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeoutMs);
    return abortController.signal;
}

const staticStore = {
    installed: false,
    state,
    install (Vue, options) {
        this.installed = true;
        Vue.prototype.$staticStore = {
            state,
        };
    },
};

export { staticStore, getStaticStore, getPlantStore };

if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(staticStore);
    if (staticStore.installed) {
        staticStore.installed = false;
    }
}
