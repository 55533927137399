var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showDarkBackground
      ? _c("div", { staticClass: "dark-bg", on: { click: _vm.onClickOutside } })
      : _vm._e(),
    _c(
      "div",
      _vm._b(
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.onClickOutside,
              expression: "onClickOutside",
            },
          ],
          staticClass: "modal card",
          class: {
            popoverShadow: _vm.showShadow,
            boxShadow: _vm.showDarkBackground,
            midder: _vm.midCard,
            darker: _vm.darkCard,
            current: _vm.current,
          },
        },
        "div",
        _vm.$attrs,
        false
      ),
      [
        _c(
          "div",
          {
            staticClass: "header",
            style: { marginBottom: _vm.noTitleSpace ? "-10px" : "10px" },
          },
          [
            _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _c("span", {
              staticClass: "close icofont-close",
              on: { click: _vm.onClickOutside },
            }),
          ]
        ),
        _c(
          "div",
          { staticClass: "niceScroll", class: { maxHeight: _vm.maxHeight } },
          [_vm._t("default")],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }