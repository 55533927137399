<template>

    <div class="termsWrapper">
        <div class="top">
            <logo class="lcpLogo" :fontSize="40" />
        </div>
        <termsBase class="base"
        :showButtons="true"/>
    </div>
</template>

<script>
import logo from '@/components/home/logo';
import termsBase from './termsBase';

export default {
    data () {
        return {

        };
    },
    components: {
        termsBase,
        logo,
    },
};
</script>
<style lang="scss" scoped>
.termsWrapper {
    .top {
        width: 100%;
        height: 50px;
        position: fixed;
        top: 0;
        z-index: 98;
        background: var(--headerColour);
        box-shadow: 0 1px 1px 0px var(--shadowColour);
        line-height: 50px;
        color: #fff;
        font-size: 26px;
        padding: 5px 0 0 10px;
    }
    .base {
        margin-left: 10px;
        height: 100%;
    }
    margin-top: 50px;
    position: fixed;
    z-index: 99;
    height: 100vh;
}
</style>
