var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fixedModal",
    {
      attrs: {
        title: "Add your dashboards to the company portfolio",
        showDarkBackground: "",
      },
      on: { close: _vm.onClickOutside },
    },
    [
      _c("div", { staticClass: "shareWrapper row" }, [
        _c(
          "div",
          { staticClass: "dashboards niceScroll col-md-8" },
          _vm._l(_vm.selectedDashboards, function (dashboard, key) {
            return _c("div", { key: key }, [
              _c(
                "div",
                { staticClass: "card" },
                [
                  _c(
                    "p-check",
                    {
                      staticClass: "p-icon p-square p-fill p-smooth",
                      attrs: { name: "check", color: "primary" },
                      model: {
                        value: dashboard.selected,
                        callback: function ($$v) {
                          _vm.$set(dashboard, "selected", $$v)
                        },
                        expression: "dashboard.selected",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "icon icofont-check",
                        attrs: { slot: "extra" },
                        slot: "extra",
                      }),
                      _c("div", { staticClass: "wrapper" }, [
                        _c("div", { staticClass: "dbIcon" }, [
                          _c("span", {
                            class: "" + dashboard.icon,
                            style: { background: dashboard.colour },
                          }),
                        ]),
                        _c("div", { staticClass: "option__desc" }, [
                          _c("span", { staticClass: "option__id" }, [
                            _vm._v(_vm._s(dashboard.name)),
                          ]),
                          _c("span", { staticClass: "option__name" }, [
                            _vm._v(" " + _vm._s(dashboard.description) + " "),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
        _c("div", { staticClass: "col-md-4" }, [
          _c("div", { staticClass: "bottomAbsolute" }, [
            _c("div", { staticClass: "warningText" }, [
              _vm._v(
                "Please note this will add your dashboards to your company portfolio."
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "button",
                class: { disabled: _vm.buttonDisabled },
                on: { click: _vm.sendDashboards },
              },
              [_vm._v("Send Dashboards")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }