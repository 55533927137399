var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "buttonHolderHolder" },
        [
          _c("buttonHolder", {
            staticClass: "buttonHolder",
            attrs: {
              smaller: "",
              initialSelectedId: _vm.selectedDateOptionId,
              options: _vm.dateOptions,
            },
            on: {
              select: function (el) {
                return _vm.selectOption(el)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "buttonHolderHolder" },
        [
          _vm.smallDate && !_vm.useSingleDay
            ? _c("buttonHolder", {
                staticClass: "buttonHolder",
                attrs: {
                  smaller: "",
                  initialSelectedId: _vm.smallDateFromTo,
                  options: [
                    {
                      id: "from",
                      name: "From",
                    },
                    { id: "to", name: "To" },
                  ],
                },
                on: {
                  select: function (el) {
                    return (_vm.smallDateFromTo = el.id)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.selectedDateOptionId === "aggregate"
        ? _c("chartAggregate", {
            attrs: { aggregateOptionsInitial: _vm.aggregateOptions },
            on: {
              updateAggregateOptions: function (el) {
                return _vm.updateAggregateOptions(el)
              },
            },
          })
        : _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.close,
                  expression: "close",
                },
              ],
              staticClass: "dates-wrapper",
              class: { smaller: _vm.hideButtons, singleDay: _vm.useSingleDay },
            },
            [
              _c("div", { staticClass: "dates-flex" }, [
                !_vm.smallDate || _vm.smallDateFromTo === "from"
                  ? _c(
                      "div",
                      { staticClass: "left" },
                      [
                        !_vm.useSingleDay
                          ? _c("div", { staticClass: "header" }, [
                              _vm._v(_vm._s(_vm.fromTitle)),
                            ])
                          : _vm._e(),
                        _vm.selectedDateOptionId !== "hour"
                          ? [
                              _c("datepicker", {
                                style: {
                                  opacity:
                                    _vm.from !== _vm.fromToIndex("date", "from")
                                      ? 0.5
                                      : 1,
                                },
                                attrs: {
                                  inline: true,
                                  disabled:
                                    _vm.from !==
                                    _vm.fromToIndex("date", "from"),
                                  value: _vm.fromDateCal,
                                  placeholder: "Select date",
                                  minimumView: _vm.minimumView,
                                  format: _vm.dateFormat,
                                  disabledDates: _vm.disabledDates,
                                },
                                on: { input: _vm.updateFromDate },
                              }),
                              _c("buttonHolder", {
                                attrs: {
                                  smaller: "",
                                  watchInitial: "",
                                  initialSelectedId: "" + _vm.from,
                                  options: (this.useSingleDay
                                    ? _vm.singleDayChecks
                                    : _vm.fromDayChecks
                                  ).map(function (el, key) {
                                    return { id: "" + key, name: el.text }
                                  }),
                                },
                                on: {
                                  select: function (el) {
                                    return _vm.radioOptionChange(el.id, true)
                                  },
                                },
                              }),
                              _vm.from === _vm.fromToIndex("dynamDay", "from")
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.fromDaysOrHours,
                                        expression: "fromDaysOrHours",
                                      },
                                    ],
                                    staticClass: "input",
                                    attrs: {
                                      name: "days",
                                      type: "number",
                                      min: "1",
                                    },
                                    domProps: { value: _vm.fromDaysOrHours },
                                    on: {
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.fromDaysOrHours =
                                            $event.target.value
                                        },
                                        function ($event) {
                                          return _vm.debounceInputChanged()
                                        },
                                      ],
                                    },
                                  })
                                : _vm._e(),
                            ]
                          : [
                              !_vm.hideHours
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.useSingleDay,
                                          expression: "!useSingleDay",
                                        },
                                      ],
                                      staticClass: "item",
                                      staticStyle: {
                                        "margin-top": "5px",
                                        "margin-bottom": "5px",
                                      },
                                    },
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "start-hour" } },
                                        [_vm._v(" Start Time ")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.fromHoursForDay,
                                            expression: "fromHoursForDay",
                                          },
                                        ],
                                        staticClass: "timepicker",
                                        style: {
                                          opacity:
                                            _vm.from ===
                                            _vm.fromToIndex("hours", "from")
                                              ? 0.3
                                              : 1,
                                        },
                                        attrs: {
                                          name: "start-hour",
                                          type: "time",
                                        },
                                        domProps: {
                                          value: _vm.fromHoursForDay,
                                        },
                                        on: {
                                          input: [
                                            function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.fromHoursForDay =
                                                $event.target.value
                                            },
                                            function ($event) {
                                              return _vm.debounceInputChanged()
                                            },
                                          ],
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._l(
                                _vm.fromHourChecks,
                                function (fromHourCheck, fromKey) {
                                  return _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.useSingleDay,
                                          expression: "!useSingleDay",
                                        },
                                      ],
                                      key: fromHourCheck.text,
                                      staticClass: "item",
                                    },
                                    [
                                      _c(
                                        "p-radio",
                                        {
                                          staticClass:
                                            "p-icon p-round p-fill p-smooth",
                                          attrs: {
                                            value:
                                              fromKey +
                                              _vm.fromDayChecks.length,
                                            name: "from",
                                            color: "primary",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.radioOptionChange(
                                                $event,
                                                true
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.from,
                                            callback: function ($$v) {
                                              _vm.from = $$v
                                            },
                                            expression: "from",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "icon icofont-check",
                                            attrs: { slot: "extra" },
                                            slot: "extra",
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(fromHourCheck.text) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      fromHourCheck.input === "text"
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.fromDaysOrHours,
                                                expression: "fromDaysOrHours",
                                              },
                                            ],
                                            staticClass: "input",
                                            style: {
                                              opacity: !(
                                                _vm.from ===
                                                  _vm.fromToIndex(
                                                    "hours",
                                                    "from"
                                                  ) &&
                                                fromKey +
                                                  _vm.fromDayChecks.length ===
                                                  _vm.from
                                              )
                                                ? 0.5
                                                : 1,
                                            },
                                            attrs: {
                                              name: "days",
                                              type: "number",
                                              min: "1",
                                              disabled: !(
                                                _vm.from ===
                                                  _vm.fromToIndex(
                                                    "hours",
                                                    "from"
                                                  ) &&
                                                fromKey +
                                                  _vm.fromDayChecks.length ===
                                                  _vm.from
                                              ),
                                            },
                                            domProps: {
                                              value: _vm.fromDaysOrHours,
                                            },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.fromDaysOrHours =
                                                    $event.target.value
                                                },
                                                function ($event) {
                                                  return _vm.debounceInputChanged()
                                                },
                                              ],
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.useSingleDay &&
                          (!_vm.smallDate || _vm.smallDateFromTo === "to"),
                        expression:
                          "!useSingleDay && (!smallDate || smallDateFromTo === 'to')",
                      },
                    ],
                    staticClass: "right",
                  },
                  [
                    _c("div", { staticClass: "header" }, [_vm._v("To")]),
                    _vm.selectedDateOptionId !== "hour"
                      ? [
                          _c("datepicker", {
                            style: {
                              opacity:
                                _vm.to !== _vm.fromToIndex("date", "to")
                                  ? 0.5
                                  : 1,
                            },
                            attrs: {
                              inline: true,
                              disabled:
                                _vm.to !== _vm.fromToIndex("date", "to"),
                              value: _vm.toDateCal,
                              placeholder: "Select date",
                              minimumView: _vm.minimumView,
                              format: _vm.dateFormat,
                              disabledDates: _vm.disabledDates,
                            },
                            on: { input: _vm.updateToDate },
                          }),
                          _c("buttonHolder", {
                            attrs: {
                              smaller: "",
                              watchInitial: "",
                              initialSelectedId: "" + _vm.to,
                              options: _vm.toDayChecks.map(function (el, key) {
                                return { id: "" + key, name: el.text }
                              }),
                            },
                            on: {
                              select: function (el) {
                                return _vm.radioOptionChange(el.id, false)
                              },
                            },
                          }),
                          _vm.to === _vm.fromToIndex("dynamDay", "to")
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.toDaysOrHours,
                                    expression: "toDaysOrHours",
                                  },
                                ],
                                staticClass: "input",
                                attrs: {
                                  name: "days",
                                  type: "number",
                                  min: "1",
                                },
                                domProps: { value: _vm.toDaysOrHours },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.toDaysOrHours = $event.target.value
                                    },
                                    _vm.debounceInputChanged,
                                  ],
                                },
                              })
                            : _vm._e(),
                        ]
                      : [
                          !_vm.hideHours
                            ? _c(
                                "div",
                                {
                                  staticClass: "item",
                                  staticStyle: {
                                    "margin-top": "5px",
                                    "margin-bottom": "5px",
                                  },
                                },
                                [
                                  _c("label", { attrs: { for: "end-hour" } }, [
                                    _vm._v(" End Time "),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.toHoursForDay,
                                        expression: "toHoursForDay",
                                      },
                                    ],
                                    staticClass: "timepicker",
                                    style: {
                                      opacity:
                                        _vm.from ===
                                        _vm.fromToIndex("hours", "from")
                                          ? 0.3
                                          : 1,
                                    },
                                    attrs: { name: "end-hour", type: "time" },
                                    domProps: { value: _vm.toHoursForDay },
                                    on: {
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.toHoursForDay =
                                            $event.target.value
                                        },
                                        function ($event) {
                                          return _vm.debounceInputChanged()
                                        },
                                      ],
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._l(
                            _vm.toHourChecks,
                            function (toHourCheck, toKey) {
                              return _c(
                                "div",
                                { key: toHourCheck.text, staticClass: "item" },
                                [
                                  _c(
                                    "p-radio",
                                    {
                                      staticClass:
                                        "p-icon p-round p-fill p-smooth",
                                      attrs: {
                                        value: toKey + _vm.toDayChecks.length,
                                        name: "to",
                                        color: "primary",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.radioOptionChange(
                                            $event,
                                            false
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.to,
                                        callback: function ($$v) {
                                          _vm.to = $$v
                                        },
                                        expression: "to",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icon icofont-check",
                                        attrs: { slot: "extra" },
                                        slot: "extra",
                                      }),
                                      _vm._v(
                                        " " + _vm._s(toHourCheck.text) + " "
                                      ),
                                    ]
                                  ),
                                  toHourCheck.input === "text"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.toDaysOrHours,
                                            expression: "toDaysOrHours",
                                          },
                                        ],
                                        staticClass: "input",
                                        style: {
                                          opacity: !(
                                            _vm.to ===
                                              _vm.fromToIndex("hours", "to") &&
                                            toKey + _vm.toDayChecks.length ===
                                              _vm.to
                                          )
                                            ? 0.5
                                            : 1,
                                        },
                                        attrs: {
                                          name: "days",
                                          type: "number",
                                          min: "1",
                                          disabled: !(
                                            _vm.to ===
                                              _vm.fromToIndex("hours", "to") &&
                                            toKey + _vm.toDayChecks.length ===
                                              _vm.to
                                          ),
                                        },
                                        domProps: { value: _vm.toDaysOrHours },
                                        on: {
                                          input: [
                                            function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.toDaysOrHours =
                                                $event.target.value
                                            },
                                            _vm.debounceInputChanged,
                                          ],
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                  ],
                  2
                ),
              ]),
              _vm.infoText
                ? _c("div", { staticClass: "info" }, [
                    _vm._v(" " + _vm._s(_vm.infoText) + " "),
                  ])
                : _vm._e(),
            ]
          ),
      _c("div", { staticClass: "flex" }, [
        _vm.allowOverrideAll
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Overwrite dashboard date",
                    expression: "'Overwrite dashboard date'",
                  },
                ],
                staticClass: "button btn",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.debounceOverwiteDate.apply(null, arguments)
                  },
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.debounceOverwiteDate.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(" Update Page "),
                _c("span", { staticClass: "icofont-ui-calendar" }),
              ]
            )
          : _vm._e(),
        _vm.allowOverrideAll && _vm.overwriteDateMode
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Restore individual chart dates",
                    expression: "'Restore individual chart dates'",
                  },
                ],
                staticClass: "button btn",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.restoreCharts.apply(null, arguments)
                  },
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.restoreCharts.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(" Restore Charts "),
                _c("span", { staticClass: "icofont-undo" }),
              ]
            )
          : _vm._e(),
        !_vm.hideButtons && (!_vm.overwriteDateMode || _vm.isHeader)
          ? _c(
              "div",
              { staticClass: "button btn", on: { click: _vm.updateChart } },
              [
                _vm._v(" " + _vm._s(_vm.updateTextOverride) + " "),
                _c("span", { staticClass: "icofont-ui-calendar" }),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }