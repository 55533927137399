<template>
    <absoluteModal class="modal" noTitleSpace darkCard showShadow @close="onClickOutside" :style="getPosition()">
		<div class="filter slider" v-if="type === 'numeric' && config.headerData.allowFilter">
			<div class="row flex">
				<div class="flexInner">
					Min value
					<div class="input">
						<input
							type="text"
							:value="inputMin"
							v-on:blur="throttleFilter('min', $event)"
							v-on:keyup="throttleFilter('min', $event)"
						/>
					</div>
				</div>
				<div class="flexInner">
					Max value
					<div class="input text-right">
						<input
							type="text"
							:value="inputMax"
							v-on:blur="throttleFilter('max', $event)"
							v-on:keyup="throttleFilter('max', $event)"
						/>
					</div>
				</div>
			</div>
			<p-check name="check" class="p-icon p-square p-fill p-smooth" v-model="absoluteValue" @change="updateAbsoluteValue" color="primary">
				<i slot="extra" class="icon icofont-check"></i>
				Absolute values
			</p-check>
			<i class="icofont-question-circle" v-tooltip="'Allows the above filter to be applied to both positive and negative values\n (e.g. a range of 10 to 100 with absolute values on will allow values of 10-100 and -10 to -100)'"/>
		</div>
		<div v-if="type === 'text'">
			<p-check name="check" style="margin-bottom: 5px" class="p-icon p-square p-fill p-smooth" v-model="excludeSelection" @change="updateExcludeSelection" 
			color="primary" v-if="config.headerData.allowFilter" >
				<i slot="extra" class="icon icofont-check"></i>
				Exclude selection
			</p-check>
			<i class="icofont-question-circle" v-tooltip="'When the Exclude button is clicked, the selected options below dictate what not to show.'" v-if="config.headerData.allowFilter"/>
			<multiSelect
				v-if="config.headerData.allowFilter && !usePlantDropdown && !useFuelDropdown"
				:options="possibleTextOptions"
				:showLabels="false"
				class="multiselect"
				placeholder="Select Option"
				v-model="selectedOptions"
				openDirection="below"
				:multiple="true"
				:close-on-select="false"
				:clear-on-select="false"
				@input="updateFilterText"
			/>
			<template v-if="config.headerData.allowFilter && usePlantDropdown">
				<buttonHolder style="margin-bottom: 10px" :initialSelectedId="plantOption" :options="plantSelector" @select="o => selectPlantOption(o.id)" />
				<multiselectPlant
					style="margin-bottom: 10px"
					v-if="plantOption === 'plant'"
					allowMultiple variableMultiselectHeight
					:categoryOverrides="['BmPlant', 'NonBmPlant']"
					:initialArraySelect="selectedOptions"
					@select="selectPlant"
					fullWidth
					openDirection="below"
				></multiselectPlant>
				<multiSelect
					style="margin-bottom: 10px"
					v-else
					:options="myPlantGroups"
					:showLabels="false"
					placeholder="Select group"
					v-model="selectedPlantGroup"
					openDirection="below"
					label="name"
					trackBy="id"
					@input="updatePlantGroup"
				/>
			</template>
			<multiselectFuel
				allowMultiple
				:initialArraySelect="selectedOptions"
				:useId="false"
				v-if="config.headerData.allowFilter && useFuelDropdown"
				@select="selectFuel"
				openDirection="below"
			></multiselectFuel>
		</div>
		<div v-if="type === 'date' && config.headerData.allowFilter">
			<dateRangePicker :info="this.config.currentFilters == null ? {} : config.currentFilters.options" hideButtons includeNone @dateChange="dateChange" />
		</div>

		<div class="filter slider" v-if="config.headerData.allowSort && allowSort">
			<div class="filter-title">
				<span>Sort</span>
			</div>
			<div class="filter-content">
				<div class="row flex">
					<div class="flexInner">
						<div
							class="button"
							:class="sortingOrder === true ? 'active' : ''"
							@click="updateSort(true)"
						>
							Low to High
						</div>
					</div>
					<div class="flexInner">
						<div
							class="button"
							:class="sortingOrder === false ? 'active' : ''"
							@click="updateSort(false)"
						>
							High to Low
						</div>
					</div>
				</div>
			</div>
		</div>
	</absoluteModal>
</template>

<script>
import multiSelect from 'vue-multiselect';
import dateRangePicker from '@/components/inputs/datePicker/dateRangePicker';
import multiselectPlant from '@/components/layout/multiselectPlant';
import multiselectFuel from '@/components/layout/multiSelectFuel';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import absoluteModal from '@/components/layout/absoluteModal';
import debounce from 'lodash/debounce';
/* eslint-disable indent */
import buttonHolder from '@/components/layout/buttonHolder';

export default {
	name: 'genericHeaderFilter',
    props: {
        config: {
            type: Object,
            required: true,
        },
        allowSort: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    components: {
        multiSelect,
        dateRangePicker,
        multiselectPlant,
        multiselectFuel,
		absoluteModal,
		buttonHolder,
    },
    data () {
        return {
            inputMin: null,
            inputMax: null,
            absoluteValue: false,
			excludeSelection: false,
            sortingOrder: null, // temp variable, shouldn't exist, should get this from config prop. Back-end also needs to send which column is to be sorted in the header
            type: '',
            possibleTextOptions: [], // also temp, should be in a prop which is updated on the emit
            selectedOptions: [], // also temp, should be in a prop which is updated on the emit
            usePlantDropdown: false,
            useFuelDropdown: false,
            inputSelected: null,
            valueChanged: null,

			plantSelector: [{
				id: 'plant',
				name: 'Select plants',
			}, {
				id: 'group',
				name: 'My groups',
			}],
			plantOption: 'plant',
			myPlantGroups: [
				{ name: 'Show all', id: 'all' },
                { name: 'Show units on bingo board', id: 'bingoEnact' },
                { name: 'Show units not on bingo board', id: 'notBingoEnact' },
            ],
			selectedPlantGroup: null,
        };
    },
    created () {
        this.debounceInput = debounce(this.validateInputs, 400);
        this.sortingOrder = this.config.currentSortAsc;

        switch (this.config.sampleDataPoint.cellType) {
        case 'number':
            this.numericConfigCreate();
            this.type = 'numeric';
            break;
        case 'date':
            this.type = 'date';
            break;
        case 'text':
            this.selectedOptions = this.config.selectedOptions;
			this.excludeSelection = this.config.currentFilters != null && this.config.currentFilters.options.exclude !== undefined && this.config.currentFilters.options.exclude;
            this.possibleTextOptions = this.config.uniqueData;
            if (
                (!this.possibleTextOptions || this.possibleTextOptions.length == 0)
				&& this.config.sampleDataPoint.isPlant
            ) {
                this.usePlantDropdown = true;
				Object.keys(this.trackedPlantsByGroup).forEach((plantGroup, index) => {
					this.myPlantGroups.push({
						name: plantGroup,
						id: plantGroup,
					});
				});
				if (this.config.group != null) {
					this.plantOption = 'group';
					this.selectedPlantGroup = this.myPlantGroups.find(el => el.id === this.config.group);
				}
            }
            if (
                (!this.possibleTextOptions || this.possibleTextOptions.length == 0)
				&& (this.config.headerData.title === 'Fuel' || this.config.headerData.title === 'Secondary Fuel')
            ) {
                this.useFuelDropdown = true;
            }
            this.type = 'text';
            break;
        }
    },
    computed: {
		trackedPlantsByGroup () {
            return this.$store.state.user.chosenPlantsByGroup;
        },
        bingoPlants () {
            return this.$store.state.data.pages.bingo.data.plantData;
        },
	},
    methods: {
		selectPlantOption (option) {
			this.plantOption = option;
		},
		updatePlantGroup (group) {
			this.selectedOptions = [];
			this.updateFilterText();
        },
		dateChange (dateFormat) {
            this.$emit('filterUpdate', {
                type: 'date',
				options: { date: dateFormat },
                noFilter: dateFormat.from.type === 'none' && dateFormat.to.type === 'none', // startTime == null && endTime == null,
            });
		},
        throttleFilter (type, event) {
            this.inputSelected = type;
            this.valueChanged = event.target.value;
            this.debounceInput();
        },
        selectPlant (el) {
			this.selectedPlantGroup = null;
            this.selectedOptions = el.map((e) => e.id);
            this.updateFilterText(null);
        },
        selectFuel (el) {
            this.selectedOptions = el.map((e) => e.name);
            this.updateFilterText(null);
        },
        onClickOutside () {
            this.$emit('closeFilter');
        },
        getPosition () {
            return {
                left: `${this.config.position.left}px`,
                width: this.type === 'date' ? '' : '350px',
            };
        },
        updateSort (sort) {
            this.sortingOrder = sort;
            this.$emit('sortUpdate', sort);
        },
        updateAbsoluteValue () {
            this.updateFilterNumeric();
        },
		updateExcludeSelection () {
			this.updateFilterText();
		},
        updateFilterNumeric () {
            if (this.absoluteValue) {
                this.inputMin = this.inputMin === null ? null : Math.abs(this.inputMin);
                this.inputMax = this.inputMax === null ? null : Math.abs(this.inputMax);
            }

            this.$emit('filterUpdate', {
                type: 'numeric',
                options: {
                    min: this.inputMin,
                    max: this.inputMax,
                    absolute: this.absoluteValue,
                },
                noFilter:
					this.inputMin === null
					&& this.inputMax === null,
            });
        },
        updateFilterText () {
            this.$emit('filterUpdate', {
                type: 'text',
                options: {
                    values: this.selectedOptions,
					group: this.selectedOptions.length === 0 && this.selectedPlantGroup != null && this.selectedPlantGroup.id !== 'all' ? this.selectedPlantGroup.id : null,
					exclude: this.excludeSelection,
                },
                noFilter: this.selectedOptions.length === 0 && (this.selectedPlantGroup == null || this.selectedPlantGroup.id === 'all'),
            });
        },
        updateDateValues (value) {
            const startTime = value.startDate;
            if (startTime) startTime.setHours(0, 0, 0, 0);
            const endTime = value.endDate;
            if (endTime) endTime.setHours(23, 59, 59, 999);

            this.$emit('filterUpdate', {
                type: 'date',
                options: {
                    min: startTime ? startTime.getTime() / 1000 : null,
                    max: endTime ? endTime.getTime() / 1000 : null,
                },
                noFilter: startTime == null && endTime == null,
            });
        },
        validateInputs () {
            const newVal = parseFloat(this.valueChanged);
            const newValChecked = Number.isNaN(newVal) ? null : newVal;
            if (this.inputSelected === 'min') {
                this.inputMin = newValChecked;
            } else {
                this.inputMax = newValChecked;
            }
            this.updateFilterNumeric();
        },
        numericConfigCreate () {
            const hasCurrent = this.config.currentFilters != null;

            this.inputMin = hasCurrent
                ? this.config.currentFilters.options.min
                : null;
            this.inputMax = hasCurrent
                ? this.config.currentFilters.options.max
                : null;
            this.absoluteValue = hasCurrent
                ? (this.config.currentFilters.options.hasOwnProperty('absolute') ? this.config.currentFilters.options.absolute : false)
                : false;
        },
    },
};
</script>
<style lang="scss" scoped>
.bg {
	position: fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	z-index: 99;
}
.modal {
	font-size: 12px !important;

	.multiselect {
		width: 100%;
		margin-bottom: 20px;
	}

	::v-deep .reportrange-text {
		background: none;
		cursor: pointer;
		padding: 0;
		border: none;
		width: 100%;
		margin-bottom: 20px;
		.dateInput {
			.visibleDates {
				color: $primary;
				background: var(--dark);
				border: none;
				padding: 10px;
			}
		}
	}
	.dateCancel {
		background: $primary;
		border-radius: 5px;
		color: white;
		display: inline-block;
		cursor: pointer;
		margin-left: 10px;
		padding: 5px;
	}

	::v-deep .daterangepicker {
		.drp-buttons .btn {
			cursor: pointer;
			display: inline-block;
			height: 40px;
			line-height: 30px;
			margin: 10px;
			padding: 5px 10px 5px 10px;
			text-align: center;
			background: $primary;
			color: white;
			border: none;
		}
		.calendars {
			&.row {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	.filter {
		&.slider {
			margin-top: 10px;
		}
		.flex {
			display: flex;
			justify-content: space-between;
			.flexInner {
				margin-left: 15px;
				margin-right: 15px;
			}
		}
		.p-icon {
			margin-top: 10px;
		}
		.text-center {
			text-align: center;
		}
		.text-right {
			text-align: right;
			input {
				text-align: right;
			}
		}
		.filter-title {
			margin-bottom: 10px;
		}
		.filter-content {
			.button {
				background: var(--mid);
				line-height: 20px;
				color: black;
				height: 30px;
				&.active {
					background: $primary;
					color: white;
				}
			}
		}
		margin-bottom: 2px;
		.input {
			margin-top: 5px;
			input {
				width: 100%;
				border: none;
				padding: 5px;
				background: var(--mid);
				color: $primary;
				font-size: 16px;
				font-weight: bold;
				box-shadow: inset 0 0 10px #ebebed;
			}
		}
	}
}
.dark {
	.modal {
		color: white;
		.input {
			input {
				box-shadow: inset 0 0 10px $dmMed;
			}
		}
		.filter {
			.button {
				color: white;
			}
		}
	}
}
</style>

<style lang="scss">
.dark {
	::v-deep .reportrange-text {
		.dateInput {
			.visibleDates {
				background: $dmMed !important;
				color: white;
			}
		}
	}
	::v-deep .daterangepicker {
		background: $dmMed;
		border: $dmMed;
		.calendar-table {
			background: $dmMed;
			border: $dmMed;
			th,
			td {
				background: $dmMed;
				&:hover {
					background: $dmDark;
				}
				&.active {
					background: $primary;
				}
				&.inRange {
					background: $PRIMARYTINT1 !important;
				}
			}
		}
		.calendars {
			.ranges {
				li {
					&:hover {
						background: $dmDark;
					}
				}
			}
		}
	}
}
</style>
