import store from '@/store';
import { uuid } from 'vue-uuid';
import { BroadcastChannel as BroadCastChannel } from 'broadcast-channel';

let userSettingsChangeBroadcastChannel = null;
const uniqueTabId = uuid.v4();

function subscribeToOtherTabUserSettingsChange () {
    userSettingsChangeBroadcastChannel = new BroadCastChannel('user_settings_change');

    userSettingsChangeBroadcastChannel.onmessage = (ev) => {
        if (ev.data !== uniqueTabId) { // This check might not be needed
            switch (store.state.user.tabUpdateValue) {
            case 'warn':
                store.commit('accessDeniedModalText', {
                    type: 'release',
                    text: 'Your User Settings has been updated on another tab/window, we recommend that you refresh before making any changes on this screen to avoid clashes.<br />This message can be turned on/off in your User Settings.',
                });
                break;
            case 'update':
                store.dispatch('user/getUserSettings', store.state.user.userId);
                break;
            case 'none':
                break;
            }
        }
    };
}

function sendOtherTabUserSettingsChange () {
    userSettingsChangeBroadcastChannel.postMessage(uniqueTabId);
}

export { subscribeToOtherTabUserSettingsChange, sendOtherTabUserSettingsChange };
