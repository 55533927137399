var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "font-size": "13px" } }, [
    _c(
      "div",
      { staticClass: "holder" },
      [
        _vm._v(" Granularity: "),
        _c("i", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: "This will only reduce the granularity of a series.",
              expression:
                "'This will only reduce the granularity of a series.'",
            },
          ],
          staticClass: "icofont-question-circle",
        }),
        _c("buttonHolder", {
          attrs: {
            options: _vm.aggregateGranularities,
            initialSelectedId: _vm.aggregateOptions.granularity,
          },
          on: {
            select: function (o) {
              return (_vm.aggregateOptions.granularity = o.id)
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "holder" },
      [
        _vm._v(" Type: "),
        _c("buttonHolder", {
          attrs: {
            options: _vm.types,
            initialSelectedId: _vm.aggregateOptions.type,
          },
          on: {
            select: function (o) {
              return (_vm.aggregateOptions.type = o.id)
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "holder" },
      [
        _c(
          "p-check",
          {
            staticClass: "p-switch p-fill",
            attrs: { disabled: _vm.aggregateOptions.delta },
            model: {
              value: _vm.aggregateOptions.cumulative,
              callback: function ($$v) {
                _vm.$set(_vm.aggregateOptions, "cumulative", $$v)
              },
              expression: "aggregateOptions.cumulative",
            },
          },
          [_vm._v(" Cumulative ")]
        ),
        _c("i", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value:
                "Calculate the cumulative series for the data aggregated as specified above.<br />MW series are converted to MWh.",
              expression:
                "'Calculate the cumulative series for the data aggregated as specified above.<br />MW series are converted to MWh.'",
            },
          ],
          staticClass: "icofont-question-circle",
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "p-check",
          {
            staticClass: "p-switch p-fill",
            attrs: { disabled: _vm.aggregateOptions.cumulative },
            on: { change: _vm.deltaEnabledUpdated },
            model: {
              value: _vm.deltaEnabled,
              callback: function ($$v) {
                _vm.deltaEnabled = $$v
              },
              expression: "deltaEnabled",
            },
          },
          [_vm._v(" Delta ")]
        ),
        _c("i", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value:
                "Calculate the delta series for the data aggregated as specified above.<br />Each point is displayed as the difference to a point in the past.<br />The point to compare to is the number of points in the past that you select.",
              expression:
                "'Calculate the delta series for the data aggregated as specified above.<br />Each point is displayed as the difference to a point in the past.<br />The point to compare to is the number of points in the past that you select.'",
            },
          ],
          staticClass: "icofont-question-circle",
        }),
        _c("enactInput", {
          staticStyle: {
            width: "50px",
            display: "inline-block",
            "margin-left": "10px",
          },
          attrs: {
            doNotEmitOnConstructor: "",
            disabled: !_vm.deltaEnabled,
            showTooltipError: false,
            useInputInsteadOfTextArea: "",
            inputType: "number",
            startingVal: "" + _vm.aggregateOptions.delta,
          },
          on: {
            textChange: function (el) {
              _vm.aggregateOptions.delta = parseFloat(el.content)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }