<template>
    <div class="periodSelector" :class="{ 'centerPeriod': $store.state.ui.pageName === 'boa' || $store.state.ui.pageName === 'boaComparer' }">
        <datepicker class="clickable" :value="dateString" @input="updateCurrentDate" :disabledDates="disabledDates"></datepicker>
        <!-- <span class="divider" v-show="showPeriod">|</span> -->
        <span class="periodInlineWrapper" v-show="showPeriod">
            <i class="icofont-rounded-up icofont-rotate-270" @click="periodChanger('down')"></i>
            <div class="periodInline"  v-click-outside="closeDropdown">
                <span @click="periodDropdownVisible = !periodDropdownVisible"
                :class="{'tradingPeriod': useTrading}">{{currentPeriod}} </span>
                <div v-show="periodDropdownVisible" class="periodDropdown card" >
                    <div class="title">Jump to period</div>
                    <div v-for="i in availablePeriods" class="cell" :key="i" :class="{'selected' : currentPeriod === i}" @click="jumpToPeriod(i)">{{i}}</div>
                </div>
            </div>
            <i class="icofont-rounded-up icofont-rotate-90" @click="periodChanger('up')"></i>
            <span v-show="pastFuturePresent !== 'present'" class="jumpToPresent" v-tooltip="'Jump to live period'" @click="jumpToLive">
                <span class="icofont-clock-time"></span>
            </span>
        </span>
        <span class="divider" v-show="showTimer">|</span>
        <countdownTimer v-show="showTimer" :restartCountdown="restartCountdown"/>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import ClickOutside from 'vue-click-outside';
import countdownTimer from '@/components/layout/countdownTimer';

export default {
    props: {
        initialDate: {
            type: Date,
            default: () => new Date(),
        },
        enabled: {
            type: Boolean,
        },
        showPeriod: {
            type: Boolean,
            default: false,
            required: true,
        },
        disabledDateOverride: {
            type: Object,
            default: null,
            required: false,
        },
    },
    directives: {
        ClickOutside,
    },
    components: {
        Datepicker,
        countdownTimer,
    },
    data () {
        return {
            currentDate: '',
            periodDropdownVisible: false,
            disabledDates: {
                from: this.$moment().add(1, 'd').toDate(),
            },
            restartCountdown: false,
        };
    },
    created () {
        if (this.disabledDateOverride) this.disabledDates = this.disabledDateOverride;
    },
    methods: {
        updateCountdown () {
            this.restartCountdown = !this.restartCountdown;
        },
        periodChanger (dir) {
            let period = this.currentPeriod;

            if (dir === 'up') {
                if (this.availablePeriods > period) {
                    this.$emit('currentDate', { date: this.currentDate, period: ++period });
                } else {
                    this.$emit('currentDate', { date: this.currentDate.add(1, 'days'), period: 1, changingDateAndPeriod: true });
                }
            } else if (dir === 'down') {
                if (period > 1) {
                    this.$emit('currentDate', { date: this.currentDate, period: --period });
                } else {
                    this.$emit('currentDate', { date: this.currentDate.add(-1, 'days'), period: this.availablePeriods, changingDateAndPeriod: true});
                }
            }
            this.updateCountdown();
        },
        updateCurrentDate (newDate) {
            this.currentDate = this.$moment(newDate);
            this.$emit('currentDate', { date: this.currentDate, period: this.currentPeriod });
            this.updateCountdown();
        },
        jumpToPeriod (period) {
            this.$emit('currentDate', { date: this.currentDate, period });
            this.updateCountdown();
        },
        closeDropdown () {
            if (this.periodDropdownVisible) this.periodDropdownVisible = false;
        },
        jumpToLive () {
            // using moment not current date as we may be in a other day
            if (this.$store.state.data.analysisDate.livePeriod !== null) {
                this.$emit('currentDate', { date: this.$moment(), period: this.$store.state.data.analysisDate.livePeriod, changingDateAndPeriod: true });
            } else {
                this.$store.dispatch('getCurrentPeriod').then((response) => {
                    this.$emit('currentDate', { date: this.$moment(), period: response.period });
                });
            }
            this.updateCountdown();
        },
    },
    computed: {
        dateString () {
            return this.$moment(this.currentDate).toString();
        },
        startOfDay () {
            return this.$moment(this.currentDate).startOf('day');
        },
        currentPeriod () {
            return this.$store.state.data.analysisDate.period;
        },
        availablePeriods () {
            return this.$store.state.data.analysisDate.periodsInDay;
        },
        pastFuturePresent () {
            return this.$store.state.data.analysisDate.periodStatus;
        },
        useTrading () {
            return this.$store.getters['boaWindowType'] === 'trading' && (this.$store.getters.hasBoa || this.$store.state.ui.pageName === 'boaComparer');
        },
        showTimer () {
            return this.$store.state.user.showTimer && (this.$store.getters.hasBoa || this.$store.state.ui.pageName === 'boaComparer');
        },
    },
    watch: {
        initialDate: {
            handler (newVal, oldVal) {
                this.currentDate = this.$moment(newVal);
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/base.scss';
.periodSelector {
    display: inline-block;
    position: relative;
    font-size: 20px;
    font-weight: 450;
    line-height: 30px;
    color: $primary;
    text-align: center;
    &.centerPeriod {
        @include responsive('xlUp') {
            position: absolute;
        }
    }
    .clickable {
        ::v-deep input {
            cursor: pointer;
            width: 150px;
            font-size: 20px;
            font-weight: 450;
            color: $primary;
        }
    }
    .divider {
        color: $mid-grey;
        padding: 0 5px;
        &.left {
            margin-left: -20px;
            margin-right: 10px;
        }
        &.right {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    i {
        color: $primary;
        cursor: pointer;
        user-select: none;
    }
    .periodInlineWrapper{
        position: relative;
        .jumpToPresent{
            color: $primary;
            cursor: pointer;
                display: inline-block;
                margin-right: 5px;
        }
        .play {
            margin-left: 10px;
        }
        .periodInline {
            cursor: pointer;
            display: inline-block;
            position: relative;
            width: 30px;
            .tradingPeriod{
                color: $ALTERNATIVE1;
            }
            .periodDropdown {
                color: black;
                border: 1px solid #ccc;
                font-size: 16px;
                left:-130px;
                padding: 0;
                position: absolute;
                text-align: left;
                top:40px;
                width: 300px;
                z-index: 10;
                .title {
                    border-bottom: 1px solid #ccc;
                    text-align: center;
                }
                .cell {
                    border: 1px solid transparent;
                    cursor: pointer;
                    display: inline-block;
                    height: 40px;
                    line-height: 40px;
                    padding: 0 5px;
                    text-align: center;
                    vertical-align: middle;
                    width: 14.285714285714286%;
                    &:hover {
                        border: 1px solid $primary !important
                    }
                    &.selected {
                        background: $primary;
                        color: white;
                    }
                }
            }
        }
    }
    .dateSwitcherInline {
        cursor: pointer;
        display: inline-block;
        position: relative;
        width: 30px;
        .dateSwitcherDropdown {
            border: 1px solid #ccc;
            font-size: 16px;
            left:-130px;
            padding: 0;
            position: absolute;
            text-align: left;
            top:40px;
            width: 300px;
            z-index: 10;
            .title {
                border-bottom: 1px solid #ccc;
                text-align: center;
            }
            .type {
                border: 1px solid transparent;
                cursor: pointer;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                padding: 0 5px;
                text-align: center;
                vertical-align: middle;
                width: 100%;
                &:hover {
                    border: 1px solid $primary !important
                }
                &.selected {
                    background: $primary;
                    color: white;
                }
            }
        }
    }
}
.dark {
    .periodSelector {
        .periodInlineWrapper {
            .periodInline {
                .periodDropdown {
                    background:$dmDark;
                    border: 1px solid $dmLight;
                    color: white;
                    .title{
                        border-bottom: 1px solid $dmLight
                    }
                }
            }
        }
    }
}
@media (max-width: 1536px) {
    .periodSelector {
        font-size: 16px;
        .clickable {
            ::v-deep input {
                font-size: 16px;
                width: 100px;
            }
        }
    }
}
</style>

<style lang="scss">
@import '@/assets/scss/base.scss';
.vdp-datepicker {
    display:inline-block;
    margin-right: 5px;
    margin-left: 5px;
    input {
        border: none;
        display: inline-block;
        font-family: inherit;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        width: 200px;
        background: transparent;
    }
    .vdp-datepicker__calendar {
        font-size: 16px;
        .prev {
            &::after {
                border-right: 10px solid $primary;
            }
        }
        .next {
            &::after {
                border-left: 10px solid $primary;
            }
        }
        .cell {
            // border-radius:50%;
            &.selected {
                background: $primary;
                color: white;
            }
            &:hover {
                border: 1px solid $primary!important;
            }
        }
    }
}
@media (max-width: 1536px) {
    .vdp-datepicker  {
        input {
            font-size: 18px;
            width: 160px;
        }
    }
}
</style>
