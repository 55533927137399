var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "absoluteModal",
    {
      staticClass: "modal",
      attrs: { showDarkBackground: "", title: "My Account" },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "email" }, [_vm._v(_vm._s(_vm.userEmail))]),
          _c(
            "router-link",
            { staticClass: "button", attrs: { to: "/logout" } },
            [
              _c("i", { staticClass: "iconLink", class: "icofont-logout" }),
              _c("span", { staticClass: "textLink" }, [_vm._v("Logout")]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }