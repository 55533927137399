<template>
    <div class="buttonHolder" :class="{ secondStyle: secondStyle, 'vertical niceScroll': verticalList }">
        <div class="button delete" :class="{ 'smaller': smaller }" v-if="addSelectAll"
            @click="toggleSelectAll" @keyup.enter="toggleSelectAll"
        >
            {{ isAllSelected ? 'Deselect All' : 'Select All' }}
        </div>
        <div v-for="option in options" :key="option.id"
            class="button"
            :class="{ 'selected': selectedOptions.includes(option.id), 'disabled': disabled || option.disabled, 'darkText': darkText, 'smaller': smaller, 'spaceLeft': option.space,
             'darkBackground': darkBackground}"
            @click.stop="selectOption(option)" @keyup.enter="selectOption(option)" v-tooltip="option.tooltip"
        >
            <disabled-icon v-if="option.disabled" class="lockIcon" :accessLevel="option.disabled" v-tooltip="`${option.disabled}`" />
            {{option.name}}
            <i v-if="option.info" class="icofont-info" v-tooltip="option.info" />
            <i v-if="option.icon" :class="`icofont-${option.icon}`" :style="{ color: option.colour }" />
        </div>
        <div class="button delete" :class="{ 'smaller': smaller }" v-if="allowNoFilter && selectedOptions.length !== 0"
            @click="clearOptions" @keyup.enter="clearOptions"
        >
            <i class="icofont-ui-close"></i>
        </div>
    </div>
</template>

<script>

import disabledIcon from '@/components/layout/disabledIcon';

export default {
    name: 'buttonHolder',
    props: {
        options: {
            type: Array,
            required: true,
        },
        initialSelectedId: {
            type: String,
            required: false,
            default: null,
        },
        initialSelectedArray: {
            type: Array,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        darkText: {
            type: Boolean,
            required: false,
            default: false,
        },
        smaller: {
            type: Boolean,
            required: false,
            default: false,
        },
        watchInitial: {
            type: Boolean,
            required: false,
            default: false,
        },
        secondStyle: {
            type: Boolean,
            required: false,
            default: false,
        },
        darkBackground: {
            type: Boolean,
            required: false,
            default: false,
        },
        addSelectAll: {
            type: Boolean,
            required: false,
            default: false,
        },
        allowNoFilter: {
            type: Boolean,
            required: false,
            default: false,
        },
        noInitialSelect: {
            type: Boolean,
            required: false,
            default: false,
        },
        allowMultiple: {
            type: Boolean,
            required: false,
            default: false,
        },
        verticalList: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        disabledIcon,
    },
    data () {
        return {
            selectedOptions: [],
            isAllSelected: false,
        };
    },
    async created () {
        this.selectInitial();
        this.isAllSelected = this.selectedOptions.length === this.options.length;
    },
    methods: {
        toggleSelectAll() {
            if (this.isAllSelected) {
                this.deselectAllOptions();
            } else {
                this.selectAllOptions();
            }
        },
        selectAllOptions () {
            this.selectedOptions = this.options.map(el => el.id);
            this.$emit('select', this.selectedOptions);
            this.isAllSelected = true;
        },
        deselectAllOptions () {
            this.selectedOptions = [];
            this.$emit('select', this.selectedOptions);
            this.isAllSelected = false;
        },
        clearOptions () {
            this.selectedOptions.splice(0);
            this.$emit('select', this.allowMultiple ? this.selectedOptions : { id: null, name: null });
        },
        selectOption (option) {
            if (option.disabled || this.disabled) return;

            if (this.allowMultiple) {
                if (this.selectedOptions.includes(option.id)) this.selectedOptions.splice(this.selectedOptions.indexOf(option.id), 1);
                else this.selectedOptions.push(option.id);
            }
            else this.selectedOptions = [option.id];

            this.$emit('select', this.allowMultiple ? this.selectedOptions : option);
            this.isAllSelected = this.selectedOptions.length === this.options.length;
        },
        selectInitial () {
            if ((this.allowNoFilter && !this.initialSelectedId && !this.initialSelectedArray) || this.noInitialSelect) this.selectedOptions = [];
            else if (this.initialSelectedId !== null) {
                if (!this.allowMultiple) this.selectedOptions = [this.initialSelectedId];
                else this.selectedOptions.push(this.initialSelectedId);
            } else if (!this.allowMultiple) {
                this.selectedOptions.push(this.options[0].id);
            } else if (this.initialSelectedArray) {
                this.selectedOptions = this.initialSelectedArray;
            }
            this.isAllSelected = this.selectedOptions.length === this.options.length;
        },
    },
    watch: {
        // options () {
        //     if (this.options.find(el => this.selectedOptions.includes(el.id)) == null) {
        //         this.selectInitial();
        //         if (!this.allowMultiple) this.$emit('select', this.allowMultiple ? [{ id: this.selectedOption }] : { id: this.selectedOption });
        //     }
        // },
        initialSelectedId () {
            if (this.watchInitial) {
                this.selectInitial();
            }
        },
        initialSelectedArray () {
            if (this.watchInitial) {
                this.selectInitial();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.buttonHolder {
    .button {
        .lockIcon {
            font-size: 15px;
            @include responsive("sm") {
                padding-right: 0;
            }
        }
        &.spaceLeft {
            margin-left: 20px;
        }
        &.delete {
            color: $primary;
            border-bottom: none;
        }
        @include responsive("md") {
            padding-top: 0;
            padding-bottom: 0;
            height: 30px;
        }
        &.smaller {
            padding: 0 5px;
            height: 30px;
            line-height: 30px;
        }
        &.darkBackground {
            background-color: $iconGrey;
        }
    }
}
</style>
