<template>
    <absoluteModal showDarkBackground title="My Account" class="modal" @close="close">
        <div class="content">
            <div class="email">{{userEmail}}</div>

            <!--<div class="button" @click="toggleSettings">
                <font-awesome-icon class="iconLink" :icon="['fad', 'cogs']" />
                <span class="textLink">Settings</span>
            </div>-->

            <router-link to="/logout" class="button">
                <i class="iconLink" :class="`icofont-logout`"></i>
                <span class="textLink">Logout</span>
            </router-link>
        </div>
    </absoluteModal>
</template>

<script>
import vClickOutside from 'v-click-outside';
import absoluteModal from '@/components/layout/absoluteModal';

export default {
    name: 'userModal',
    components: {
        absoluteModal,
    },
    props: {

    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    data () {
        return {

        };
    },
    created () {

    },
    computed: {
        userEmail () {
            return this.$store.state.user.userEmail;
        },
    },
    methods: {
        toggleSettings () {
            this.$store.commit('initiateSlideOutContent', {
                data: { title: '' },
                component: 'components/slideOut/settings.vue',
            });
        },
        close () {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.modal {
    position: absolute;
    top: 50px;
    right: 100px;
    z-index: 99;
    @include responsive("sm") {
        right: 10px;
    }
    .header {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
        color: var(--primaryFont);
    }
    .content {
        color: var(--primaryFont);
        .button {
            color: var(--primaryFont);
            display: flex;
            justify-content: space-between;
            text-align: left;
            text-decoration: none;
            margin: 10px;
            .iconLink {
                color: var(--primaryFont);
                font-size: 20px;
                margin: 5px 5px 0 5px;
                width: 20px;
                text-align: left;
                text-decoration: none;
            }
            .textLink {
                padding: 0 10px;
            }
        }
    }
}
</style>
