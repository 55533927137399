<template>
    <div>
        <div class="terms">
            <div class="card holder">
                <h1>LCP Enact Terms & Conditions</h1>
                <div class="termsHolder niceScroll" ref="termsHolder">
                    <p>By using Enact, you are agreeing to the terms stated here:
                        <a href="https://delta.lcp.com/technology-products-standard-terms/">LCP Delta technology products standard terms</a>
                    </p>
                    <h1>LCP Enact Authorised User Confirmation</h1>
                    <p>You acknowledge and agree: (a) that these Terms and Conditions (including, without limitation, the exclusions and limitations of liability in Sections 6,8 and 9) are fair, reasonable and necessary for LCP in the protection of its Confidential Information, intellectual property rights and its business; (b) having reviewed these Terms and Conditions, that there is nothing which would breach, or cause the breach of, or would conflict with any other terms (including, without limitation, terms of employment) to which you may be subject; (c) your entering into discussions with LCP, and your furnishing information/content (if applicable), shall not infringe the intellectual property rights of any third party; and (d) that the Licence granted by LCP is the sole benefit and/or advantage that may arise to you under these Terms and Conditions and any other benefits and/or advantages (which by operation of law or otherwise) may arise are hereby disclaimed and waived.</p>
                    <div v-if="showButtons" class="button marginLeft" @click="agree">Accept</div>
                    <div v-if="showButtons" class="button marginLeft" @click="disagree">Disagree</div>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'termsBase',

    data () {
        return {
            enactUrl: `${process.env.VUE_APP_BASE_URL || 'http://localhost:8080'}`,
        };
    },
    props: {
        showButtons: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        agreed () {
            return this.$store.state.user.terms.agreed;
        },
    },
    created () {
        this.$axios.post(`${process.env.VUE_APP_STATIC_API}/staticApi/frontendLog/logFrontendEvent`, {
            frontendEvent: 'Accessed Terms and Conditions',
        });
    },
    methods: {
        agree () {
            this.$store.dispatch('user/agreeTerms').then((response) => {
                this.$router.push('/dashboard');
            });
        },
        disagree () {
            this.$store.dispatch('user/disagreeTerms');
        },
    },
};
</script>

<style lang="scss" scoped>
.termsWrapper {
    .terms {
        margin-top: 10px;
        position: fixed;
        z-index: 1;
        padding-right:10px;
    }
    .termsHolder {
        height: 60vh;
        overflow-y: scroll;
        margin-bottom: 20px;
        padding: 20px;
        line-height: 30px;
    }
    h1, p, h2 {
        margin-bottom: 10px;
    }
    a {
        color: $BRIGHT;
        font-weight: 600;
    }
    h1 {
        color: $primary;
        font-size: 20px;
    }
    h2 {
        color: $primary;
    }
    .highlight {
        color: $primary;
    }
}
.marginLeft {
    margin-left: 5px;
}
</style>
