<template>
<absoluteModal showDarkBackground class="settingsWrapper"  @close="onClickOutside">
    <div @click="goToNotificationPage" class="icon icofont-external" v-tooltip="`Notifications`" />
    <moduleLiveTable hideCard
        class="table"
        :dataAndConfig="emptyDataConfig"
        :showHeader="false"
        :canClose="false"
    />
    <div class="footer button" @click="goToNotificationPage">Go to Notification Manager</div>
</absoluteModal>
</template>

<script>

import moduleLiveTable from '@/components/modules/moduleLiveTable';
import absoluteModal from '@/components/layout/absoluteModal';

export default {
    data () {
        return {
            emptyDataConfig: {
                data: this.$staticStore.state.data.liveTableOptions.notifications,
                info: {
                    id: this.$staticStore.state.data.liveTableOptions.notifications.moduleType,
                },
            },
        };
    },
    components: {
        moduleLiveTable,
        absoluteModal,
    },
    methods: {
        goToNotificationPage () {
            this.$store.commit('toggleNotifications');
            this.$router.push('/notifications');
        },
        onClickOutside (event, el) {
            this.$store.commit('toggleNotificationsOff');
        },
    },
    beforeDestroy () {
        this.$store.dispatch('liveTable/markNotificationsSeen');
    },
};
</script>

<style lang="scss" scoped>
.settingsWrapper {
    height: 70vh;

    right: 120px;
    top: 50px;
    position: absolute;
    min-width: 350px;

    .table {
        top: -10px;
        height: calc(100% - 20px);
    }

    .icon {
        top: 7px;
        line-height: 20px;
        position: absolute;
        cursor: pointer;
        color: $primary;
        z-index: 100;
    }

    .footer {
        bottom: 30px;
        left: 20px;
        z-index: 100;
        position: absolute;
    }

    .icofont-external {
        right: 48px;
        font-size: 20px;
    }

    h1 {
        font-size: 30px;
        height: 20px;
        margin-bottom: 30px;
    }
    .button {
        background: $primary;
        color: white;
        cursor: pointer;
        display: inline-block;
        height: 40px;
        line-height: 30px;
        margin-top: 20px;
        padding: 5px 10px 5px 10px;
        text-align: center;
        text-decoration: none;
    }
}
</style>
