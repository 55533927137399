var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "countdown" },
    [
      _c(
        "countdown",
        {
          key: _vm.componentKey,
          ref: "countdown",
          attrs: { endTime: _vm.endTime },
          on: { finish: _vm.moveToNextTradingWindow },
          scopedSlots: _vm._u([
            {
              key: "process",
              fn: function (ref) {
                var timeObj = ref.timeObj
                return _c("span", {}, [
                  _vm._v(_vm._s(timeObj.m + ":" + timeObj.s)),
                ])
              },
            },
          ]),
        },
        [
          _c("span", { attrs: { slot: "finish" }, slot: "finish" }, [
            _vm._v("closed"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }