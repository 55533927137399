var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "absoluteModal",
    {
      staticClass: "feeback-holder",
      class: { bottom: _vm.plantFeedback },
      attrs: { showDarkBackground: "", title: "Feedback" },
      on: { close: _vm.onClickOutside },
    },
    [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c("h2", [_vm._v("Type")]),
          _c("multiselect", {
            staticClass: "type",
            attrs: {
              options: _vm.type,
              searchable: true,
              "close-on-select": true,
              "show-labels": false,
              placeholder: "Search for a type",
            },
            model: {
              value: _vm.selectType,
              callback: function ($$v) {
                _vm.selectType = $$v
              },
              expression: "selectType",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "note-holder" },
        [
          _c("h2", [_vm._v("Notes")]),
          _c("enactInput", {
            staticStyle: { "margin-top": "10px" },
            attrs: {
              minHeight: _vm.plantFeedback ? 100 : 150,
              placeholder: _vm.plantFeedback
                ? "Example: Battery XYZ has a capacity of 20MW, and duration of 1.2 hours. Source: Work at ABC."
                : "",
            },
            on: { textChange: _vm.textChange },
          }),
          !_vm.loggedIn
            ? [
                _c("h2", [_vm._v("Email & Name")]),
                !_vm.loggedIn
                  ? _c("enactInput", {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { useInputInsteadOfTextArea: "" },
                      on: { textChange: _vm.emailChange },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "button",
          class: { disabled: _vm.disableSubmit },
          on: { click: _vm.sendFeedback },
        },
        [_vm._v("Send Feedback")]
      ),
      _vm.showModal
        ? _c("div", { staticClass: "modal" }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center", "margin-top": "20px" } },
              [
                _vm._v(
                  "Thanks for the feedback. The Enact team will be in touch!"
                ),
              ]
            ),
            _c("span", {
              staticClass: "icofont-close",
              on: { click: _vm.closeFeedbackSentMessage },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }