<template>
    <absoluteModal showDarkBackground class="settingsWrapper" @close="onClickOutside">
        <div class="button" @click="$emit('share')">
            Share dashboard with users
            <i class="infoIcon icofont-info" v-tooltip="'This will share an existing dashboard into your coworkers list of dashboards.'" />
        </div>
        <div class="button" @click="$emit('save')">
            Save dashboard to company repository
            <i class="infoIcon icofont-info" v-tooltip="'This will save an existing dashboard into your company\'s starting pre-made dashboards.<br/>So existing users can create a dashboard from this as a base.'" />
        </div>
    </absoluteModal>
</template>

<script>
import absoluteModal from '@/components/layout/absoluteModal';

export default {
    data () {
        return {

        };
    },
    components: {
        absoluteModal,
    },
    methods: {
        onClickOutside (event, el) {
            this.$emit('close');
        },
    },
    beforeDestroy () {

    },
};
</script>

<style lang="scss" scoped>
.settingsWrapper {
    display: inline-block;

    right: 240px;
    top: 50px;
    position: absolute;

    .icon {
        top: 7px;
        line-height: 20px;
        position: absolute;
        cursor: pointer;
        color: $primary;
        z-index: 100;
    }

    .button {
        margin-top: 20px;
        display: block;
        .infoIcon {
            margin-left: 5px;
            padding: 2px;
            border-radius: 10px;
            background-color: var(--light);
            color: $primary;
        }
    }
}
</style>
