var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal",
      style:
        "top: " +
        (_vm.$staticStore.state.data.headerHeightPlusMargin - 10) +
        "px",
    },
    [
      _c("i", {
        staticClass: "icofont-close closeModal",
        on: { click: _vm.closeModal },
      }),
      _c(
        "div",
        { staticClass: "niceScroll scrollWrapper" },
        _vm._l(_vm.textToShow, function (value, key) {
          return _c("div", { key: key, staticClass: "textToShow" }, [
            _c("span", { staticClass: "date" }, [
              _vm._v(_vm._s(value.date.format("DD/MM/YYYY h:mm a")) + ": "),
            ]),
            _c("span", {
              staticClass: "text",
              domProps: { innerHTML: _vm._s(value.text) },
            }),
          ])
        }),
        0
      ),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }