<template>
	<div :class="{ 'card': !hideCard }" >
        <moduleHeader
            :title="hideTitle ? '' : moduleName"
            :accentColour="moduleInfo.moduleColour"
            :canClose="canClose"
            @close="$emit('close')"
            @edit="$refs.table.setTableFilter()"
            :showHeaderIcons="showHeader"
            :canEdit="true"
            :showNoData="false"
            :showChartIcons="false"
            v-if="!showTableOnly"
        >
            <template v-if="addSpacing">
                <div class="filterWrapper">
                    <span class="filters" v-for="(item, key) in selectedFilters" :key="key">
                        {{item}}
                    </span>
                </div>
                <div v-if="moduleInfo.showRefreshRealtime">
                    <span style="font-weight:700">Table Updated:</span>
                    {{tablePulledDate}}
                    <i @click="getInitialData" class="icofont-refresh refresh"></i>
                </div>
            </template>
        </moduleHeader>

		<div v-if="loading" class="loaderSvg">
			<img v-bind:src="moduleChartLoader" />
			<div class="loaderText">Loading...</div>
		</div>
		<div class="tableWrap" :style="{height: heightString}" v-else>
			<genericTable
                v-if="tableData!==null"
                ref="table"
                @filterChange="filtersChanged"
				:excelFilename="moduleName"
				:allowExcel="allowExcel"
                :uniqueNameId="`liveTable_${config.id}`"
				:showHover="moduleInfo.moduleType !== 'SOSOTradesTable' && moduleInfo.moduleType!== 'EpexMessages'"
				:tableData="tableData"
				@plantSelect="plantSelect"
                @rowSelect="rowSelect"
				noDataMessage="No Data"
                :backendPredefinedFilters="dataAndConfig.presetFilters"
                :frontendPredefinedFilters="dataAndConfig.frontendPredefinedFilters"
                minHeightIs100
                sitsOnCard
                :showFooter="allowFooter"
                :lightHover="lightHover"
			/>
		</div>
	</div>
</template>

<script>

import { LiveTableHandler } from '@/components/editor/liveTableOptions';
import moduleChartLoader from '@/components/modules/components/loader.svg';
import moduleHeader from '@/components/modules/components/moduleHeader';
import genericTable from '@/components/table/genericCSharpTable';

// Need to add for no data available
export default {
    name: 'moduleLiveTable',
    props: {
        dataAndConfig: {
            type: Object,
            required: true,
        },
        showHeader: {
            type: Boolean,
            required: false,
            default: true,
        },
        hideTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideCard: {
            type: Boolean,
            required: false,
            default: false,
        },
        dateOverride: {
            type: Object,
            required: false,
            default: null,
        },
        postObject: {
            type: Object,
            required: false,
            default: null,
        },
        hideTopFilters: {
            type: Boolean,
            required: false,
            default: false,
        },
        extraPullInfo: {
            type: String,
            required: false,
            default: null,
        },
        showTableOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        allowFooter: {
            type: Boolean,
            required: false,
            default: false,
        },
        allowExcel: {
            type: Boolean,
            required: false,
            default: false,
        },
        plantDetailsToShowInitial: {
            type: String,
            required: false,
            default: null,
        },
        canClose: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    components: {
        genericTable,
        moduleHeader,
    },
    data () {
        return {
            loading: true,
            moduleChartLoader,
            tableDataLocal: {},
            selectedFilters: [],
            preDefinedFilters: {},

            moduleInfo: [],
            countryName: '',
            moduleName: '',
            tablePulledDate: '',
        };
    },
    created () {
        this.runInitial();
    },
    computed: {
        addSpacing () {
            if (this.hideTopFilters) return false;
            return this.selectedFilters.length > 0 || this.moduleInfo.showRefreshRealtime;
        },
        heightString () {
            if (this.showTableOnly) return 'calc(100% - 10px)';
            return `calc(100% - 25px)`;
        },
        moduleType () {
            return this.data.moduleType;
        },
        country () {
            return this.data.country;
        },
        data () {
            return this.dataAndConfig.data;
        },
        config () {
            return this.dataAndConfig.info;
        },
        bingoOverrides () {
            return this.$store.state.user.bingoColourOverrides;
        },
        bidColour () {
            return this.$store.state.user.bidColour;
        },
        offerColour () {
            return this.$store.state.user.offerColour;
        },
        canActionWatchOnBothDayAndPeriod () {
            return this.$store.state.data.analysisDate.canActionWatchOnBothDayAndPeriod;
        },
        componentTablePushes () {
            return this.$store.state.liveTable.componentTables.hasOwnProperty(this.config.id)
                ? this.$store.state.liveTable.componentTables[this.config.id]
                : null;
        },
        componentTablePushesCounter () {
            return this.componentTablePushes
                ? this.componentTablePushes.counter
                : 0;
        },
        useStore () {
            return this.moduleInfo.useStore;
        },
        tableDataStore () {
            return this.$store.state.liveTable.storeTables.hasOwnProperty(this.moduleType)
                ? this.$store.state.liveTable.storeTables[this.moduleType]
                : null;
        },
        reRequestFromStore () {
            return this.tableDataStore
                ? this.tableDataStore.reRequestCounter
                : 0;
        },
        tableData () {
            return this.useStore ? this.tableDataStore : this.tableDataLocal;
        },
        tableBeingDeleted () {
            return this.$store.state.liveTable.tablesBeingDeleted;
        },
        currentDate () {
            return this.dateOverride && this.dateOverride.from ? this.dateOverride.from : this.$store.state.data.analysisDate.date;
        },
        currentPeriod () {
            return this.$store.state.data.analysisDate.period;
        },
        fromToDate () {
            return this.$store.getters.fromToHeaderDate;
        },
        lightHover () {
            return this.moduleName === 'BM Feed';
        },
    },
    methods: {
        runInitial () {
            this.moduleInfo = Object.values(this.$staticStore.state.data.liveTableOptions).find((liveTable) => liveTable.moduleType === this.data.moduleType);
            this.countryName = this.getCountryName();

            this.moduleName = this.moduleInfo.moduleName;

            if (!this.useStore || this.tableDataStore === null) {
                this.getInitialData();
            } else {
                this.loading = false;
            }
        },
        getInitialData () {
            LiveTableHandler.handlePull(this.moduleType, this.config.id, this.country, this.$moment(this.currentDate), this.currentPeriod, this.postObject, this.fromToDate, this.extraPullInfo).then((data) => {
                if (data !== null) this.tableDataLocal = data;
                this.tablePulledDate = this.$moment().format('Do MMMM YYYY, h:mm a');
                this.loading = false;
            });
        },
        unsubscribeFromTable (date, period) {
            LiveTableHandler.unsubscribe(this.moduleType, this.country, date, period, this.extraPullInfo);
        },
        filtersChanged (filters) {
            this.selectedFilters = [];
            Object.values(filters).forEach((heading) => {
                switch (heading.type) {
                case 'text':
                    heading.options.values.forEach((value) => {
                        this.selectedFilters.push(value);
                    });
                    break;
                default:
                    break;
                }
            });
        },
        plantSelect (plantId) {
            if (plantId.includes('BSAD')) plantId = plantId.split(' [BSAD]')[0];

            this.$store.dispatch('initiateSlideOutPlant', {
                plantId,
                fromDate: this.moduleInfo.getOnDayChange ? new Date(this.currentDate) : new Date(),
                toDate: this.moduleInfo.getOnDayChange ? new Date(this.currentDate) : new Date(),
                plantDetailsToShowInitial: this.plantDetailsToShowInitial,
            });
        },
        rowSelect (rowId) {
            if (this.moduleInfo.slideOutOverride) {
                this.$store.commit('initiateSlideOutContent', {
                    data: { title: 'Contract Information', width: 50 },
                    component: `components/slideOut/${this.moduleInfo.slideOutOverride}.vue`,
                    props: {
                        id: rowId.uniqueId,
                    },
                });
            }
        },
        updateTableData () {
            const liveTable = this.componentTablePushes;
            if (liveTable.reRequest) {
                this.getInitialData();
                return;
            }
            while (liveTable.pushes.length > 0) {
                const newChange = liveTable.pushes.pop();
                // We know our extra-info here this.extraPullInfo
                const success = LiveTableHandler.handlePush(this.tableData, newChange);
                if (!success) {
                    this.getInitialData();
                    while (liveTable.pushes.length > 0) {
                        liveTable.pushes.pop();
                    }
                    return;
                }
            }
        },
        getCountryName () {
            let countryName;

            if (this.country == null) {
                return 'GB';
            } else {
                this.$staticStore.state.data.countries.some(country => {
                    if (country.subCountries.length === 0) {
                        if (country.id === this.country) {
                            countryName = country.name;
                            return true;
                        }
                    } else {
                        for (const subCountryIndex in country.subCountries) {
                            if (country.subCountries[subCountryIndex].id === this.country) {
                                countryName = country.subCountries[subCountryIndex].name;
                                return true;
                            }
                        }
                        return false;
                    }
                    return false;
                });
            }
            return countryName;
        },
    },
    watch: {
        extraPullInfo () {
            this.getInitialData();
        },
        componentTablePushesCounter: {
            handler (newVal, oldVal) {
                // Update chart series from store
                if (newVal > oldVal) this.updateTableData();
            },
        },
        reRequestFromStore: {
            handler (newVal, oldVal) {
                // Push from back-end told store to re-get data
                if (newVal > oldVal) this.getInitialData();
            },
        },
        bingoOverrides: {
            handler (newVal, oldVal) {
                LiveTableHandler.changeTableColours(this.tableData, this.moduleType);
            },
            deep: true,
        },
        bidColour: {
            handler (newVal, oldVal) {
                LiveTableHandler.changeTableColours(this.tableData, this.moduleType);
            },
        },
        offerColour: {
            handler (newVal, oldVal) {
                LiveTableHandler.changeTableColours(this.tableData, this.moduleType);
            },
        },
        currentDate: {
            handler (newVal, oldVal) {
                if (!newVal.isSame(oldVal) && this.moduleInfo.getOnDayChange && this.canActionWatchOnBothDayAndPeriod) {
                    console.log(newVal, oldVal);
                    this.loading = true;
                    this.unsubscribeFromTable(this.$moment(oldVal), this.currentPeriod);
                    this.getInitialData();
                }
            },
            deep: true,
        },
        fromToDate: {
            handler(newVal, oldVal){
                const fromDateChanged = newVal.from !== oldVal.from;
                const toDateChanged = newVal.to !== oldVal.to;

                if ((fromDateChanged || toDateChanged) && this.moduleInfo.getOnDayRangeChange) {
                    this.loading = true;
                    this.getInitialData();
                }
            }
        },
        currentPeriod: {
            handler (newVal, oldVal) {
                if (newVal !== oldVal && this.moduleInfo.getOnPeriodChange && this.canActionWatchOnBothDayAndPeriod) {
                    this.loading = true;
                    this.unsubscribeFromTable(this.$moment(this.currentDate), oldVal);
                    this.getInitialData();
                }
            },
            deep: true,
        },
        dataAndConfig: {
            handler (newVal, oldVal) {
                this.runInitial();
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
	// padding: 5px 10px 10px 10px !important;
    // min-height: 252px;
    height: 100%;
    // max-height: 452px;
    position: relative;
    &:hover {
        outline: rgb(170, 170, 170) 1px solid;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5),
            0 2px 4px rgba(0, 0, 0, 0.5);
        .header {
            ::v-deep .icon-wrapper {
                display: flex !important;
            }
        }
    }
	.tableWrap {
        width: 100%;
        margin-top: 8px;
	}
}
.filterWrapper {
        margin-left: 10px;
        overflow: hidden;
        white-space: nowrap;
        border-radius: $border-radius 0 0 0;
        display: flex;
        justify-content: space-between;
        .filters {
            color: white;
            font-size: 14px;
            padding: 1px 3px 1px 3px;
            margin-right: 6px;
            text-align: center;
            border-radius: 6px;
            background-color: $ALTERNATIVE1;
        }
        .refresh {
            font-weight: 500;
            cursor: pointer;
            color: $primary;
        }
    }
.loaderSvg {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 28px;
	.loaderText {
		transform: translate(5%, 0%);
	}
}
</style>
