<template>
    <absoluteModal showDarkBackground title="Feedback" class="feeback-holder" :class="{bottom: plantFeedback}" @close="onClickOutside">
        <div class="left">
            <h2>Type</h2>
            <multiselect
                class="type"
                v-model="selectType"
                :options="type"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="Search for a type"/>
        </div>
        <div class="note-holder">
            <h2>Notes</h2>
            <enactInput style="margin-top: 10px" @textChange="textChange" :minHeight="plantFeedback ? 100 : 150"
                :placeholder="plantFeedback ? 'Example: Battery XYZ has a capacity of 20MW, and duration of 1.2 hours. Source: Work at ABC.' : ''" />
            <template v-if="!loggedIn">
                <h2>Email & Name</h2>
                <enactInput v-if="!loggedIn" style="margin-top: 10px" useInputInsteadOfTextArea @textChange="emailChange" />
            </template>
        </div>
        <div class="button" :class="{'disabled': disableSubmit}" @click="sendFeedback">Send Feedback</div>
        <div class="modal" v-if="showModal">
            <div style="text-align: center; margin-top: 20px">Thanks for the feedback. The Enact team will be in touch!</div>
            <span class="icofont-close" @click="closeFeedbackSentMessage"></span>
        </div>
    </absoluteModal>
</template>

<script>
import enactInput from '@/components/editor/enactInput';
import absoluteModal from '@/components/layout/absoluteModal';

export default {
    components: {
        enactInput,
        absoluteModal,
    },
    data () {
        return {
            check: false,
            notes: '',
            disableSubmit: true,
            selectType: '',
            type: ['Issue', 'Feature Request', 'Plant Change', 'Other'],
            showModal: false,
            email: '',
        };
    },
    created () {
        this.selectType = this.plantFeedback ? 'Plant Change' : '';
    },
    computed: {
        plantFeedback () {
            return this.$store.state.ui.feedbackPlantChange;
        },
        loggedIn () {
            return !this.$store.state.ui.isFreeUser;
        },
    },
    methods: {
        onClickOutside () {
            this.$emit('close');
        },
        textChange (text) {
            this.disableSubmit = text.broken;
            this.notes = text.content;
        },
        emailChange (email) {
            this.disableSubmit = email.broken;
            this.email = email.content;
        },
        sendFeedback () {
            if (this.disableSubmit) return;

            const sendDetails = {
                notes: this.notes,
                type: this.selectType,
                email: this.loggedIn ? '' : this.email,
                userId: '',
                page: this.$store.state.ui.pageName,
            };
            this.$axios.post(`${process.env.VUE_APP_STATIC_API}/staticApi/feedback/`, sendDetails).then(response => {
                this.selectType = '';
                this.notes = '';
            });
            this.showModal = true;
            this.selectType = '';
        },
        closeFeedbackSentMessage () {
            this.showModal = false;
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.feeback-holder {
    position: fixed;
    right: 20px;
    top: 50px;
    width: 500px;
    color: var(--primaryFont);
    &.bottom {
        bottom: 60px;
        top: unset;
    }
    h2 {
        font-size: 16px;
    }
    .left {
        height: 80px;
    }
    .type {
        width: 250px;
    }
    .button {
        margin-top: 5px;
    }
    .multiselect {
        margin-top: 10px;
    }
    .modal {
        position: absolute;
        top: 50%;
        background: var(--mid);
        height: 80px;
        width: 200px;
        border-radius: 7px;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 100;
        .modalTitle {
            color: black;
            text-align: center;
            font-weight: 500;
            margin-bottom: -5px;
            margin-top: 10px;
        }
        .icofont-close {
            color: $primary;
            top: 5px;
            right: 5px;
            cursor: pointer;
            position: absolute;
        }
    }
}
</style>
