<template>
    <div class="logoHolder">
        <a :href="loggedIn ? 'https://enact.lcp.energy' : 'https://lcpenact.com'" target="_blank">
            <img
                class="logo"
                :style="{ height: `${fontSize}px` }"
                :src="require(`@/assets/imgs/Logos/${userSettings.colour === 'Dark' ? 'Dark' : 'Dark'}/${selectedEnact === 'Enact' ? 'enact.svg' : 'envision.svg'}`)"
            />
        </a>
        <!-- <span class="energyPortal" :style="{ fontSize: `${fontSize * 0.5}px` }">
            <font-awesome-icon :icon="['fad', 'globe']" /> Energy Portal
        </span> -->
	</div>
</template>

<script>

export default {
    props: {
        fontSize: {
            type: Number,
            required: true,
        },
    },
    computed: {
        selectedEnact () {
            return this.$store.state.ui.selectedEnactView;
        },
        userSettings () {
            return this.$store.state.user;
        },
        loggedIn () {
            return !this.$store.state.ui.isFreeUser;
        },
    },
};
</script>

<style lang="scss" scoped>
.logoHolder {
    display: flex;
    .logo {
        // height: 100%;
        vertical-align: middle;
    }
    .energyPortal {
        color: $BRIGHT;
        font-weight: 600;
        padding-left: 10px;
        padding-top: 12px;
    }
}
</style>
