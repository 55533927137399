<template>
<transition name="fade" mode="out-in">
<div class="header" :class="{ isDashboard: isDashboard }">
    <div class="icon-wrapper hidden" v-if="showChartIcons || canEdit || canClose" :class="{ top: !isSmallWidth, left: isSmallWidth }">
        <div class="chart-icons" v-show="showChartIcons">
            <span
                v-if="showHelpIcon" 
                class="icon-controls icofont-question"
                @click="$emit('openChartHelpModal')"
                v-tooltip="getLeftTooltip(`What do the series on this chart mean?`)"
            />
        </div>
        <div class="chart-icons" v-show="showChartIcons">
            <span
                v-if="!showNoData && !showChart"
                class="icon-controls icofont-chart-line"
                v-tooltip="getLeftTooltip(`Show as a graph`)"
                @click="toggleChart"
            />
            <span
                v-if="!showNoData && !showTableInsteadOfChart && canTable"
                class="icon-controls"
                :class="tableClass"
                v-tooltip="getLeftTooltip(this.chartTableDisabled ? `Please use Historical Analysis for large data exports` : `Show as a table`)"
                @click="toggleTable"
            />
            <span
                v-if="!showNoData && !showMetricsInsteadOfChart && canMetric"
                class="icon-controls icofont-list"
                :class="calculatingMetrics ? 'disabled' : ''"
                v-tooltip="getLeftTooltip(this.showMetricsInsteadOfChart ? `Hide metrics` : `Show metrics`)"
                @click="toggleMetrics"
            />
        </div>
        <div class="chart-icons" v-show="showChartIcons">
            <span
                v-if="allowFullscreen && !showNoData && showChart"
                class="icon-controls icofont-resize hidden-xs"
                v-tooltip="getLeftTooltip(`Show as full screen`)"
                @click="showFullscreen"
            />
            <span
                v-if="!showNoData && !showMetricsInsteadOfChart && showChart && canChangeYAxisLimits"
                class="icon-controls icofont-chart-arrows-axis"
                v-tooltip="getLeftTooltip(`Edit axis options`)"
                @click="toggleYAxisLimits"
            />
            <span
                v-if="!showNoData && showChart && canDownloadImage"
                class="icon-controls icofont-image hidden-xs"
                v-tooltip="getLeftTooltip(`Download & print image`)"
                @click="openImageModal"
                id="exportChart"
            />
            <span class="icon-controls icofont-external-link"
                v-if="showPopoutIcon"
                @click="$emit('openChartPopout')"
                v-tooltip="getLeftTooltip('Expand chart')"
            />
        </div>
        <div class="data">
            <span
                v-if="canEdit"
                class="icofont-ui-edit icon-controls hidden-xs clickableIcon"
                :class="{ disabled: lockedAccount }"
                v-tooltip="getLeftTooltip(lockedAccount ? 'Edit module locked in User Settings' : 'Edit this module')"
                @click.stop="editChart"
            />
            <span
                v-if="canClose"
                class="icofont-trash icon-controls clickableIcon"
                :class="{ disabled: lockedAccount }"
                v-tooltip="getLeftTooltip(lockedAccount ? 'Remove module locked in User Settings' : 'Remove this module')"
                @click="closeChart"
            />
        </div>
    </div>
    <div class="visibleHeader" style="marginLeft: '10px'">
        <div class="title" :class="{ 'small': titleSmall, 'ellipsis': showDate }" :style="{ fontWeight: isDashboard ? null : '500' }" v-if="title">
            {{title}}
        </div>
        <slot />
        <div class="rightHolder" v-if="showDate || showBoostMessage || showHelpIcon">
            <datePickerHolder v-if="showDate" class="dateHolder"
                :fromDate="fromDate"
                :toDate="toDate"
                :dateInfo="dateInfo"
                updateTextOverride="Update Chart"
                :dateDisabled="dateDisabled"
                :dateChanging="dateChanging"
                :hideDateRangePicker="hideDateRangePicker"
                :onlyAllowSingleDay="onlyAllowSingleDay"
                :fromHours="fromHours"
                :toHours="toHours"
                :smallDate="smallDate"
                :aggregateOptionsInitial="aggregateOptionsInitial"
                @dateChange="dateChange"
                :canAggregate="canAggregate"
                allowOverrideAll
            />
            <div class="info">
                <span class="icon icofont-exclamation-circle helpText" v-if="showBoostMessage" v-tooltip="{content: boostHelpText}"></span>
            </div>
        </div>
    </div>
</div>
</transition>
</template>

<script>

import moduleChartLoader from '@/components/modules/components/loader.svg';
import datePickerHolder from '@/components/inputs/datePicker/datePickerHolder';

export default {
    name: 'moduleHeader',
    props: {
        title: {
            type: String,
            required: true,
        },
        titleSmall: {
            type: Boolean,
            required: false,
            default: false,
        },
        accentColour: {
            type: String,
            required: false,
            default: '',
        },
        canClose: {
            type: Boolean,
            default: false,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
        showNoData: {
            type: Boolean,
        },

        showChartIcons: {
            type: Boolean,
            default: false,
            required: true,
        },
        allowFullscreen: {
            type: Boolean,
            default: false,
        },
        canAggregate: {
            type: Boolean,
            default: true,
        },
        canMetric: {
            type: Boolean,
            default: true,
        },
        canTable: {
            type: Boolean,
            default: true,
        },
        canDownloadImage: {
            type: Boolean,
            default: true,
        },
        showTableInsteadOfChart: {
            type: Boolean,
        },
        showMetricsInsteadOfChart: {
            type: Boolean,
        },
        calculatingMetrics: {
            type: Boolean,
        },
        showChart: {
            type: Boolean,
        },
        fromDate: {
            type: String,
        },
        toDate: {
            type: String,
        },
        showHeaderIcons: {
            type: Boolean,
            required: false,
            default: true,
        },
        canChangeYAxisLimits: {
            type: Boolean,
            default: true,
        },

        showDate: {
            type: Boolean,
            default: false,
        },
        smallDate: {
            type: Boolean,
            default: false,
        },
        dateDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        dateChanging: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideDateRangePicker: {
            type: Boolean,
            default: false,
        },
        onlyAllowSingleDay: {
            type: Boolean,
            default: false,
        },
        fromHours: {
            type: Number,
            required: false,
        },
        toHours: {
            type: Number,
            required: false,
        },
        dateInfo: {
            type: Object,
            required: false,
        },

        showHelpIcon: {
            type: Boolean,
            default: false,
        },
        showPopoutIcon: {
            type: Boolean,
            default: false,
        },
        showBoostMessage: {
            type: Boolean,
            default: false,
        },
        aggregateOptionsInitial: {
            type: Object,
            required: false,
            default: null,
        },
        isSmallWidth: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        datePickerHolder,
    },
    data () {
        return {
            moduleChartLoader,
            boostHelpText: 'This chart has reduced functionality (including no live updates) due to the number of points.<br />You can override this in your User Settings.',
        };
    },
    computed: {
        isDashboard () {
            return this.$route.name === 'dashboard';
        },
        chartTableDisabled () {
            return false;// this.daysOnChart > 20;
        },
        daysOnChart () {
            return this.$moment(this.toDate, 'DDMMYYYY').diff(this.$moment(this.fromDate, 'DDMMYYYY'), 'days') + 1;
        },
        tableClass () {
            const icon = this.showTable ? 'icofont-chart-line' : 'icofont-table';
            const disabled = this.chartTableDisabled ? 'disabled' : '';

            return `${icon} ${disabled}`;
        },
        userSettings () {
            return this.$store.state.user;
        },
        lockedAccount () {
            return this.userSettings.lockedAccount === 'editLocked';
        },
    },
    methods: {
        getLeftTooltip (text) {
            return {
                content: text,
                placement: 'bottom',
            };
        },
        dateChange (dateFormat, aggregateOptions) {
            this.$emit('dateChange', dateFormat, aggregateOptions);
        },
        openImageModal (event) {
            this.$emit('image', event);
        },
        showFullscreen () {
            this.$emit('fullscreen');
        },
        closeChart () {
            if (this.lockedAccount) return;
            this.$emit('close');
        },
        editChart () {
            if (this.lockedAccount) return;
            this.$emit('edit');
        },
        toggleTable () {
            this.$emit('table');
        },
        toggleChart () {
            this.$emit('chart');
        },
        toggleMetrics () {
            this.$emit('metrics');
        },
        toggleYAxisLimits () {
            this.$emit('yAxisLimits');
        },
    },
};
</script>

<style lang="scss" scoped>
$dot-size: 4px;
$dot-border-radius: calc($dot-size / 2);

.header {
    margin: -15px -5px 0 -5px;
    padding-top: 10px;
    &.isDashboard {
        // background: var(--light);
        // position: sticky;
        // top: 0;
        z-index: 3;
    }

    .visibleHeader {
        display: flex;
        justify-content: space-between;
        height: 25px;
        .title {
            font-size: 18px;
            margin: auto 0 auto 5px;
            white-space: nowrap;
            color: var(--primaryFont);
            @include responsive("sm") {
                font-size: 15px;
                height: 16px;
            }
            &.ellipsis {
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .small {
                font-size: 15px;
                height: 16px;
            }
        }
        .rightHolder {
            display: flex;
            .info {
                color: $primary;
                cursor: pointer;
                font-weight: 600;
                @include responsive("sm") {
                    display: none;
                }
                .icon {
                    padding-left: 10px;
                }
                .helpText {
                    color: $ALTERNATIVE1;
                    cursor: disable;
                }
            }
        }
    }
}
.icon-wrapper {
    color: $primary;
    font-size: 16px;
    outline: rgb(170, 170, 170) 1px solid;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5),
        0 2px 4px rgba(0, 0, 0, 0.5);
    position: absolute;
    border-radius: $border-radius;
    padding: 5px;
    background: var(--mid);
    z-index: 3;
    display: flex;
    justify-content: space-between;
    &.left {
        top: 30px;
        left: 5px;
        flex-direction: column;
        .data, .chart-icons {
            flex-direction: column;
        }
        .chart-icons {
            margin-bottom: 20px;
        }
        .icon-controls {
            margin: 4px 0;
        }
    }
    &.top {
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        .chart-icons {
            margin-right: 20px;
        }
        .icon-controls {
            margin: 0 4px;
        }
    }
    .close-wrapper{
        justify-self: flex-start;
    }
    .data, .chart-icons {
        display: flex;
        align-items: center;
    }
    .close-wrapper{
        display: flex;
        align-items: center;
    }
    .icofont-close{
        font-size: 20px;
    }
    .disabled {
        color: $dmDark;
        cursor: not-allowed;
    }
    .icon-controls {
        cursor: pointer;
    }
}
</style>
