<template>
	<div class="headWrap" :style="{ height: `${$staticStore.state.data.headerHeightPlusMargin - 10}px` }">
		<div class="left box">
			<div class="sideNavOptions" :class="{ hiddenOnDesktop: hideSidenav }">
                <div class="toggle" @click="toggleMenu" @keyup.enter="toggleMenu">
					<font-awesome-icon
                        class="icon"
                        :icon="['fas', $store.state.ui.menuOpen ? 'chevron-left' : 'chevron-right']"
                        title="Menu"
                        aria-label="Menu"
                    />
				</div>
                <!-- <div class="pin">Pinned</div> -->
            </div>
			<div class="image">
				<logo class="lcpLogo" :fontSize="28" alt="LCP Enact Logo" />
			</div>
			<portal-target name="headerLeft"></portal-target>
		</div>
		<div class="middle box">
			<portal-target name="headerMiddle"></portal-target>

			<periodPicker
				v-if="showPeriodPicker"
				@currentDate="setDate"
				:initialDate="pageName === 'forecastHistory' ? historicForecastDate :
					$moment(this.$store.state.data.analysisDate.date).toDate()"
				:enabled="dataLoaded"
				:showPeriod="$store.getters.hasBoaNotLite || (pageName === 'dashboard' && $store.state.ui.dashboardHasPeriodPicker) ||
						pageName === 'mapPage' || pageName === 'europeMapPage' ||
						pageName === 'boaComparer' ||
						(pageName === 'terreDashboard' && !terreDashboardDailyMode)"
                :disabledDateOverride="pageName === 'forecastHistory' ? historEnabled : null"
			/>
			<div v-if="showHeaderDateRange">
				<datePickerHolder
					:fromDate="fromDateOverride.format('DDMMYYYY')"
					:toDate="toDateOverride.format('DDMMYYYY')"
					:dateInfo="dateInfo"
					:minimumView="minimumView"
					:disabledDate="disabledDate"
					@dateChange="updateDate"
					hideTimes
					isHeader
                    :onlyAllowSingleDay="onlyAllowSingleDay"
				/>
			</div>
		</div>
		<div class="right box">
            <!-- mobile / ipad only -->
			<div class="datepickerMobile nonDesktop" @click="toggleMobileDatepicker" @keyup.enter="toggleMobileDatepicker" v-if="showPeriodPicker">
				<span
                    :class="showMobileDatepicker ? 'icofont-close' : 'icofont-clock-time'"
                    aria-label="Datepicker"
                    title="Datepicker"
                ></span>
			</div>
			<div class="datepickerMobileHidden nonDesktop" @click="toggleMobileDatepicker" @keyup.enter="toggleMobileDatepicker" v-else></div>
			<div class="datepickerDropdown" v-show="dataLoaded && showMobileDatepicker && showPeriodPicker">
				<periodPicker
					@currentDate="setDate"
					:initialDate="
						$moment(this.$store.state.data.analysisDate.date).toDate()
					"
					:enabled="dataLoaded"
					:showPeriod="$store.getters.hasBoaNotLite || (pageName === 'dashboard' && $store.state.ui.dashboardHasPeriodPicker) ||
							pageName === 'mapPage' || pageName === 'europeMapPage' ||
							pageName === 'boaComparer' ||
							(pageName === 'terreDashboard' && !terreDashboardDailyMode)
					"
				/>
			</div>

			<!-- end mobile / ipad only -->
			<rightHeader />
		</div>
	</div>
</template>

<script>

import Vue from 'vue';
import PortalVue from 'portal-vue';

import periodPicker from '@/components/inputs/periodPicker';
import rightHeader from '@/components/header/rightHeader';
import logo from '@/components/home/logo';
import datePickerHolder from '@/components/inputs/datePicker/datePickerHolder';
import { DatePickerMethods } from '@/components/inputs/datePicker/datePickerMethods';

Vue.use(PortalVue);

export default {
    data () {
        return {
            defaultAccess: {
                hasAccess: false,
                accessRequired: 'Enact Visualise',
            },
            period: 21,
            historEnabled: {
                from: this.$moment()
                    .add(4, 'days')
                    .toDate(),
                to: this.$moment('01-01-2019', 'DD-MM-YYYY')
                    .toDate(),
            },
            showMobileDatepicker: false,
        };
    },
    components: {
        periodPicker,
        datePickerHolder,
        rightHeader,
        logo,
    },
    created () {

    },
    methods: {
        toggleMenu () {
            if (this.$store.state.ui.dataLoaded) {
                this.$store.commit('toggleMenu');
            }
        },
        toggleMobileDatepicker () {
            this.showMobileDatepicker = !this.showMobileDatepicker;
        },
        notificationIsUnseen (notification) {
            if (notification.row.Unseen.hasOwnProperty('value')) return notification.row.Unseen.value === 'False';

            if (notification.row.Unseen.hasOwnProperty('values')) {
                return notification.row.Unseen.values[0] === 'False';
            }
            return false;
        },
        restartAnalytics () {
            this.$store.dispatch('restartAnalytics');
        },
        updateHistoricForecastDate (date) {
            this.$store.commit('updateHistoricForecastDate', date);
        },
        updateDate (date) {
            this.$store.dispatch('user/updateDate', date);
        },
        closeTradingOptions () {
            if (this.$store.state.ui.tradingOptions !== false) {
                this.$store.commit('toggleTradingOptions');
            }
        },
        setDate (newDate) {
            if (this.pageName === 'forecastHistory') this.updateHistoricForecastDate(newDate.date.toDate());
            else this.$store.dispatch('setDate', newDate);
        },
        idAndName ({ id, name }) {
            return `${id} — (${name})`;
        },
    },
    computed: {
        dateInfo () {
            let options;
            if (this.$store.getters.headerDates.dateObject) {
                options = this.$store.getters.headerDates.dateObject;
            } else {
                options = {
                    from: { type: 'date', value: this.$moment.unix(this.fromDateOverride) / 1000 },
                    to: { type: 'date', value: this.$moment.unix(this.toDateOverride) / 1000 },
                    recentHour: false,
                    fromHours: 1,
                    toHours: 1,
                };
            }
            return { date: options };
        },
        fromDateOverride () {
            return DatePickerMethods.getDatesFromHeaderConfigOrOld(this.$store.getters.headerDates, 'from');
        },
        toDateOverride () {
            return DatePickerMethods.getDatesFromHeaderConfigOrOld(this.$store.getters.headerDates, 'to');
        },
        minimumView () {
            if (this.pageName === 'monthlySummary' || this.pageName === 'monthAheadDashboard') return 'month';
            else if (this.selectedEnact === 'EnVision') {
                return this.$store.state.user.longTermSettings.granularity === 'monthly' ? 'month' : 'year';
            }
            return 'day';
        },
        dateHeaderFormat () {
            if (this.pageName === 'monthlySummary' || this.pageName === 'monthAheadDashboard') return 'MMM yyyy';
            else if (this.selectedEnact === 'EnVision') {
                return this.$store.state.user.longTermSettings.granularity === 'monthly' ? 'MMM yyyy' : 'yyyy';
            }
            return 'dd MMM yyyy';
        },
        selectedEnact () {
            return this.$store.state.ui.selectedEnactView;
        },
        terreDashboardDailyMode () {
            return this.$store.state.data.pages.terreDashboard.data.dailyMode;
        },
        activeTab () {
            return this.userSettings.pages.userDashboard.user.activeTab;
        },
        historicForecastDate () {
            return this.$store.state.data.analysisDate.historicForecast;
        },
        dashboardOverwriteEnabled () {
            return this.$store.state.data.analysisDate.dashboard.overwriteDashboardDate;
        },
        forecastHistoryEnabled () {
            return this.$store.state.ui.enableHistoricForecasts;
        },
        dataLoaded () {
            return this.$store.state.ui.dataLoaded;
        },
        userSettings () {
            return this.$store.state.user;
        },
        showTimer () {
            return this.$store.state.user.showTimer;
        },
        hideSidenav () {
            return !this.$store.state.user.sideNavVisible;
        },
        pageName () {
            return this.$store.state.ui.pageName;
        },
        showPeriodPicker () {
            return this.$store.getters.hasBoaNotLite || this.pageName === 'europeMapPage' || (this.pageName === 'dashboard' && this.$store.state.ui.dashboardHasDatePicker)
				|| (this.pageName === 'mapPage' && this.$store.state.data.pages.map.showDatePicker)
				|| this.pageName === 'boaComparer'
				|| this.pageName === 'terreDashboard' || this.pageName === 'plantActivityBoard' || this.pageName === 'zonalBuildUp'
                || (this.pageName === 'forecastHistory' && this.forecastHistoryEnabled);
        },
        showHeaderDateRange () {
            const dashboardRange = (this.pageName === 'monthlySummary' && this.dashboardOverwriteEnabled) || (this.pageName === 'dashboard' && this.$store.state.ui.dashboardHasDayRange);
            const forecastHistoryRange = this.pageName === 'forecastHistory' && !this.forecastHistoryEnabled;
            const windForecastingRange = this.pageName === 'windForecasting' && this.$store.state.data.pages.windForecasting.showDatePicker;
            const weatherForecastingRange = this.pageName === 'weatherForecasting';
            const remitRange = this.pageName === 'Remit';
            const maDashboardRange = this.pageName === 'monthAheadDashboard';
            const ancillaryRange = this.pageName === 'ancillaryContracts' && this.$store.state.data.analysisDate.ancillaryContracts.showDate;
            const enVisionRange = (this.selectedEnact === 'EnVision') && this.dashboardOverwriteEnabled;
            return dashboardRange || forecastHistoryRange || windForecastingRange || weatherForecastingRange || remitRange || ancillaryRange
                || maDashboardRange || enVisionRange;
        },
        onlyAllowSingleDay () {
            return this.pageName === 'monthAheadDashboard';
        },
        disabledDate () {
            switch (this.pageName) {
            case 'Remit':
                return {
                    from: this.$moment()
                        .add(7, 'days')
                        .toDate(),
                    to: new Date(Date.now() - 8640000),
                };
            case 'forecastHistory':
                return {
                    from: this.$moment()
                        .add(4, 'days')
                        .toDate(),
                    to: this.$moment()
                        .add(-1, 'days')
                        .toDate(),
                };
            case 'monthlySummary':
                return {
                    from: this.$moment().startOf('month')
                        .add(-1, 'months')
                        .toDate(),
                    to: null,
                };
            case 'dayAheadDashboard':
                return {
                    from: this.$moment().startOf('day').add(1, 'days').toDate(),
                    to: null,
                    disableEndOfWeek: true,
                    disableEndOfMonth: true,
                };
            case 'monthAheadDashboard':
                return {
                    from: this.$moment().startOf('month').toDate(),
                    to: null,
                    disableEndOfMonth: true,
                    disableStartOfMonth: true,
                };
            default:
                return {};
            }
        },
    },
    beforeDestroy () {
        // clearInterval(this.interval);
    },
};
</script>

<style lang="scss" scoped>
$easing: cubic-bezier(0.25, 1.7, 0.35, 0.8);
$duration: 0.6s;
::v-deep .vdp-datepicker {
	input {
		cursor: pointer;
	}
}
.box {
	// background: var(--mid);
	// box-shadow: 0 4px 12px 0px $shadow-color;
	padding: 5px;
	height: 40px;
}
.headWrap {
	background: var(--headerColour);
	box-shadow: 0 1px 1px 0px var(--shadowColour);
    color: var(--primaryFont);
	display: flex;
	justify-content: space-between;
	left: 0px;
	position: relative;
	vertical-align: middle;
	// width: calc(100% - 210px);
	// margin-left: 210px;
	.left {
		display: flex;
		@include responsive ("md") {
			flex-grow: 1;
			.flex{
				flex-grow: 1;
				justify-content: center;
			}
		}
		.flex {
			display: flex;
			align-items: center;
		}
		.image {
			height: 30px;
			margin-right: 10px;
			.lcpLogo {
				height: 30px;
			}
		}
		@media (max-width: 1536px) {
			.lcpLogo {
				height: 35px;
			}
		}
		.sideNavOptions {
			@include responsive("mdUp"){
				display: none;
			}
            &.hiddenOnDesktop {
                @include responsive("mdUp"){
                    display: unset;
                }
            }
			.toggle {
				background: var(--darkGrad1);
				cursor: pointer;
				padding: 5px 7px;
                border-radius: 10px;
                margin-right: 10px;
                height: 30px;
                width: 30px;
			}
			.icon {
				color: $primary;
				font-size: 30px;
				height: 100%;
			}
		}
	}
	.right {
        display: flex;
		.nonMobile {
			@include responsive("sm") {
				display: none;
			}
		}

	}
	.middle{
		display: flex;
		@include responsive("sm") {
			display: none;;
		}
		.iconTitleWrapper {
			display: flex;
			align-items: center;
			height: 100%;
			vertical-align: middle;
			.title {
				font-size: 25px;
				font-weight: 500;
				margin-left: 10px;
				@include responsive('xs') {
					font-size:22px;
				}
			}
			.icon {
				width: 25px;
				height: 25px;
				font-size: 18px;
				padding: 4px;
				text-align: center;
				border-radius: 4px;
			}
		}
	}
	&:before,
	&:after {
		content: '';
		position: absolute;
		z-index: -1;
	}
	&:before {
		border-radius: 100% / 10%;
		bottom: 0;
		box-shadow: 0 0 10px 2px $shadow-color;
		content: '';
		left: 2%;
		right: 2%;
		top: 0;
	}
	.datepickerMobile{
		@include responsive("smUp"){
			display: none;
		}
		// background: var(--mid);
		color: $primary;
		cursor: pointer;
		height: 40px;
        margin-top: -3px;
        margin-right: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		&.active {
			color: $ALTERNATIVE1;
		}
		span {
			font-size: 28px;
		}
	}
	.datepickerMobileHidden {
		@extend .datepickerMobile;
		background: var(--light);
		cursor: default;
	}
	.datepickerDropdown {
		z-index: 100;
		position: absolute;
		right: 0;
        top: 40px;
		padding: 2px;
		background: var(--mid);
		width: 100%;
		text-align: center;
        box-shadow: 0px 3px 6px rgba(50, 50, 50, 0.95);
	}
}
.vdp-datepicker {
	input {
		color: white;
	}
}
.dark {
	.headWrap {
		.vdp-datepicker__calendar {
				background: $dmDark;
				border-color: $dmMed;
		}
	}
}

.existingNew {
  // background: white;
  // border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  .iconHolder {
    // width: 36rem;
    display: flex;
    text-align: center;
    color: black;
    background: white;
    // margin-left: 1rem;
    // padding: 0.1rem 0.9rem 0.1rem 0.9rem;
    padding: 0.5em 0.8em;
    opacity: 1;
    &:nth-child(1) {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    &:nth-child(2) {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:hover {
      opacity: 1;
    }
    @media only screen and (max-width: 800px) {
      // width: 26rem;
      padding: 0.5em 1.5em;
      display: flex;
      align-items: center;
    }
    .flag {
      font-size: 1.2em;
      margin-right: 0.4em;
      color: var(--lcpPrimary);
      @media only screen and (max-width: 800px) {
        font-size: 2em;
      }
    }
    .text {
      font-size: 1em;
      font-weight: 500;
      @include responsive('md') {
        font-size: 1.5em;
      }
    }
    background-color: white;
    // background-size: 100% 200%;
    // background-image: linear-gradient(to bottom, white 50%,var(--lcpGrapefruit) 50%);
    transition: background-color 1s;
    &.selected {
	background: $BRIGHT;

    //   background-color: var(--lcpGrapefruit);
      // color: white;
      // background-position: 0 -100%;
      //background: var(--lcpGrapefruit);
      //opacity: 1;
      .fleetIcon {
        color: var(--lcpSecondary);
        // color: white;
      }
    }
  }
}

@media (max-width: 1536px) {
	.headWrap {
		.analyticsMode {
			height: 25px;
		}
	}
}
</style>
