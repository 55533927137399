<template>
<div style="font-size: 13px;">
    <div class="holder">
        Granularity:
        <i class="icofont-question-circle" v-tooltip="'This will only reduce the granularity of a series.'"/>
        <buttonHolder :options="aggregateGranularities" :initialSelectedId="aggregateOptions.granularity" @select="o => aggregateOptions.granularity = o.id" />
    </div>
    <div class="holder">
        Type:
        <buttonHolder :options="types" @select="o => aggregateOptions.type = o.id" :initialSelectedId="aggregateOptions.type" />
    </div>
    <div class="holder">
        <p-check v-model="aggregateOptions.cumulative" class="p-switch p-fill" :disabled="aggregateOptions.delta">
            Cumulative
        </p-check>
        <i class="icofont-question-circle" v-tooltip="'Calculate the cumulative series for the data aggregated as specified above.<br />MW series are converted to MWh.'"/>
    </div>
    <div>
        <p-check v-model="deltaEnabled" @change="deltaEnabledUpdated" class="p-switch p-fill" :disabled="aggregateOptions.cumulative">
            Delta
        </p-check>
        <i class="icofont-question-circle" v-tooltip="'Calculate the delta series for the data aggregated as specified above.<br />Each point is displayed as the difference to a point in the past.<br />The point to compare to is the number of points in the past that you select.'"/>
        <enactInput style="width: 50px; display: inline-block; margin-left: 10px" doNotEmitOnConstructor :disabled="!deltaEnabled"
            :showTooltipError="false" useInputInsteadOfTextArea inputType="number"
            :startingVal="`${aggregateOptions.delta}`" @textChange="el => { aggregateOptions.delta = parseFloat(el.content); }"
        />
    </div>
</div>
</template>

<script>
import buttonHolder from '@/components/layout/buttonHolder';
import chartAggregateMixin from '@/components/views/mixins/chartAggregateMixin';
import enactInput from '@/components/editor/enactInput';

export default {
    props: {
        aggregateOptionsInitial: {
            type: Object,
        },
    },
    components: {
        buttonHolder,
        enactInput,
    },
    mixins: [chartAggregateMixin],
    created () {
        if (this.aggregateOptionsInitial) {
            this.aggregateOptions.cumulative = this.aggregateOptionsInitial.cumulative;
            this.aggregateOptions.delta = this.aggregateOptionsInitial.delta;
            this.aggregateOptions.granularity = this.aggregateOptionsInitial.granularity;
            this.aggregateOptions.type = this.aggregateOptionsInitial.type;

            this.deltaEnabled = this.aggregateOptions.delta != null;
        }
    },
    data () {
        return {
            aggregateOptions: {
                cumulative: false,
                delta: null,
                granularity: null,
                type: null,
            },
            deltaEnabled: false,
            types: [{
                id: null,
                name: 'None',
            }, {
                id: 'average',
                name: 'Average',
            }, {
                id: 'min',
                name: 'Min',
            }, {
                id: 'max',
                name: 'Max',
            }, {
                id: 'sum',
                name: 'Sum',
            }, {
                id: 'spread',
                name: 'Spread',
            }, {
                id: 'standardDeviation',
                name: 'Standard Deviation',
            }, {
                id: 'variance',
                name: 'Variance',
            }],
        };
    },
    computed: {
        buttonDisabled () {
            return this.aggregateOptions.granularity === null ? this.aggregateOptions.type !== null : this.aggregateOptions.type === null;
        },
    },
    methods: {
        deltaEnabledUpdated () {
            if (this.deltaEnabled) {
                this.aggregateOptions.delta = 1;
            } else {
                this.aggregateOptions.delta = null;
            }
        },
    },
    watch: {
        aggregateOptions: {
            handler (newVal, oldVal) {
                this.$emit('updateAggregateOptions', this.aggregateOptions);
            },
            deep: true,
        },
    },
};
</script>
<style lang="scss" scoped>
.buttonHold {
    display: flex;
    justify-content: flex-end;
    .button {
        font-size: 12px;
        height: 30px;
        line-height: 20px;
    }
}
.holder {
    margin-bottom: 28px;
}
</style>
