import Vue from 'vue';
import axios from 'axios';
import moment from 'moment-timezone';
import { buildChart } from '@/components/presets/chartUpdater/buildDbFormat';

Vue.use(require('vue-moment'), {
    moment,
});

Vue.moment.tz.setDefault('Europe/London');

export default {
    namespaced: true,
    state: {

    },
    computed: {

    },
    getters: {
    },
    actions: {
        async getHistoricForecastChart ({}, chart) {
            const endpoint = await axios.post(`${process.env.VUE_APP_STATIC_API}/staticApi/forecastHistory/endpoint`, chart.data.series);
            return await axios.post(`${endpoint.data}/historyOfForecast/${chart.type}/`, chart.data).then((response) => response.data);
        },
        async getHistoricalHistoricForecastChart ({}, data) {
            const endpoint = await axios.post(`${process.env.VUE_APP_STATIC_API}/staticApi/forecastHistory/endpoint`, data.series);
            return await axios.post(`${endpoint.data}/historyOfForecast/historicData/`, data).then((response) => response.data);
        },
        async getDynamicChart ({}, chart) {
            return await axios.post(`${process.env.VUE_APP_STATIC_API}/staticApi/dynamicchart/${chart.type}/`, chart.data).then((response) => response.data);
        },
        async getPlantChart ({}, chart) {
            return await axios.post(`${process.env.VUE_APP_STATIC_API}/staticApi/plantchart/${chart.type}/`, chart.data).then((response) => response.data);
        },
        buildDBFormat ({}, charts) {
            if (charts.length > 0) {
                const returnCharts = [];
                for (let i = 0; i < charts.length; i++) {
                    const chart = charts[i];
                    if (chart.data !== null) {
                        const dbFormat = buildChart(chart);
                        returnCharts.push(dbFormat);
                    }
                }
                return returnCharts;
            }
        },
        async jointSeriesConfig ({ commit }, chart) {
            return (await axios.post(`${process.env.VUE_APP_STATIC_API}/staticApi/jointConfig/config/`, chart)).data;
        },
        jointSeriesConfigFromSingleId ({ commit }, chart) {
            return new Promise((resolve) => axios.post(`${process.env.VUE_APP_STATIC_API}/staticApi/jointConfig/configFromSingleId/`, chart).then((response) => {
                resolve(response.data);
            }));
        },
    },
    mutations: {

    },
};
