<template>
<div>
    <div class="buttonHolderHolder">
        <buttonHolder smaller :initialSelectedId="selectedDateOptionId" :options="dateOptions" @select="el => selectOption(el)" class="buttonHolder"/>
    </div>
    <div class="buttonHolderHolder">
        <buttonHolder v-if="smallDate && !useSingleDay" smaller :initialSelectedId="smallDateFromTo" :options="[{
            id: 'from',
            name: 'From',
        }, { id: 'to',
            name: 'To',
        }]" @select="el => smallDateFromTo = el.id" class="buttonHolder"/>
    </div>
    <chartAggregate
        v-if="selectedDateOptionId === 'aggregate'"
        :aggregateOptionsInitial="aggregateOptions"
        @updateAggregateOptions="el => updateAggregateOptions(el)"
    />
    <div class="dates-wrapper" :class="{ 'smaller': hideButtons, 'singleDay': useSingleDay }" v-click-outside="close" v-else>
        <!-- <i class="icofont-ui-close" @click="close" v-if="!hideButtons"></i> -->
        <div class="dates-flex">
            <div class="left" v-if="!smallDate || smallDateFromTo === 'from'">
                <div class="header" v-if="!useSingleDay">{{fromTitle}}</div>
                <template v-if="selectedDateOptionId !== 'hour'">
                <datepicker :inline="true"
                    :style="{opacity: from !== fromToIndex('date', 'from') ? 0.5 : 1}"
                    :disabled="from !== fromToIndex('date', 'from')"
                    @input="updateFromDate"
                    :value="fromDateCal"
                    placeholder="Select date"
                    :minimumView="minimumView"
                    :format="dateFormat"
                    :disabledDates="disabledDates"
                />
                <buttonHolder smaller watchInitial
                    :initialSelectedId="`${from}`"
                    :options="(this.useSingleDay ? singleDayChecks : fromDayChecks).map((el, key) => ({id: `${key}`, name: el.text}))"
                    @select="el => radioOptionChange(el.id, true)"
                />
                <input
                    name="days"
                    type="number"
                    v-model="fromDaysOrHours"
                    v-if="from === fromToIndex('dynamDay', 'from')"
                    min="1"
                    @input="debounceInputChanged()"
                    class="input"
                />
                </template>
                <template v-else>
                <div v-if="!hideHours" v-show="!useSingleDay" class="item" style="margin-top: 5px; margin-bottom: 5px;">
                    <label for="start-hour"> Start Time </label>
                    <input
                        name="start-hour"
                        type="time"
                        v-model="fromHoursForDay"
                        @input="debounceInputChanged()"
                        class="timepicker"
                        :style="{opacity: from === fromToIndex('hours', 'from') ? 0.3 : 1}"/>
                </div>
 
                <div class="item" v-show="!useSingleDay" v-for="(fromHourCheck, fromKey) in fromHourChecks" :key="fromHourCheck.text">
                    <p-radio v-model="from" :value="fromKey + fromDayChecks.length" @change="radioOptionChange($event, true)"
                        class="p-icon p-round p-fill p-smooth" name="from" color="primary">
                        <i slot="extra" class="icon icofont-check"></i>
                        {{fromHourCheck.text}}
                    </p-radio>
                    <input
                        name="days"
                        type="number"
                        v-model="fromDaysOrHours"
                        v-if="fromHourCheck.input === 'text'"
                        min="1"
                        @input="debounceInputChanged()"
                        :disabled="!((from === fromToIndex('hours', 'from')) && fromKey + fromDayChecks.length === from)"
                        class="input"
                        :style="{opacity: !((from === fromToIndex('hours', 'from')) && fromKey + fromDayChecks.length === from) ? 0.5 : 1}"/>
                </div>
                </template>
            </div>
 
            <div class="right" v-show="!useSingleDay && (!smallDate || smallDateFromTo === 'to')">
                <div class="header">To</div>
                <template v-if="selectedDateOptionId !== 'hour'">
                <datepicker :inline="true"
                    :style="{opacity: to !== fromToIndex('date', 'to') ? 0.5 : 1}"
                    :disabled="to !== fromToIndex('date', 'to')"
                    @input="updateToDate"
                    :value="toDateCal"
                    placeholder="Select date"
                    :minimumView="minimumView"
                    :format="dateFormat"
                    :disabledDates="disabledDates"
                />
                <buttonHolder smaller watchInitial
                    :initialSelectedId="`${to}`"
                    :options="toDayChecks.map((el, key) => ({id: `${key}`, name: el.text}))" @select="el => radioOptionChange(el.id, false)"
                />
                <input
                    name="days"
                    type="number"
                    v-model="toDaysOrHours"
                    @input="debounceInputChanged"
                    v-if="to === fromToIndex('dynamDay', 'to')"
                    min="1"
                    class="input"
                />
                </template>
                <template v-else>
                <div v-if="!hideHours" class="item" style="margin-top: 5px; margin-bottom: 5px;">
                    <label for="end-hour"> End Time </label>
                    <input
                        name="end-hour"
                        type="time"
                        v-model="toHoursForDay"
                        @input="debounceInputChanged()"
                        class="timepicker"
                        :style="{opacity: from === fromToIndex('hours', 'from') ? 0.3 : 1}"/>
                </div>
 
                <div class="item" v-for="(toHourCheck, toKey) in toHourChecks" :key="toHourCheck.text">
                    <p-radio v-model="to" :value="toKey + toDayChecks.length" @change="radioOptionChange($event, false)"
                        class="p-icon p-round p-fill p-smooth" name="to" color="primary">
                        <i slot="extra" class="icon icofont-check"></i>
                        {{toHourCheck.text}}
                    </p-radio>
                    <input
                        name="days"
                        type="number"
                        v-model="toDaysOrHours"
                        @input="debounceInputChanged"
                        v-if="toHourCheck.input === 'text'"
                        min="1"
                        :disabled="!((to === fromToIndex('hours', 'to')) && toKey + toDayChecks.length === to)"
                        class="input"
                        :style="{opacity: !((to === fromToIndex('hours', 'to')) && toKey + toDayChecks.length === to) ? 0.5 : 1}"/>
                </div>
                </template>
            </div>
        </div>
        <div class="info" v-if="infoText">
            {{infoText}}
        </div>
    </div>
    <div class="flex">
        <div
            @click.stop="debounceOverwiteDate"
            @keyup.enter="debounceOverwiteDate"
            class="button btn"
            v-if="allowOverrideAll"
            v-tooltip="'Overwrite dashboard date'"
        >
            Update Page
            <span class="icofont-ui-calendar" />
        </div>
        <div
            @click.stop="restoreCharts"
            @keyup.enter="restoreCharts"
            class="button btn"
            v-if="allowOverrideAll && overwriteDateMode"
            v-tooltip="'Restore individual chart dates'"
        >
            Restore Charts
            <span class="icofont-undo" />
        </div>
        <div class="button btn" @click="updateChart" v-if="!hideButtons && (!overwriteDateMode || isHeader)">
            {{updateTextOverride}}
            <span class="icofont-ui-calendar" />
        </div>
    </div>
</div>
</template>
 
<script>
import Datepicker from 'vuejs-datepicker';
import vClickOutside from 'v-click-outside';
import buttonHolder from '@/components/layout/buttonHolder';
import chartAggregate from '@/components/modules/chartComponents/chartAggregate';
import throttle from 'lodash/throttle';
import { DatePickerMethods } from '@/components/inputs/datePicker/datePickerMethods';
 
export default {
    name: 'dateRangePicker',
    components: {
        Datepicker,
        buttonHolder,
        chartAggregate,
    },
    props: {
        info: {
            type: Object,
            fromCal: Object,
            toCal: Object,
        },
        hideButtons: {
            type: Boolean,
            required: false,
            default: false,
        },
        includeNone: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideHours: {
            type: Boolean,
            required: false,
            default: false,
        },
        historicOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        updateTextOverride: {
            type: String,
            required: false,
            default: 'Update',
        },
        infoText: {
            type: String,
            required: false,
            default: null,
        },
        minimumView: {
            type: String,
            required: false,
            default: 'day',
        },
        disabledDateOverride: {
            type: Object,
            required: false,
            default: null,
        },
        onlyAllowSingleDay: {
            type: Boolean,
            default: false,
        },
        aggregateOptionsInitial: {
            type: Object,
            required: false,
            default: null,
        },
        canAggregate: {
            type: Boolean,
            required: false,
            default: false,
        },
        allowOverrideAll: {
            type: Boolean,
            required: false,
            default: false,
        },
        smallDate: {
            type: Boolean,
            required: false,
            default: false,
        },
        isHeader: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    data () {
        return {
            from: 2,
            fromDateCal: '',
            fromDaysOrHours: 1,
            fromResult: '',
            selectedType: '',
            showOptions: false,
            smallDateFromTo: 'from',
            to: 2,
            toDate: 0,
            toDateCal: '',
            toDaysOrHours: 1,
            toResult: '',
            disabledDates: {
                to: this.$moment('2015-01-01').toDate(),
            },
            fromDayChecks: [
                {
                    text: 'Custom', columnType: 'from', input: 'date', type: 'date', value: '', id: 'date',
                },
            ],
            toDayChecks: [
                {
                    text: 'Custom', columnType: 'to', input: 'date', type: 'date', value: '', id: 'date',
                },
            ],
            singleDayChecks: [
                {
                    text: 'Custom', columnType: 'from', input: 'date', type: 'date', value: '', id: 'date',
                },
            ],
            fromHourChecks: [],
            toHourChecks: [],
            defaultFromHoursForDay: '00:00',
            defaultToHoursForDay: '23:59',
            fromHoursForDay: '00:00',
            toHoursForDay: '23:59',
            selectedDateOptionId: 'date',
            aggregateOptions: null,
            debounceInputChanged: throttle(this.inputChanged, 500),
        };
    },
    created () {
        this.debounceOverwiteDate = throttle(this.overwriteDate, 2000);
        this.aggregateOptions = this.aggregateOptionsInitial;
        let allowHistorics = true;
        if (this.disabledDateOverride) {
            this.disabledDates = this.disabledDateOverride;
            if (this.disabledDates.to) {
                const disabledTo = this.$moment(this.disabledDates.to).startOf('day');
                const yesterday = this.$moment().add(-1, 'day').startOf('day');
 
                allowHistorics = !disabledTo.isSameOrAfter(yesterday);
            }
        }
 
        if (!this.historicOnly) {
            if (allowHistorics && !this.isMonth) {
                const pastDays = {
                    text: 'Past Days', columnType: 'from', input: 'text', type: 'dynamic', value: 1, id: 'dynamDay',
                };
                this.fromDayChecks.push(pastDays);
                this.singleDayChecks.push(pastDays);
            }
            if (!this.isMonth) {
                const today = {
                    text: 'Today', columnType: 'from', input: '', type: 'date', value: 0, id: 'currentDay',
                };
                this.fromDayChecks.push(today);
                this.singleDayChecks.push(today);
            }
            if (allowHistorics && !this.useSingleDay) {
                if (!this.isMonth) {
                    this.fromDayChecks.push({
                        text: 'Week Start', columnType: 'from', input: '', type: 'dynamic', value: 'StartWeek', id: 'week',
                    });
                }
                if (!this.disabledDates.disableStartOfMonth) {
                    this.fromDayChecks.push({
                        text: 'Month Start', columnType: 'from', input: '', type: 'dynamic', value: 'StartMonth', id: 'month',
                    });
                }
            }
 
            if (!this.isMonth) {
                if (!this.disableNextNumberOfDays) {
                    this.toDayChecks.push({
                        text: 'Next Days', columnType: 'to', input: 'text', type: 'dynamic', value: 1, id: 'dynamDay',
                    });
                }
                this.toDayChecks.push({
                    text: 'Today', columnType: 'to', input: '', type: 'date', value: 0, id: 'currentDay',
                });
                if (!this.disabledDates.disableEndOfWeek) {
                    this.toDayChecks.push({
                        text: 'Week End', columnType: 'to', input: '', type: 'dynamic', value: 'EndWeek', id: 'week',
                    });
                }
            }
            if (!this.disabledDates.disableEndOfMonth) {
                this.toDayChecks.push({
                    text: 'Month End', columnType: 'to', input: '', type: 'dynamic', value: 'EndMonth', id: 'month',
                });
            }
        } else {
            if (!this.isMonth) {
                this.fromDayChecks.push({
                    text: 'Past No. Days', columnType: 'from', input: 'text', type: 'dynamic', value: 1, id: 'dynamDay',
                });
                if (!this.disabledDates.disableNextNumberOfDays) {
                    this.toDayChecks.push({
                        text: 'Next No. Days', columnType: 'to', input: 'text', type: 'dynamic', value: 1, id: 'dynamDay',
                    });
                }
            }
        }
 
        if (!this.hideHours) {
            this.fromHourChecks.push({
                text: 'Hours Before', columnType: 'from', input: 'text', type: 'dynamicHours', value: 'HoursBefore', id: 'hours',
            });
            this.toHourChecks.push({
                text: 'Hours After', columnType: 'to', input: 'text', type: 'dynamicHours', value: 'HoursAfter', id: 'hours',
            });
        }
        if (this.includeNone) {
            this.fromHourChecks.push({
                text: 'None', columnType: 'from', input: '', type: 'none', value: 'none', id: 'none',
            });
            this.toHourChecks.push({
                text: 'None', columnType: 'to', input: '', type: 'none', value: 'none', id: 'none',
            });
        }
        if (this.info.hasOwnProperty('date')) {
            this.fromIndex = this.fromDayChecks.findIndex((f) => f.type === this.info.date.from.type && f.value === this.info.date.from.value);
            if (this.fromIndex > -1) {
                this.from = this.fromIndex;
                this.fromDayChecks[1].value = this.fromIndex === this.fromToIndex('dynamDay', 'from') ? this.info.date.from.value : 1;
                this.fromHoursForDay = this.info.date.fromHoursForDay != null ? this.info.date.fromHoursForDay : this.defaultFromHoursForDay;
                this.fromDateCal = this.fromIndex === this.fromToIndex('date', 'from') ? this.fromDayChecks[this.fromIndex].value : new Date();
            } else if (this.info.date.from.type === 'date' && this.info.date.from.value <= 5) { // currentDay should be limited to a value of 0, but this catches for incorrect DB settings
                this.from = this.fromToIndex('currentDay', 'from');
                this.fromDaysOrHours = this.info.date.from.value;
                this.fromHoursForDay = this.info.date.fromHoursForDay == null ? this.defaultFromHoursForDay : this.info.date.fromHoursForDay;
                this.fromDateCal = new Date();
            } else if (!isNaN(this.info.date.from.value) && this.info.date.from.type === 'dynamic') {
                this.from = this.fromToIndex('dynamDay', 'from');
                this.fromDaysOrHours = this.info.date.from.value;
                this.fromHoursForDay = this.info.date.fromHoursForDay == null ? this.defaultFromHoursForDay : this.info.date.fromHoursForDay;
            } else {
                this.from = this.fromToIndex('date', 'from');
                this.fromDaysOrHours = this.info.date.from.value;
                this.fromHoursForDay = this.info.date.fromHoursForDay == null ? this.defaultFromHoursForDay : this.info.date.fromHoursForDay;
                this.fromDateCal = this.info.date.from.type === 'dynamicHours' ? new Date() : this.info.date.from.value;
                this.info.fromCal = this.fromDateCal;
            }
            this.toIndex = this.toDayChecks.findIndex((f) => f.type === this.info.date.to.type && f.value === this.info.date.to.value);
            if (this.toIndex > -1) {
                this.to = this.toIndex;
                this.toDayChecks[1].value = this.toIndex === this.fromToIndex('dynamDay', 'to') ? this.info.date.to.value : 1;
                this.toHoursForDay = this.info.date.toHoursForDay != null ? this.info.date.toHoursForDay : this.defaultToHoursForDay;
                this.toDateCal = this.fromIndex === this.fromToIndex('date', 'to') ? this.toDayChecks[this.toIndex].value : new Date();
            } else if (this.info.date.to.type === 'date' && this.info.date.to.value <= 5) { // currentDay should be limited to a value of 0, but this catches for incorrect DB settings
                this.to = this.fromToIndex('currentDay', 'to');
                this.toDaysOrHours = this.info.date.to.value;
                this.toHoursForDay = this.info.date.toHoursForDay == null ? this.defaultToHoursForDay : this.info.date.toHoursForDay;
                this.toDateCal = new Date();
            } else if (!isNaN(this.info.date.to.value) && this.info.date.to.type === 'dynamic') {
                this.to = this.fromToIndex('dynamDay', 'to');
                this.toDaysOrHours = this.info.date.to.value;
                this.toHoursForDay = this.info.date.toHoursForDay == null ? this.defaultToHoursForDay : this.info.date.toHoursForDay;
            } else {
                this.to = this.fromToIndex('date', 'to');
                this.toDaysOrHours = this.info.date.to.value;
                this.toHoursForDay = this.info.date.toHoursForDay == null ? this.defaultToHoursForDay : this.info.date.toHoursForDay;
                this.toDateCal = this.info.date.to.type === 'dynamicHours' ? new Date() : this.info.date.to.value;
                this.info.toCal = this.toDateCal;
            }
            if (this.info.date.recentHour) {
                this.from = this.fromToIndex('hours', 'from');
                this.to = this.fromToIndex('hours', 'to');
                this.fromHoursForDay = this.defaultFromHoursForDay;
                this.toHoursForDay = this.defaultToHoursForDay;
                this.fromDaysOrHours = this.info.date.hasOwnProperty('fromHours') ? this.info.date.fromHours : 1;
                this.toDaysOrHours = this.info.date.hasOwnProperty('toHours') ? this.info.date.toHours : 1;
            }
        } else {
            this.from = this.fromToIndex('none', 'from');
            this.to = this.fromToIndex('none', 'to');
        }
 
        const fromIsTo = this.updateDateRange();
        if (fromIsTo) this.selectedDateOptionId = 'day';
    },
    computed: {
        dateOptions () {
            if (this.onlyAllowSingleDay) {
                return [{
                    id: 'day', name: 'Single Day',
                }];
            }

            const options = [{
                id: 'date', name: 'Day range',
            }, {
                id: 'day', name: 'Single Day',
            }];
            if (!this.hideHours) {
                options.push({
                    id: 'hour', name: 'Hours',
                });
            }
            if (this.canAggregate) {
                options.push({
                    id: 'aggregate', name: 'Granularity', space: true,
                });
            }
            return options;
        },
        useSingleDay () {
            return this.onlyAllowSingleDay || this.selectedDateOptionId === 'day';
        },
        overwriteDateMode () {
            return this.$store.state.data.analysisDate.dashboard.overwriteDashboardDate;
        },
        isMonth () {
            return this.minimumView === 'month';
        },
        fromTitle () {
            if (this.onlyOneMonthAtATime) return 'Month';
            return this.useSingleDay ? 'Day' : 'From';
        },
        onlyOneMonthAtATime () {
            return this.$store.state.ui.pageName === 'monthAheadDashboard';
        },
        dateFormat () {
            return {
                day: 'dd MMM yyyy',
                month: 'MMM yyyy',
                year: 'yyyy',
            }[this.minimumView];
        },
    },
    methods: {
        overwriteDate () {
            this.$store.dispatch('user/updateDate', this.getDateToEmit());
            this.$store.commit('overwriteDashboardDateChanged', true);
            this.close();
        },
        restoreCharts () {
            this.$store.commit('overwriteDashboardDateChanged', false);
            this.close();
        },
        updateAggregateOptions (aggregateOptions) {
            this.aggregateOptions = aggregateOptions;
        },
        selectOption (option) {
            this.selectedDateOptionId = option.id;
        },
        fromToIndex (type, fromOrTo) {
            const toCheck = fromOrTo === 'from' ? this.fromDayChecks : this.toDayChecks;
            let index = toCheck.findIndex(el => el.id === type);
            if (index === -1) {
                const toHourCheck = fromOrTo === 'from' ? this.fromHourChecks : this.toHourChecks;
                index = toHourCheck.findIndex(el => el.id === type);
                if (index !== -1) index += toCheck.length;
            }
            return index;
        },
        inputChanged () {
            if (this.hideButtons) this.updateChart();
            this.updateDateRange();
        },
        radioOptionChange (selected, fromDateSelected) {
            selected = parseInt(selected);
            if (fromDateSelected) this.from = selected;
            else this.to = selected;
 
            const fromHoursIndex = this.fromToIndex('hours', 'from');
            const toHoursIndex = this.fromToIndex('hours', 'to');
            if (this.from === fromHoursIndex || this.to === toHoursIndex) {
                if (selected === fromHoursIndex || selected === toHoursIndex) {
                    this.from = fromHoursIndex;
                    this.to = toHoursIndex;
                    this.fromHoursForDay = this.defaultFromHoursForDay;
                    this.toHoursForDay = this.defaultToHoursForDay;
                } else {
                    this.from = selected;
                    this.to = selected;
                }
            }
 
            const fromDaysIndex = this.fromToIndex('dynamDay', 'from');
            const toDaysIndex = this.fromToIndex('dynamDay', 'to');
            if (selected === fromHoursIndex || selected === toHoursIndex || selected === fromDaysIndex || selected === toDaysIndex) {
                if (!(this.from === fromDaysIndex && this.to === toDaysIndex && !fromDateSelected)) this.fromDaysOrHours = 1;
                if (!(this.from === fromDaysIndex && this.to === toDaysIndex && fromDateSelected)) this.toDaysOrHours = 1;
            }
 
            const fromDateIndex = this.fromToIndex('date', 'from');
            const toDateIndex = this.fromToIndex('date', 'to');
            if (selected !== fromDateIndex && selected !== toDateIndex) {
                if (this.hideButtons) this.updateChart();
            }
            this.updateDateRange();
        },
        close () {
            if (this.hideButtons) return;
            this.$emit('close');
        },
        toggleOptions () {
            this.showOptions = !this.showOptions;
        },
        openCalendar () {
            this.$refs.calendarOpen.showCalendar();
        },
        getDateToEmit () {
            if (this.fromDayChecks.length > 1) {
                this.fromDayChecks[1].value = parseInt(this.fromDaysOrHours);
            }
            if (this.toDayChecks.length > 1) {
                this.toDayChecks[1].value = parseInt(this.toDaysOrHours);
            }
            if (this.info) {
                this.toDayChecks[0].value = this.onlyOneMonthAtATime ? this.info.fromCal : this.info.toCal;
                this.fromDayChecks[0].value = this.info.fromCal;
            }
            const fromHoursIndex = this.fromToIndex('hours', 'from');
            const fromNoneIndex = this.fromToIndex('none', 'from');
 
            const from = {
                type: this.from === fromHoursIndex || this.from === fromNoneIndex ? this.fromHourChecks[this.from - fromHoursIndex].type : this.fromDayChecks[this.from].type,
                value: this.from === fromHoursIndex || this.from === fromNoneIndex ? this.fromHourChecks[this.from - fromHoursIndex].value : this.fromDayChecks[this.from].value,
            };
            let to;
            if (this.useSingleDay) {
                let valueForTo = from.value;
                if (from.type === 'dynamic') valueForTo = -valueForTo;
                to = {
                    type: from.type,
                    value: valueForTo,
                };
            } else {
                to = {
                    type: this.from === fromHoursIndex || this.from === fromNoneIndex ? this.toHourChecks[this.to - fromHoursIndex].type : this.toDayChecks[this.to].type,
                    value: this.from === fromHoursIndex || this.from === fromNoneIndex ? this.toHourChecks[this.to - fromHoursIndex].value : this.toDayChecks[this.to].value,
                };
            }
 
            return {
                from,
                to,
                recentHour: this.from === fromHoursIndex,
                fromHours: this.from === fromHoursIndex ? this.fromDaysOrHours : 1,
                toHours: this.from === fromHoursIndex ? this.toDaysOrHours : 1,
                fromHoursForDay: this.fromHoursForDay,
                toHoursForDay: this.toHoursForDay,
                aggregateOptions: this.aggregateOptions,
            };
        },
        updateDateRange () {
            const holder = this.getDateToEmit();
            const { from, to } = DatePickerMethods.getDatesFromConfig(holder);
            this.fromDateCal = from.toDate();
            this.toDateCal = to.toDate();
            return from.startOf('day').isSame(to.startOf('day'));
        },
        updateChart () {
            this.$emit('dateChange', this.getDateToEmit(), this.aggregateOptions);
            this.$emit('close');
        },
        updateFromDate (value) {
            this.from = this.fromToIndex('date', 'from');
            this.fromDayChecks[0].value = value;
            this.info.fromCal = value;
            if (this.to === this.fromToIndex('date', 'to')) {
                this.toDayChecks[0].value = this.info.toCal;
            } else {
                this.toDayChecks[0].value = '';
            }
 
            if (this.hideButtons) this.updateChart();
        },
        updateToDate (value) {
            if (this.isMonth) {
                value = this.$moment(value).endOf('month').toDate();
                this.toDateCal = value;
            }
 
            this.to = this.fromToIndex('date', 'to');
            this.toDayChecks[0].value = value;
            this.info.toCal = value;
            if (this.from === this.fromToIndex('date', 'from')) {
                this.fromDayChecks[0].value = this.info.fromCal;
            } else {
                this.fromDayChecks[0].value = '';
            }
 
            if (this.hideButtons) this.updateChart();
        },
    },
};
</script>
 
<style lang="scss" scoped>
.buttonHolderHolder {
    display: flex;
    justify-content: center;
    .buttonHolder {
        margin-bottom: 15px;
    }
}
.flex {
    display: flex;
    justify-content: flex-end;
    .btn {
        margin-top: 10px;
        margin-left: 10px;
        font-size: 12px;
        height: 30px;
        line-height: 20px;
    }
}
.dates-wrapper {
    font-size: 14px;
    line-height: 26px;
    color: var(--primaryFont);
    .dates-flex {
        display: flex;
        justify-content: center;
        gap: 10px;
        // flex-wrap: wrap;
    }
    @include responsive("sm") {
        height: 100%;
    }
    @include responsive("smUp") {
        // width: 430px;
    }
    &.singleDay {
        @include responsive("smUp") {
            // width: 200px;
        }
        .left {
            width: unset;
        }
    }
    .left, .right {
        font-size: 13px;
        text-align: center;
        .header {
            margin-bottom: 5px;
            font-weight: 600;
        }
        .input {
            margin-top: 5px;
        }
    }
    .left {
        // padding-right: 15px;
        &::v-deep .vdp-datepicker__calendar {
            left: -105px;
        }
    }
    .right {
        &::v-deep .vdp-datepicker__calendar {
            left: -160px;
        }
    }
    &::v-deep .vdp-datepicker__calendar {
        right: 0px;
    }
    .options {
        width: 210px;
    }
    .icofont-ui-close {
        color: $primary;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
    }
    input {
        border: none;
        font-size: 15px;
        padding: 2px;
        width: 50px;
    }
    .info {
        padding: 5px;
    }
}
</style>
<style lang="scss">
.item {
    .vdp-datepicker__calendar {
        font-size: 0.8rem;
        width: 195px;
        height: 195px;
        .cell {
            height: 23px;
            line-height: 23px;
        }
        .vdp-datepicker__calendar header {
            line-height: 23px;
        }
    }
        .vdp-datepicker {
            @include responsive("smUp") {
                margin: 0px 0px 0px 5px!important;
            }
            @include responsive("xs") {
                margin: 0px 0px 10px 5px!important;
            }
        }
        .timepicker {
            margin: 0px 0px 0px 10px!important;
            display: inline-block;
            font-family: inherit;
            font-size: 14px!important;
            line-height: 26px!important;
            text-align: center!important;
            width: 80px!important;
            height: 26px;
        }
        .timepicker::-webkit-calendar-picker-indicator {
            cursor: pointer;
        }
        .vdp-datepicker input {
            border-bottom: 1px solid $primary!important;
            cursor: pointer;
            display: inline-block;
            font-family: inherit;
            font-size: 14px!important;
            line-height: 26px!important;
            text-align: center!important;
            width: 90px!important;
            color: black;
        }
        .pretty input:checked ~ .state.default .icon {
            background: $primary;
            color: white;
        }
    }
</style>
