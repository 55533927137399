import { staticStore } from '@/staticStore';

function getMainCountry (countryId, getMainSubCountry = false) {
    const idToUse = countryId || 'Gb';
    const mainCountry = staticStore.state.data.countries.find(c => c.id === idToUse || c.mainSubCountryId === idToUse);

    if (getMainSubCountry && mainCountry.subCountries.length !== 0) {
        return mainCountry.subCountries.find(c => c.id === mainCountry.mainSubCountryId);
    } else return mainCountry;
}

function getMainCountrySimplified (countryId, getMainSubCountry = false) {
    const countryAll = getMainCountry(countryId, getMainSubCountry);
    return {
        id: countryAll.id,
        name: countryAll.name,
    };
}

export {
    getMainCountry,
    getMainCountrySimplified,
};
