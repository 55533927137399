var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "periodSelector",
      class: {
        centerPeriod:
          _vm.$store.state.ui.pageName === "boa" ||
          _vm.$store.state.ui.pageName === "boaComparer",
      },
    },
    [
      _c("datepicker", {
        staticClass: "clickable",
        attrs: { value: _vm.dateString, disabledDates: _vm.disabledDates },
        on: { input: _vm.updateCurrentDate },
      }),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPeriod,
              expression: "showPeriod",
            },
          ],
          staticClass: "periodInlineWrapper",
        },
        [
          _c("i", {
            staticClass: "icofont-rounded-up icofont-rotate-270",
            on: {
              click: function ($event) {
                return _vm.periodChanger("down")
              },
            },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeDropdown,
                  expression: "closeDropdown",
                },
              ],
              staticClass: "periodInline",
            },
            [
              _c(
                "span",
                {
                  class: { tradingPeriod: _vm.useTrading },
                  on: {
                    click: function ($event) {
                      _vm.periodDropdownVisible = !_vm.periodDropdownVisible
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.currentPeriod) + " ")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.periodDropdownVisible,
                      expression: "periodDropdownVisible",
                    },
                  ],
                  staticClass: "periodDropdown card",
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("Jump to period"),
                  ]),
                  _vm._l(_vm.availablePeriods, function (i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "cell",
                        class: { selected: _vm.currentPeriod === i },
                        on: {
                          click: function ($event) {
                            return _vm.jumpToPeriod(i)
                          },
                        },
                      },
                      [_vm._v(_vm._s(i))]
                    )
                  }),
                ],
                2
              ),
            ]
          ),
          _c("i", {
            staticClass: "icofont-rounded-up icofont-rotate-90",
            on: {
              click: function ($event) {
                return _vm.periodChanger("up")
              },
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pastFuturePresent !== "present",
                  expression: "pastFuturePresent !== 'present'",
                },
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Jump to live period",
                  expression: "'Jump to live period'",
                },
              ],
              staticClass: "jumpToPresent",
              on: { click: _vm.jumpToLive },
            },
            [_c("span", { staticClass: "icofont-clock-time" })]
          ),
        ]
      ),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTimer,
              expression: "showTimer",
            },
          ],
          staticClass: "divider",
        },
        [_vm._v("|")]
      ),
      _c("countdownTimer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTimer,
            expression: "showTimer",
          },
        ],
        attrs: { restartCountdown: _vm.restartCountdown },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }