var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fixedModal",
    {
      attrs: { title: _vm.title, showDarkBackground: "" },
      on: { close: _vm.onClickOutside },
    },
    [
      !_vm.shareResult
        ? _c("div", { staticClass: "shareWrapper row" }, [
            _c(
              "div",
              { staticClass: "dashboards niceScroll col-md-8" },
              _vm._l(_vm.selectedDashboards, function (dashboard, key) {
                return _c("div", { key: key }, [
                  _c(
                    "div",
                    { staticClass: "card" },
                    [
                      _c(
                        "p-check",
                        {
                          staticClass: "p-icon p-square p-fill p-smooth",
                          attrs: { name: "check", color: "primary" },
                          model: {
                            value: dashboard.selected,
                            callback: function ($$v) {
                              _vm.$set(dashboard, "selected", $$v)
                            },
                            expression: "dashboard.selected",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "icon icofont-check",
                            attrs: { slot: "extra" },
                            slot: "extra",
                          }),
                          _c("div", { staticClass: "wrapper" }, [
                            _c("div", { staticClass: "dbIcon" }, [
                              _c("span", {
                                class: "" + dashboard.icon,
                                style: { background: dashboard.colour },
                              }),
                            ]),
                            _c("div", { staticClass: "option__desc" }, [
                              _c("span", { staticClass: "option__id" }, [
                                _vm._v(_vm._s(dashboard.name)),
                              ]),
                              _c("span", { staticClass: "option__name" }, [
                                _vm._v(
                                  " " + _vm._s(dashboard.description) + " "
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("multiselect", {
                  attrs: {
                    options: _vm.usersToSendTo,
                    multiple: true,
                    placeholder: "Select user",
                    showLabels: false,
                  },
                  model: {
                    value: _vm.selectedUsers,
                    callback: function ($$v) {
                      _vm.selectedUsers = $$v
                    },
                    expression: "selectedUsers",
                  },
                }),
                _c("div", { staticClass: "bottomAbsolute" }, [
                  _c("div", { staticClass: "warningText" }, [
                    _vm._v(
                      "Please note this will automatically add your " +
                        _vm._s(_vm.displayName) +
                        " to the selected users."
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "button",
                      class: { disabled: _vm.buttonDisabled },
                      on: { click: _vm.sendDashboards },
                    },
                    [_vm._v(_vm._s(_vm.message))]
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("loader", { attrs: { showLoader: _vm.shareResult } }, [
        _c("div", { staticClass: "message" }, [
          _vm.haveSuccessMessages
            ? _c(
                "div",
                [
                  _c("div", [
                    _vm._v(
                      "Successfully shared " +
                        _vm._s(_vm.shareResult.pageShared) +
                        "s:"
                    ),
                  ]),
                  _vm._l(
                    _vm.shareResult.successMessages,
                    function (item, index) {
                      return _c("div", { key: index }, [_vm._v(_vm._s(item))])
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm.haveFailedMessages
            ? _c(
                "div",
                _vm._l(_vm.shareResult.failedMessages, function (item, index) {
                  return _c("div", { key: index }, [_vm._v(_vm._s(item))])
                }),
                0
              )
            : _vm._e(),
          _c("div", { staticClass: "button", on: { click: _vm.closeShare } }, [
            _vm._v("Close"),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }