var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "searchDiv section",
      class: { textInputted: !_vm.showPlaceholder },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPlaceholder,
              expression: "showPlaceholder",
            },
          ],
          staticClass: "placeholder",
        },
        [
          _c("span", { staticClass: "text" }, [_vm._v("Search platform")]),
          _c("font-awesome-icon", {
            staticClass: "icon",
            attrs: { icon: ["fas", "search"] },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "card card__modal searchModal",
          class: { setHeight: !_vm.showHelperText },
        },
        [
          _vm.showHelperText
            ? _c("div", { staticClass: "helperDiv" }, [
                _c("div", { staticClass: "title" }, [_vm._v("Try:")]),
                _c("div", { staticClass: "option" }, [
                  _vm._v("Search for a plant."),
                ]),
                _c("div", { staticClass: "option" }, [
                  _vm._v("Search for an Enact series or chart."),
                ]),
                _c("div", { staticClass: "option" }, [
                  _vm._v("Search for an energy definition in our database."),
                ]),
              ])
            : _c(
                "div",
                { staticClass: "searchResults" },
                _vm._l(_vm.results, function (sectionResults, sectionKey) {
                  return sectionResults.optionHolder.options.length !== 0
                    ? _c(
                        "div",
                        { key: sectionKey, staticClass: "card searchSection" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(sectionResults.title)),
                          ]),
                          _c(
                            "div",
                            { staticClass: "results niceScroll" },
                            [
                              _vm._l(
                                sectionResults.optionHolder.options,
                                function (result, resultKey) {
                                  return _c(
                                    "div",
                                    {
                                      key: resultKey,
                                      staticClass: "result",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectResult(
                                            sectionResults,
                                            result
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "icon",
                                        class:
                                          "icofont-" +
                                          (result.icon || "light-bulb"),
                                        style: {
                                          background:
                                            result.colour || "rgb(0, 47, 95)",
                                        },
                                      }),
                                      _c("div", { staticClass: "text" }, [
                                        sectionResults.showId
                                          ? _c("span", { staticClass: "id" }, [
                                              _vm._v(_vm._s(result.id)),
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "name",
                                            class: {
                                              hideNameMobile:
                                                sectionResults.showId,
                                            },
                                          },
                                          [_vm._v(_vm._s(result.name))]
                                        ),
                                        result.owner
                                          ? _c(
                                              "span",
                                              { staticClass: "owner" },
                                              [_vm._v(_vm._s(result.owner))]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              sectionResults.optionHolder.capped
                                ? _c("div", { staticClass: "capped" }, [
                                    _c("strong", [_vm._v("Note:")]),
                                    _vm._v(" Results shown are capped "),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
        ]
      ),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputText,
            expression: "inputText",
          },
        ],
        staticClass: "searchInput",
        domProps: { value: _vm.inputText },
        on: {
          focus: function ($event) {
            _vm.inputFocussed = true
          },
          blur: function ($event) {
            _vm.inputFocussed = false
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.inputText = $event.target.value
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }