var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fixedModal",
    {
      attrs: { showDarkBackground: "", current: "" },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", { staticClass: "getintouch" }, [
        _c("section", { staticClass: "wrapper" }, [
          _c("div", { staticClass: "container form" }, [
            _vm.isSubmitted === false
              ? _c("section", { staticClass: "form-holder" }, [
                  _c("div", { staticClass: "header section-heading" }, [
                    _c("h1", { staticClass: "mainTitle" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.eworld
                              ? "Contact us about E-world"
                              : "Interested in LCP Energy?"
                          ) +
                          " "
                      ),
                      _vm.eworld
                        ? _c("span", [
                            _vm._v(
                              " Want to arrange a chat or join us for a drink at E-world?"
                            ),
                            _c("br"),
                            _vm._v("A member of our team will get in touch. "),
                          ])
                        : _c("span", [
                            _vm._v(
                              " Sign up to some insights from our Energy Market experts. Alternatively, the Enact team would be happy to arrange a demo for you. "
                            ),
                          ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      class: _vm.errors.lenght ? "error" : "clean",
                      attrs: { method: "post" },
                    },
                    [
                      _c(
                        "form",
                        {
                          attrs: { id: "form" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.handleSubmit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "form-content-holder" }, [
                            _c(
                              "div",
                              { staticClass: "form-content firstname" },
                              [
                                _c("label", { attrs: { for: "first name" } }, [
                                  _vm._v("First Name"),
                                  _c("span", { staticClass: "required" }, [
                                    _vm._v("*"),
                                  ]),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.clientDetails.firstname,
                                      expression: "clientDetails.firstname",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  class: { error: _vm.errors.fname },
                                  attrs: { type: "text", name: "firstname" },
                                  domProps: {
                                    value: _vm.clientDetails.firstname,
                                  },
                                  on: {
                                    keyup: _vm.checkForm,
                                    blur: [
                                      _vm.checkForm,
                                      function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    ],
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.clientDetails,
                                        "firstname",
                                        $event.target.value.trim()
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm.errors.fname
                              ? _c(
                                  "div",
                                  { staticClass: "error-highlight-message" },
                                  [_vm._v(" " + _vm._s(_vm.errors.fname) + " ")]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "form-content-holder" }, [
                            _c(
                              "div",
                              { staticClass: "form-content lastname" },
                              [
                                _c("label", { attrs: { for: "last name" } }, [
                                  _vm._v("Last Name"),
                                  _c("span", { staticClass: "required" }, [
                                    _vm._v("*"),
                                  ]),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.clientDetails.lastname,
                                      expression: "clientDetails.lastname",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  class: { error: _vm.errors.lname },
                                  attrs: { type: "text", name: "lastname" },
                                  domProps: {
                                    value: _vm.clientDetails.lastname,
                                  },
                                  on: {
                                    keyup: _vm.checkForm,
                                    blur: [
                                      _vm.checkForm,
                                      function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    ],
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.clientDetails,
                                        "lastname",
                                        $event.target.value.trim()
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm.errors.lname
                              ? _c(
                                  "div",
                                  { staticClass: "error-highlight-message" },
                                  [_vm._v(" " + _vm._s(_vm.errors.lname) + " ")]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "form-content-holder" }, [
                            _c("div", { staticClass: "form-content email" }, [
                              _c("label", { attrs: { for: "email" } }, [
                                _vm._v("Email"),
                                _c("span", { staticClass: "required" }, [
                                  _vm._v("*"),
                                ]),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.clientDetails.email,
                                    expression: "clientDetails.email",
                                    modifiers: { trim: true },
                                  },
                                ],
                                class: { error: _vm.errors.email },
                                attrs: { type: "text", name: "email" },
                                domProps: { value: _vm.clientDetails.email },
                                on: {
                                  keyup: _vm.checkForm,
                                  blur: [
                                    _vm.checkForm,
                                    function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  ],
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.clientDetails,
                                      "email",
                                      $event.target.value.trim()
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm.errors.email
                              ? _c(
                                  "div",
                                  { staticClass: "error-highlight-message" },
                                  [_vm._v(" " + _vm._s(_vm.errors.email) + " ")]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "form-content-holder" }, [
                            _c(
                              "div",
                              { staticClass: "form-content company topSpace" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "company name" } },
                                  [
                                    _vm._v("Company Name"),
                                    _c("span", { staticClass: "required" }, [
                                      _vm._v("*"),
                                    ]),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.clientDetails.companyName,
                                      expression: "clientDetails.companyName",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  class: { error: _vm.errors.companyName },
                                  attrs: { type: "text", name: "company" },
                                  domProps: {
                                    value: _vm.clientDetails.companyName,
                                  },
                                  on: {
                                    keyup: _vm.checkForm,
                                    blur: [
                                      _vm.checkForm,
                                      function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    ],
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.clientDetails,
                                        "companyName",
                                        $event.target.value.trim()
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm.errors.companyName
                              ? _c(
                                  "div",
                                  { staticClass: "error-highlight-message" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.errors.companyName) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "form-content-holder" }, [
                            _c(
                              "div",
                              { staticClass: "form-content jobtitle" },
                              [
                                _c("label", { attrs: { for: "job title" } }, [
                                  _vm._v("Job Title"),
                                  _c("span", { staticClass: "required" }, [
                                    _vm._v("*"),
                                  ]),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.clientDetails.jobTitle,
                                      expression: "clientDetails.jobTitle",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  class: { error: _vm.errors.jobTitle },
                                  attrs: { type: "text", name: "jobtitle" },
                                  domProps: {
                                    value: _vm.clientDetails.jobTitle,
                                  },
                                  on: {
                                    keyup: _vm.checkForm,
                                    blur: [
                                      _vm.checkForm,
                                      function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    ],
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.clientDetails,
                                        "jobTitle",
                                        $event.target.value.trim()
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm.errors.jobTitle
                              ? _c(
                                  "div",
                                  { staticClass: "error-highlight-message" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.errors.jobTitle) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "form-content-holder" }, [
                            _c("div", { staticClass: "form-content country" }, [
                              _c("label", { attrs: { for: "country" } }, [
                                _vm._v("Country"),
                                _c("span", { staticClass: "required" }, [
                                  _vm._v("*"),
                                ]),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.clientDetails.country,
                                    expression: "clientDetails.country",
                                    modifiers: { trim: true },
                                  },
                                ],
                                class: { error: _vm.errors.country },
                                attrs: { type: "text", name: "country" },
                                domProps: { value: _vm.clientDetails.country },
                                on: {
                                  keyup: _vm.checkForm,
                                  blur: [
                                    _vm.checkForm,
                                    function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  ],
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.clientDetails,
                                      "country",
                                      $event.target.value.trim()
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm.errors.country
                              ? _c(
                                  "div",
                                  { staticClass: "error-highlight-message" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.errors.country) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "form-content-holder" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-content whereHeardFrom topSpace",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "whereHeardFrom-content",
                                    attrs: { for: "whereHeardFrom" },
                                  },
                                  [
                                    _vm._v("How did you hear about Enact?"),
                                    _c("span", { staticClass: "required" }, [
                                      _vm._v("*"),
                                    ]),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.clientDetails.whereHeardFrom,
                                      expression:
                                        "clientDetails.whereHeardFrom",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  class: { error: _vm.errors.whereHeardFrom },
                                  attrs: {
                                    type: "text",
                                    name: "whereHeardFrom",
                                  },
                                  domProps: {
                                    value: _vm.clientDetails.whereHeardFrom,
                                  },
                                  on: {
                                    keyup: _vm.checkForm,
                                    blur: [
                                      _vm.checkForm,
                                      function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    ],
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.clientDetails,
                                        "whereHeardFrom",
                                        $event.target.value.trim()
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm.errors.whereHeardFrom
                              ? _c(
                                  "div",
                                  { staticClass: "error-highlight-message" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.errors.whereHeardFrom) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "form-content-holder" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-content checkboxHolder insight topSpace",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "enact-content",
                                    attrs: { for: "enact" },
                                  },
                                  [_vm._v(" Find out more about LCP Enact")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.clientDetails.enact,
                                      expression: "clientDetails.enact",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  attrs: { type: "checkbox", name: "enact" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.clientDetails.enact
                                    )
                                      ? _vm._i(_vm.clientDetails.enact, null) >
                                        -1
                                      : _vm.clientDetails.enact,
                                  },
                                  on: {
                                    keyup: _vm.checkForm,
                                    blur: _vm.checkForm,
                                    change: function ($event) {
                                      var $$a = _vm.clientDetails.enact,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.clientDetails,
                                              "enact",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.clientDetails,
                                              "enact",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.clientDetails,
                                          "enact",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "form-content-holder" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-content checkboxHolder insight topSpace",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "insight-content",
                                    attrs: { for: "insight" },
                                  },
                                  [
                                    _vm._v(
                                      " Receive further insights from our energy team"
                                    ),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.clientDetails.insight,
                                      expression: "clientDetails.insight",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  attrs: { type: "checkbox", name: "insight" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.clientDetails.insight
                                    )
                                      ? _vm._i(
                                          _vm.clientDetails.insight,
                                          null
                                        ) > -1
                                      : _vm.clientDetails.insight,
                                  },
                                  on: {
                                    keyup: _vm.checkForm,
                                    blur: _vm.checkForm,
                                    change: function ($event) {
                                      var $$a = _vm.clientDetails.insight,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.clientDetails,
                                              "insight",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.clientDetails,
                                              "insight",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.clientDetails,
                                          "insight",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "form-content-holder" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-content checkboxHolder webinars",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "webinars-content",
                                    attrs: { for: "webinars" },
                                  },
                                  [
                                    _vm._v(
                                      " Hear about future webinars and events from our energy team"
                                    ),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.clientDetails.webinars,
                                      expression: "clientDetails.webinars",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  attrs: { type: "checkbox", name: "webinars" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.clientDetails.webinars
                                    )
                                      ? _vm._i(
                                          _vm.clientDetails.webinars,
                                          null
                                        ) > -1
                                      : _vm.clientDetails.webinars,
                                  },
                                  on: {
                                    keyup: _vm.checkForm,
                                    blur: _vm.checkForm,
                                    change: function ($event) {
                                      var $$a = _vm.clientDetails.webinars,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.clientDetails,
                                              "webinars",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.clientDetails,
                                              "webinars",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.clientDetails,
                                          "webinars",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "form-content privacy" }, [
                            _c("p", [
                              _vm._v(" Please read our "),
                              _c("span", { on: { click: _vm.handleModal } }, [
                                _c("strong", [_vm._v("privacy policy")]),
                              ]),
                              _vm._v(
                                " to find out more about how we use your information. "
                              ),
                            ]),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass:
                                "form-content button cta link-color-lcp",
                              attrs: { type: "submit" },
                            },
                            [_c("span", [_vm._v("Submit")])]
                          ),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.isSubmitted
              ? _c("section", { staticClass: "success-message" }, [
                  _c("h3", [_vm._v("Thank you for getting in touch")]),
                  _c("p", [
                    _vm._v(
                      "If you requested a demo, the Enact team will get back to you as soon as possible."
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "cta link-color-lcp",
                      attrs: { type: "button" },
                      on: { click: _vm.formReset },
                    },
                    [
                      _c("span", [_vm._v("Reset form")]),
                      _c(
                        "svg",
                        {
                          staticClass: "hover-arrow",
                          attrs: {
                            width: "15",
                            height: "15",
                            viewBox: "0 0 10 10",
                            "aria-hidden": "true",
                          },
                        },
                        [
                          _c("g", { attrs: { "fill-rule": "evenodd" } }, [
                            _c("path", {
                              staticClass: "hover-arrow-linePath",
                              attrs: { d: "M0 5h7" },
                            }),
                            _c("path", {
                              staticClass: "hover-arrow-tipPath",
                              attrs: { d: "M1 1l4 4-4 4" },
                            }),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }