export default {
    data () {
        return {
            aggregateGranularities:
            [
                {
                    id: null,
                    name: 'None',
                    infoText: '',
                },
                {
                    id: 'halfHourly',
                    name: 'Half-Hour',
                    infoText: 'half hourly ',
                },
                {
                    id: 'hour',
                    name: 'Hour',
                    infoText: 'hourly ',
                },
                {
                    id: 'day',
                    name: 'Day',
                    infoText: 'daily ',
                },
                {
                    id: 'week',
                    name: 'Week',
                    infoText: 'weekly ',
                },
                {
                    id: 'month',
                    name: 'Month',
                    infoText: 'monthly ',
                },
            ],
        };
    },
    methods: {
        getIsCumulativeText (cumulative) {
            return cumulative ? 'Cumulative ' : '';
        },
    },
};
