var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "termsWrapper" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [_c("logo", { staticClass: "lcpLogo", attrs: { fontSize: 40 } })],
        1
      ),
      _c("termsBase", { staticClass: "base", attrs: { showButtons: true } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }