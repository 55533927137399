<template>
    <div class="countdown">
        <countdown ref="countdown" :endTime="endTime" @finish="moveToNextTradingWindow"  :key="componentKey">
            <span
                slot="process"
                slot-scope="{ timeObj }">{{ timeObj.m + ':' +timeObj.s }}</span>
                <span slot="finish">closed</span>
        </countdown>
    </div>
</template>

<script>
export default {
    data () {
        return {
            endTime: 0,
            componentKey: 0,

        };
    },
    props: {
        restartCountdown: {
            type: Boolean,
        },
    },

    mounted () {
        this.timeLeftToTrade();
    },
    computed: {
        useTradingPeriod () {
            return this.$store.getters['boaWindowType'] === 'trading';
        },
        analyticsMode () {
            return this.$store.state.data.pages.boaDashboard.analyticsMode;
        },
    },
    methods: {

        timeLeftToTrade () {
            const currentMin = this.$moment().minute();

            let endTime = 0;

            if (!this.useTradingPeriod) {
                if (currentMin < 30) {
                    endTime = this.$moment().minute(30).second(0);
                } else {
                    endTime = this.$moment().add(1, 'h').minute(0).second(0);
                }
            } else if (currentMin < 15) {
                endTime = this.$moment().minute(15).second(0);
            } else if (currentMin >= 15 && currentMin < 45) {
                endTime = this.$moment().minute(45).second(0);
            } else {
                endTime = this.$moment().add(1, 'h').minute(15).second(0);
            }
            this.endTime = endTime.toDate();
        },
        moveToNextTradingWindow () {
            if (this.$refs.countdown.endTime == 0 || this.$refs.countdown.endTime == null) return;
            if (this.$store.state.user.autoRollover) {
                this.$store.dispatch('getCurrentPeriod').then((response) => {
                    this.$store.commit('jumpToCurrentPeriod');

                    if (this.$store.getters.hasBoaAndNotAnalytics) {
                        this.$store.dispatch('getBoaData', null, { root: true });
                    }
                    if (this.$store.state.ui.pageName === 'boaComparer') {
                        this.$store.dispatch('getBoaComparerData', null, { root: true });
                    }

                    this.timeLeftToTrade();
                    // dont think needs destroying if it has ended
                    // this.componentKey += 1;
                    this.$refs.countdown.startCountdown('restart');
                });
            }
        },
    },
    watch: {
        useTradingPeriod () {
            this.timeLeftToTrade();
            this.componentKey += 1;
            this.$refs.countdown.startCountdown('restart');
        },
        restartCountdown () {
            this.timeLeftToTrade();
            this.componentKey += 1;
            this.$refs.countdown.startCountdown('restart');
        },
    },
};
</script>

<style lang="scss" scoped>
.countdown {
    // to stop timer jumping around
    width:100px;
    display: inline-block;
    font-size:20px;
    margin-left: 5px;
    text-align: left;
    color: $ALTERNATIVE1;
}
@media (max-width: 1536px) {
    .countdown {
        font-size: 18px;
    }
}
</style>
