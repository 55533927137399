var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _c(
      "div",
      { staticClass: "header", class: { isDashboard: _vm.isDashboard } },
      [
        _vm.showChartIcons || _vm.canEdit || _vm.canClose
          ? _c(
              "div",
              {
                staticClass: "icon-wrapper hidden",
                class: { top: !_vm.isSmallWidth, left: _vm.isSmallWidth },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showChartIcons,
                        expression: "showChartIcons",
                      },
                    ],
                    staticClass: "chart-icons",
                  },
                  [
                    _vm.showHelpIcon
                      ? _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.getLeftTooltip(
                                "What do the series on this chart mean?"
                              ),
                              expression:
                                "getLeftTooltip(`What do the series on this chart mean?`)",
                            },
                          ],
                          staticClass: "icon-controls icofont-question",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("openChartHelpModal")
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showChartIcons,
                        expression: "showChartIcons",
                      },
                    ],
                    staticClass: "chart-icons",
                  },
                  [
                    !_vm.showNoData && !_vm.showChart
                      ? _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.getLeftTooltip("Show as a graph"),
                              expression: "getLeftTooltip(`Show as a graph`)",
                            },
                          ],
                          staticClass: "icon-controls icofont-chart-line",
                          on: { click: _vm.toggleChart },
                        })
                      : _vm._e(),
                    !_vm.showNoData &&
                    !_vm.showTableInsteadOfChart &&
                    _vm.canTable
                      ? _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.getLeftTooltip(
                                this.chartTableDisabled
                                  ? "Please use Historical Analysis for large data exports"
                                  : "Show as a table"
                              ),
                              expression:
                                "getLeftTooltip(this.chartTableDisabled ? `Please use Historical Analysis for large data exports` : `Show as a table`)",
                            },
                          ],
                          staticClass: "icon-controls",
                          class: _vm.tableClass,
                          on: { click: _vm.toggleTable },
                        })
                      : _vm._e(),
                    !_vm.showNoData &&
                    !_vm.showMetricsInsteadOfChart &&
                    _vm.canMetric
                      ? _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.getLeftTooltip(
                                this.showMetricsInsteadOfChart
                                  ? "Hide metrics"
                                  : "Show metrics"
                              ),
                              expression:
                                "getLeftTooltip(this.showMetricsInsteadOfChart ? `Hide metrics` : `Show metrics`)",
                            },
                          ],
                          staticClass: "icon-controls icofont-list",
                          class: _vm.calculatingMetrics ? "disabled" : "",
                          on: { click: _vm.toggleMetrics },
                        })
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showChartIcons,
                        expression: "showChartIcons",
                      },
                    ],
                    staticClass: "chart-icons",
                  },
                  [
                    _vm.allowFullscreen && !_vm.showNoData && _vm.showChart
                      ? _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.getLeftTooltip("Show as full screen"),
                              expression:
                                "getLeftTooltip(`Show as full screen`)",
                            },
                          ],
                          staticClass: "icon-controls icofont-resize hidden-xs",
                          on: { click: _vm.showFullscreen },
                        })
                      : _vm._e(),
                    !_vm.showNoData &&
                    !_vm.showMetricsInsteadOfChart &&
                    _vm.showChart &&
                    _vm.canChangeYAxisLimits
                      ? _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.getLeftTooltip("Edit axis options"),
                              expression: "getLeftTooltip(`Edit axis options`)",
                            },
                          ],
                          staticClass:
                            "icon-controls icofont-chart-arrows-axis",
                          on: { click: _vm.toggleYAxisLimits },
                        })
                      : _vm._e(),
                    !_vm.showNoData && _vm.showChart && _vm.canDownloadImage
                      ? _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.getLeftTooltip(
                                "Download & print image"
                              ),
                              expression:
                                "getLeftTooltip(`Download & print image`)",
                            },
                          ],
                          staticClass: "icon-controls icofont-image hidden-xs",
                          attrs: { id: "exportChart" },
                          on: { click: _vm.openImageModal },
                        })
                      : _vm._e(),
                    _vm.showPopoutIcon
                      ? _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.getLeftTooltip("Expand chart"),
                              expression: "getLeftTooltip('Expand chart')",
                            },
                          ],
                          staticClass: "icon-controls icofont-external-link",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("openChartPopout")
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _c("div", { staticClass: "data" }, [
                  _vm.canEdit
                    ? _c("span", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getLeftTooltip(
                              _vm.lockedAccount
                                ? "Edit module locked in User Settings"
                                : "Edit this module"
                            ),
                            expression:
                              "getLeftTooltip(lockedAccount ? 'Edit module locked in User Settings' : 'Edit this module')",
                          },
                        ],
                        staticClass:
                          "icofont-ui-edit icon-controls hidden-xs clickableIcon",
                        class: { disabled: _vm.lockedAccount },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.editChart.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.canClose
                    ? _c("span", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getLeftTooltip(
                              _vm.lockedAccount
                                ? "Remove module locked in User Settings"
                                : "Remove this module"
                            ),
                            expression:
                              "getLeftTooltip(lockedAccount ? 'Remove module locked in User Settings' : 'Remove this module')",
                          },
                        ],
                        staticClass:
                          "icofont-trash icon-controls clickableIcon",
                        class: { disabled: _vm.lockedAccount },
                        on: { click: _vm.closeChart },
                      })
                    : _vm._e(),
                ]),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "visibleHeader",
            staticStyle: { marginLeft: "'10px'" },
          },
          [
            _vm.title
              ? _c(
                  "div",
                  {
                    staticClass: "title",
                    class: { small: _vm.titleSmall, ellipsis: _vm.showDate },
                    style: { fontWeight: _vm.isDashboard ? null : "500" },
                  },
                  [_vm._v(" " + _vm._s(_vm.title) + " ")]
                )
              : _vm._e(),
            _vm._t("default"),
            _vm.showDate || _vm.showBoostMessage || _vm.showHelpIcon
              ? _c(
                  "div",
                  { staticClass: "rightHolder" },
                  [
                    _vm.showDate
                      ? _c("datePickerHolder", {
                          staticClass: "dateHolder",
                          attrs: {
                            fromDate: _vm.fromDate,
                            toDate: _vm.toDate,
                            dateInfo: _vm.dateInfo,
                            updateTextOverride: "Update Chart",
                            dateDisabled: _vm.dateDisabled,
                            dateChanging: _vm.dateChanging,
                            hideDateRangePicker: _vm.hideDateRangePicker,
                            onlyAllowSingleDay: _vm.onlyAllowSingleDay,
                            fromHours: _vm.fromHours,
                            toHours: _vm.toHours,
                            smallDate: _vm.smallDate,
                            aggregateOptionsInitial:
                              _vm.aggregateOptionsInitial,
                            canAggregate: _vm.canAggregate,
                            allowOverrideAll: "",
                          },
                          on: { dateChange: _vm.dateChange },
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "info" }, [
                      _vm.showBoostMessage
                        ? _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: { content: _vm.boostHelpText },
                                expression: "{content: boostHelpText}",
                              },
                            ],
                            staticClass:
                              "icon icofont-exclamation-circle helpText",
                          })
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }