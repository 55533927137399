var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "absoluteModal",
    {
      staticClass: "settingsWrapper",
      attrs: { showDarkBackground: "" },
      on: { close: _vm.onClickOutside },
    },
    [
      _c("div", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: "Notifications",
            expression: "`Notifications`",
          },
        ],
        staticClass: "icon icofont-external",
        on: { click: _vm.goToNotificationPage },
      }),
      _c("moduleLiveTable", {
        staticClass: "table",
        attrs: {
          hideCard: "",
          dataAndConfig: _vm.emptyDataConfig,
          showHeader: false,
          canClose: false,
        },
      }),
      _c(
        "div",
        {
          staticClass: "footer button",
          on: { click: _vm.goToNotificationPage },
        },
        [_vm._v("Go to Notification Manager")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }