var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: {} },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.noModal,
              expression: "!noModal",
            },
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.getTooltipText,
              expression: "getTooltipText",
            },
          ],
          staticClass: "dateDisplay",
          class: {
            disabled: _vm.dateDisabled || _vm.dateChanging || _vm.lockedAccount,
            clickDisabled: _vm.dateChanging || _vm.lockedAccount,
            isHeader: _vm.isHeader,
          },
          on: { click: _vm.toggleDatePicker },
        },
        [
          !_vm.smallDate
            ? _c("span", [_vm._v(" " + _vm._s(_vm.dateDisplay) + " ")])
            : _vm._e(),
          _c("span", { staticClass: "icofont-clock-time" }),
          _vm.dateChanging
            ? _c("img", {
                staticClass: "dateLoaderSvg",
                attrs: { src: _vm.moduleChartLoader },
              })
            : _vm._e(),
        ]
      ),
      _vm.showModal
        ? _c(
            _vm.noModal ? "div" : "absoluteModal",
            {
              tag: "component",
              class: { isHeader: _vm.isHeader, modal: !_vm.noModal },
              attrs: { noTitleSpace: "", showShadow: "", darkCard: "" },
              on: { close: _vm.toggleDatePicker },
            },
            [
              _c("dateRangePicker", {
                attrs: {
                  info: _vm.dateInfoToUse,
                  hideHours: _vm.hideTimes,
                  minimumView: _vm.minimumView,
                  updateTextOverride: _vm.updateTextOverride,
                  disabledDateOverride: _vm.disabledDate,
                  onlyAllowSingleDay: _vm.onlyAllowSingleDay,
                  aggregateOptionsInitial: _vm.aggregateOptionsInitial,
                  canAggregate: _vm.canAggregate,
                  allowOverrideAll: _vm.allowOverrideAll,
                  smallDate: _vm.smallDate,
                  isHeader: _vm.isHeader,
                },
                on: { dateChange: _vm.dateChange, close: _vm.toggleDatePicker },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }