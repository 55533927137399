<template>
<transition name="fade" mode="out-in">
<div>
    <div class="modal" @click="closeSlideOut"></div>
    <div id="wrapper" class="card" :style="`${height}${width}`">
        <div class="inner">
            <div class="tabHeader">
                <span v-if="selectedComponent.newTitle" class="title">{{selectedComponent.newTitle}}</span>
                <div class="close-wrapper"><span class="icofont-ui-close" @click="closeSlideOut"></span></div>
            </div>
            <div style="height: calc(100% - 25px)">
                <slot></slot>
            </div>
        </div>
    </div>
</div>
</transition>
</template>

<script>
export default {
    name: 'mainModal',
    data () {
        return {
            // showSlot: null,
        };
    },
    computed: {
        // slideOpen () {
        //     return this.$store.state.ui.slideOut.slideOutOpen ? 'open' : '';
        // },
        selectedComponent () {
            return this.$store.state.ui.slideOut.slideOutObject;
        },
        height () {
            if (this.selectedComponent.maxHeight) return 'maxHeight: 80%; minHeight: 20%;';
            if (this.selectedComponent.fitContent) return '';
            return 'height: 80%;';
        },
        width () {
            if (this.selectedComponent.fitContent) return '';
            return 'width: 90%;';
        },
    },
    methods: {
        closeSlideOut () {
            this.$store.commit('toggleSlideOutMenu');
        },
        handleKeyUp (event) {
            if (event.keyCode === 27) {
                this.closeSlideOut();
            }
        },
    },
    watch: {
        // allows the sidebar to show before actually loading the content;
        // slideOpen (newVal, oldVal) {
        //     this.showSlot = false;
        //     if (newVal === 'open') {
        //         this.showSlot = true;
        //     }
        // },
    },
    mounted () {
        document.addEventListener('keyup', this.handleKeyUp);
    },
    destroyed () {
        document.removeEventListener('keyup', this.handleKeyUp);
    },
};
</script>

<style lang="scss" scoped>
.modal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $background-modal;
    z-index: 99;
    animation: fade $modal-transition linear;
    @keyframes fade {
        from { opacity: 0; }
        to { opacity: 1; }
    }
}
#wrapper {
    background: var(--dark);
    box-shadow: 0 19px 32px rgba(0,0,0,0.30), 0 5px 12px rgba(0,0,0,0.6);

    transform: translate(-50%, -50%);
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;

    display: flex;

    animation: fade $modal-transition linear;
    @keyframes fade {
        from { opacity: 0; }
        to { opacity: 1; }
    }
  .inner {
    width: 100%;
  }
  .tabHeader {
      position: relative;
      margin-bottom: 20px;
      .close-wrapper {
        color: $primary;
        font-size: 15px;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
      }
      .title {
          font-weight: 600;
          font-size: 20px;
      }
  }
}
.dark {
    #wrapper {
		.inner {
			color: white;
		}
	}
}
</style>
