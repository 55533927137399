var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "terms" }, [
      _c("div", { staticClass: "card holder" }, [
        _c("h1", [_vm._v("LCP Enact Terms & Conditions")]),
        _c(
          "div",
          { ref: "termsHolder", staticClass: "termsHolder niceScroll" },
          [
            _vm._m(0),
            _c("h1", [_vm._v("LCP Enact Authorised User Confirmation")]),
            _c("p", [
              _vm._v(
                "You acknowledge and agree: (a) that these Terms and Conditions (including, without limitation, the exclusions and limitations of liability in Sections 6,8 and 9) are fair, reasonable and necessary for LCP in the protection of its Confidential Information, intellectual property rights and its business; (b) having reviewed these Terms and Conditions, that there is nothing which would breach, or cause the breach of, or would conflict with any other terms (including, without limitation, terms of employment) to which you may be subject; (c) your entering into discussions with LCP, and your furnishing information/content (if applicable), shall not infringe the intellectual property rights of any third party; and (d) that the Licence granted by LCP is the sole benefit and/or advantage that may arise to you under these Terms and Conditions and any other benefits and/or advantages (which by operation of law or otherwise) may arise are hereby disclaimed and waived."
              ),
            ]),
            _vm.showButtons
              ? _c(
                  "div",
                  {
                    staticClass: "button marginLeft",
                    on: { click: _vm.agree },
                  },
                  [_vm._v("Accept")]
                )
              : _vm._e(),
            _vm.showButtons
              ? _c(
                  "div",
                  {
                    staticClass: "button marginLeft",
                    on: { click: _vm.disagree },
                  },
                  [_vm._v("Disagree")]
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("By using Enact, you are agreeing to the terms stated here: "),
      _c(
        "a",
        {
          attrs: {
            href: "https://delta.lcp.com/technology-products-standard-terms/",
          },
        },
        [_vm._v("LCP Delta technology products standard terms")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }