<template>
  <div>
    <div class="background">
    </div>
    <div class="diagonal-box1">
    </div>
    <div class="diagonal-box2">
    </div>
  </div>
</template>

<script>
export default {
    name: 'stripedBackground',
};
</script>

<style lang="scss" scoped>
.diagonal-box1 {
  background-image: linear-gradient(45deg, #0b325f, #0b325f);
  opacity: 0.8;
  transform: skewY(-11deg);
  height: 100vh;
  width: 100%;
  top: 50vh;
  position: fixed;
}
.diagonal-box2 {
  background-image: linear-gradient(45deg, #0a315e, #0a315e);
  opacity: 0.5;
  transform: skewY(50deg);
  height: 200vh;
  width: 100%;
  top: 50vh;
  position: fixed;
}
.background {
  background-color: #21416d;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 0;
}
</style>
