<template>
    <fixedModal :title="title" showDarkBackground @close="onClickOutside">
        <div class="shareWrapper row" v-if="!shareResult">
            <div class="dashboards niceScroll col-md-8">
                <div v-for="(dashboard, key) in selectedDashboards" :key="key">
                    <div class="card">
                        <p-check name="check" class="p-icon p-square p-fill p-smooth" color="primary"
                            v-model="dashboard.selected">
                            <i slot="extra" class="icon icofont-check"></i>
                            <div class="wrapper">
                                <div class="dbIcon">
                                    <span
                                        :class="`${dashboard.icon}`"
                                        :style="{background : dashboard.colour}"
                                    />
                                </div>
                                <div class="option__desc">
                                    <span class="option__id">{{ dashboard.name }}</span>
                                    <span class="option__name">
                                        {{dashboard.description}}
                                    </span>
                                </div>
                            </div>
                        </p-check>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <multiselect
                    :options="usersToSendTo" v-model="selectedUsers"
                    :multiple="true"
                    placeholder="Select user"
                    :showLabels="false"
                />

                <div class="bottomAbsolute">
                    <div class="warningText">Please note this will automatically add your {{displayName}} to the selected users.</div>
                    <div class="button" :class="{ 'disabled': buttonDisabled }" @click="sendDashboards">{{message}}</div>
                </div>
            </div>
        </div>
        <loader :showLoader="shareResult">
            <div class="message">
                <div v-if="haveSuccessMessages">
                    <div>Successfully shared {{shareResult.pageShared}}s:</div>
                    <div v-for="(item, index) in shareResult.successMessages" :key="index">{{item}}</div>
                </div>
                <div v-if="haveFailedMessages">
                    <div v-for="(item, index) in shareResult.failedMessages" :key="index">{{item}}</div>
                </div>
                <div class="button" @click="closeShare">Close</div>
            </div>
        </loader>
    </fixedModal>
</template>

<script>
import { dashboardAllowedOnEnactType } from '@/components/presets/chartUpdater/buildDbFormat';
import fixedModal from '@/components/layout/fixedModal';

export default {
    data () {
        return {
            selectedDashboards: [],
            usersToSendTo: [],
            selectedUsers: [],
            shareType: null,
            title: '',
            displayName: '',
            message: '',
            shareResult: null,
        };
    },
    components: {
        fixedModal,
    },
    created () {
        this.shareType = this.$route.name;

        switch (this.shareType) {
        case 'dashboard':
            this.selectedDashboards = this.dashboards.map(d => ({
                id: d.id,
                icon: d.icon,
                colour: d.colour,
                name: d.name,
                description: d.description,
                selected: false,
            }));
            this.title = 'Share Dashboards';
            this.displayName = 'dashboards';
            this.message = 'Send Dashboards';
            break;
        case 'bingoCard':
            this.selectedDashboards = this.bingoBoards.map(b => ({
                id: b.id,
                icon: '',
                colour: 'transparent',
                name: b.name,
                description: '',
                selected: false,
            }));
            this.title = 'Share Bingo Boards';
            this.displayName = 'bingo boards';
            this.message = 'Send Bingo Boards';
            break;
        case 'NotificationsPage':
            this.selectedDashboards = this.notifications.map(n => ({
                id: n.uiId,
                icon: '',
                colour: 'transparent',
                name: n.name,
                description: '',
                selected: false,
            }));
            this.title = 'Share Notifications';
            this.displayName = 'notifications';
            this.message = 'Send Notifications';
            break;
        default:
            console.error('page not shareType option');
            return;
        }

        this.$axios.get(`${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/getCompanyUsers`).then((response) => {
            this.usersToSendTo = response.data;
        }).catch(e => {
            this.usersToSendTo = ['No other users at company'];
        });
    },
    computed: {
        dashboards () {
            return this.$store.state.user.pages.userDashboard.user.views.filter(dashboard => dashboardAllowedOnEnactType(dashboard, false));
        },
        bingoBoards () {
            return Object.values(this.$store.state.user.pages.bingoNew.boards);
        },
        notifications () {
            return Object.values(this.$store.state.user.pages.subscriptions.allSubscriptions);
        },
        buttonDisabled () {
            return this.selectedDashboards.filter(d => d.selected).length === 0 || this.selectedUsers.length === 0;
        },
        haveFailedMessages () {
            if (!this.shareResult) return false;
            return this.shareResult.failedMessages.length > 0;
        },
        haveSuccessMessages () {
            if (!this.shareResult) return false;
            return this.shareResult.successMessages.length > 0;
        },
    },
    methods: {
        onClickOutside () {
            this.$emit('closeShare');
        },
        sendDashboards () {
            if (this.buttonDisabled) return;
            const dashboardsToPost = [];
            const selectedDashboards = this.selectedDashboards.filter(d => d.selected);
            selectedDashboards.forEach((d) => {
                dashboardsToPost.push(d.id);
            });
            const numberOfUniqueIdsNeeded = this.selectedUsers.length * dashboardsToPost.length;
            const uniqueIds = [];
            for (let i = 0; i < numberOfUniqueIdsNeeded; i++) {
                uniqueIds.push(this.$uuid.v4());
            }
            const toPost = {
                dashboards: dashboardsToPost,
                users: this.selectedUsers,
                shareTypeString: this.shareType,
                uniqueIds,
            };
            this.$axios.post(`${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/sendDashboards`, toPost).then((response) => {
                this.shareResult = response.data;
            });
        },
        closeShare () {
            this.$emit('closeShare');
        },
    },
};
</script>

<style lang="scss" scoped>
.shareWrapper {
    display: flex;
    width: 1000px;
    height: 80vh;
    .dashboards {
        font-size: 20px;
        height: 100%;
        padding-right: 10px;
        .card {
            margin-top: 10px;
            margin-bottom: 10px;
            background: var(--mid);
            .wrapper {
                display: flex;
                .dbIcon {
                    margin-left: 10px;
                    span {
                        font-size: 44px;
                        padding: 3px;
                    }
                }
                .option__desc {
                    display: block;
                    width: 90%;
                }
            }
            ::v-deep .pretty.p-smooth label:after, ::v-deep .pretty.p-smooth label:before, ::v-deep .icon {
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .bottomAbsolute {
        position: absolute;
        padding-left: 30px;
        bottom: 20px;
        right: 20px;
        width: 100%;
        .button {
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .warningText {
            padding-left: 30px;
            text-align: right;
            position: absolute;
            bottom: 60px;
            right: 0;
        }
    }
}
</style>
