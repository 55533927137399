var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bottomLinks" },
    [
      _c(
        "router-link",
        {
          staticClass: "link textLinkSmall animate hidden-xs",
          attrs: { to: "/glossary" },
        },
        [_vm._v(" Glossary ")]
      ),
      _c(
        "router-link",
        { staticClass: "link textLinkSmall animate", attrs: { to: "/terms" } },
        [_vm._v("Terms & Conditions")]
      ),
      _c(
        _vm.loggedIn ? "router-link" : "span",
        {
          tag: "component",
          staticClass: "link textLinkSmall animate hidden-xs",
          class: { disabledRouter: !_vm.loggedIn },
          attrs: { to: "/externalinstructions" },
        },
        [
          _vm._v(" API & DPS Instructions "),
          !_vm.loggedIn
            ? _c("disabled-icon", {
                staticClass: "disabledIcon",
                attrs: { accessLevel: "Enact Visualise" },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }