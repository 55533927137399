var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showFilters && _vm.isGb
        ? _c(
            "div",
            { staticClass: "bmuFilter" },
            [
              _vm._l(
                _vm.$staticStore.state.data.allPlants,
                function (catObject, catKey) {
                  return [
                    _vm.filterCategoriesToShow.includes(catKey)
                      ? _c(
                          "p-check",
                          {
                            key: catKey,
                            staticClass: "p-icon p-square p-fill p-smooth",
                            attrs: { name: "check", color: "primary" },
                            model: {
                              value: _vm.categoryToShow[catKey],
                              callback: function ($$v) {
                                _vm.$set(_vm.categoryToShow, catKey, $$v)
                              },
                              expression: "categoryToShow[catKey]",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "icon icofont-check",
                              attrs: { slot: "extra" },
                              slot: "extra",
                            }),
                            _vm._v(" " + _vm._s(catObject.categoryName) + " "),
                          ]
                        )
                      : _vm._e(),
                  ]
                }
              ),
            ],
            2
          )
        : _vm._e(),
      _c(
        "multiselect",
        {
          ref: "plantTrigger",
          class: {
            autoWidth: _vm.autoWidth,
            light: _vm.light,
            extraHeight: _vm.variableMultiselectHeight,
          },
          style: { width: _vm.fullWidth ? "100%" : "" },
          attrs: {
            options: _vm.allPlants,
            "custom-label": _vm.idAndName,
            disabled: _vm.disabled,
            "track-by": "searchQuery",
            searchable: true,
            "close-on-select": !_vm.allowMultiple,
            "show-labels": false,
            optionsLimit: 100,
            multiple: _vm.allowMultiple,
            placeholder: _vm.placeholderOverride,
            resetAfter: _vm.resetAfter,
            openDirection: _vm.openDirection,
            label: "searchQuery",
          },
          on: { open: _vm.clearSelected, input: _vm.emitValue },
          scopedSlots: _vm._u([
            {
              key: "singleLabel",
              fn: function (props) {
                return [
                  _c("span", { staticClass: "option__desc" }, [
                    _c("span", { staticClass: "option__title" }, [
                      _vm._v(_vm._s(props.option.id)),
                    ]),
                  ]),
                ]
              },
            },
            {
              key: "option",
              fn: function (props) {
                return [
                  _c("div", { staticClass: "option__icon" }, [
                    _c("span", {
                      staticClass: "icon",
                      class: "icofont-" + props.option.icon,
                      style: { background: props.option.colour },
                    }),
                  ]),
                  _c("div", { staticClass: "option__desc" }, [
                    _c("span", { staticClass: "option__id" }, [
                      _vm._v(_vm._s(props.option.id)),
                    ]),
                    _c("span", { staticClass: "option__name" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.aggregateType === "owner" ||
                              _vm.aggregateType === "group"
                              ? ""
                              : props.option.name !== null &&
                                props.option.name !== "Unknown"
                              ? props.option.name
                              : "-"
                          ) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(
                            props.option.owner !== null &&
                              props.option.name !== "Unknown"
                              ? props.option.owner
                              : _vm.aggregateType === "owner" ||
                                _vm.aggregateType === "group"
                              ? ""
                              : "-"
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selectedPlant,
            callback: function ($$v) {
              _vm.selectedPlant = $$v
            },
            expression: "selectedPlant",
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "multiselect_arrow",
              attrs: { slot: "caret" },
              slot: "caret",
            },
            [_c("font-awesome-icon", { attrs: { icon: ["fas", "search"] } })],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }