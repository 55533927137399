<template>
    <div class="bottomLinks">
        <!-- <router-link to="/status" class="link textLinkSmall animate hidden-xs">
            Status
        </router-link> -->
        <router-link to="/glossary" class="link textLinkSmall animate hidden-xs">
            Glossary
        </router-link>

        <router-link to="/terms" class="link textLinkSmall animate">Terms & Conditions</router-link>

        <component
            :is="loggedIn ? 'router-link' : 'span'"
            to="/externalinstructions"
            class="link textLinkSmall animate hidden-xs"
            :class="{ 'disabledRouter': !loggedIn }"
        >
            API & DPS Instructions
            <disabled-icon
                v-if="!loggedIn"
                class="disabledIcon"
                accessLevel="Enact Visualise"
            />
        </component>
    </div>
</template>

<script>

import sideNavPage from '@/components/sideNav/sideNavPage';
import disabledIcon from '@/components/layout/disabledIcon';

export default {
    components: {
        sideNavPage,
        disabledIcon,
    },
    data () {
        return {
            apiLink: {
                name: 'API & DPS Instructions',
                availableOnMobile: false,
            },
        };
    },
    computed: {
        loggedIn () {
            return !this.$store.state.ui.isFreeUser;
        },
    },
    methods: {

    },
};
</script>

<style lang="scss" scoped>
@import '@/components/sideNav/sideNav.scss';

.bottomLinks{
    background: var(--light);
    white-space: nowrap;
    overflow-x: hidden;
}
.disabledIcon {
    line-height: 10px;
}
</style>
