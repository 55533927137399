<template>
    <div style="datePickerHolder">
        <!-- This needs to be a v-show, so the computed triggers -->
        <div class="dateDisplay" v-show="!noModal" :class="{ 'disabled': dateDisabled || dateChanging || lockedAccount, 'clickDisabled': dateChanging || lockedAccount, 'isHeader': isHeader }" @click="toggleDatePicker"
            v-tooltip="getTooltipText">
            <span v-if="!smallDate">
                {{dateDisplay}}
            </span>
            <span class="icofont-clock-time"></span>
            <img v-if="dateChanging" class="dateLoaderSvg" v-bind:src="moduleChartLoader"/>
        </div>
        <component :is="noModal ? 'div' : 'absoluteModal'"
            :class="{ 'isHeader': isHeader, 'modal': !noModal }"
            noTitleSpace showShadow darkCard v-if="showModal" @close="toggleDatePicker">
            <dateRangePicker
                :info="dateInfoToUse"
                @dateChange="dateChange"
                :hideHours="hideTimes"
                :minimumView="minimumView"
                :updateTextOverride="updateTextOverride"
                :disabledDateOverride="disabledDate"
                @close="toggleDatePicker"
                :onlyAllowSingleDay="onlyAllowSingleDay"
                :aggregateOptionsInitial="aggregateOptionsInitial"
                :canAggregate="canAggregate"
                :allowOverrideAll="allowOverrideAll"
                :smallDate="smallDate"
                :isHeader="isHeader"
            />
        </component>
    </div>
</template>

<script>

import dateRangePicker from '@/components/inputs/datePicker/dateRangePicker';
import moduleChartLoader from '@/components/modules/components/loader.svg';
import absoluteModal from '@/components/layout/absoluteModal';
import { DatePickerMethods } from '@/components/inputs/datePicker/datePickerMethods';

export default {
    name: 'datePickerHolder',
    components: {
        dateRangePicker,
        absoluteModal,
    },
    props: {
        // dateDisplay: {
        //     type: String,
        //     required: true,
        // },
        fromDate: {
            type: String,
            required: true,
        },
        toDate: {
            type: String,
            required: true,
        },
        dateInfo: {
            type: Object,
            required: true,
        },
        dateDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        updateTextOverride: {
            type: String,
            required: false,
        },
        dateClickDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        dateChanging: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideDateRangePicker: {
            type: Boolean,
            default: false,
        },
        onlyAllowSingleDay: {
            type: Boolean,
            default: false,
        },
        fromHours: {
            type: Number,
            required: false,
        },
        toHours: {
            type: Number,
            required: false,
        },
        hideTimes: {
            type: Boolean,
            required: false,
        },
        isHeader: {
            type: Boolean,
            required: false,
        },
        minimumView: {
            type: String,
            required: false,
            default: 'day',
        },
        disabledDate: {
            type: Object,
            required: false,
            default: null,
        },
        noModal: {
            type: Boolean,
            required: false,
            default: false,
        },
        aggregateOptionsInitial: {
            type: Object,
            required: false,
            default: null,
        },
        canAggregate: {
            type: Boolean,
            required: false,
            default: false,
        },
        tooltipOverride: {
            type: String,
            required: false,
        },
        disabledTooltipOverride: {
            type: String,
            required: false,
        },
        allowOverrideAll: {
            type: Boolean,
            required: false,
            default: false,
        },
        smallDate: {
            type: Boolean,
            required: false,
        },
    },
    directives: {

    },
    data () {
        return {
            moduleChartLoader,
            displayDateRange: false,
        };
    },
    created () {
        if (this.noModal) this.displayDateRange = true;
    },
    methods: {
        toggleDatePicker () {
            if (this.dateClickDisabled || this.lockedAccount) return;
            if (this.displayDateRange || !this.dateChanging) {
                this.displayTimeSelector = false;
                if (!this.displayDateRange) {
                    this.selectedDateOptionId = 'date';
                }
                this.displayDateRange = !this.displayDateRange;
            }
        },
        async dateChange (dateFormat, aggregateOptions) {
            this.$emit('dateChange', dateFormat, aggregateOptions);
        },
    },
    computed: {
        dateInfoToUse() {
            return this.$store.state.data.analysisDate.dashboard.overwriteDashboardDate
                ? { date: this.$store.getters.headerDates.dateObject }
                : this.dateInfo;
        },
        showModal () {
            return this.displayDateRange && (this.selectedDateOptionId === 'aggregate' || !this.hideDateRangePicker);
        },
        dateDisplay () {
            const fromDate = this.$store.state.data.analysisDate.dashboard.overwriteDashboardDate
                ? this.$moment(this.$store.getters.fromHeaderDate).format('DDMMYYYY')
                : this.fromDate;
            const toDate = this.$store.state.data.analysisDate.dashboard.overwriteDashboardDate
                ? this.$moment(this.$store.getters.toHeaderDate).format('DDMMYYYY')
                : this.toDate;
            const title = DatePickerMethods.getTitle(fromDate, this.onlyAllowSingleDay ? null : toDate, this.minimumView, this.fromHours, this.toHours);
            return title;
        },
        userSettings () {
            return this.$store.state.user;
        },
        lockedAccount () {
            return this.userSettings.lockedAccount === 'editLocked';
        },
        getTooltipText () {
            if (this.dateDisabled) {
                return this.disabledTooltipOverride ?? 'Date overwritten';
            } else {
                return this.tooltipOverride ?? (this.lockedAccount ? 'Date change disabled in User Settings' : 'Change dates displayed on chart');
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.datePickerHolder {
    position: relative;
    @include responsive("sm") {
        position: static;
    }
}
.dateDisplay {
    color: $primary;
    font-weight: 450;
    cursor: pointer;
    background: var(--mid);
    z-index: 2;
    border-radius: $border-radius;
    padding: 5px;
    height: 25px;
    &.isHeader {
        background: rgba(0,0,0,0);
        font-size: 20px;
        @media (max-width: 1536px) {
            margin-top: 3px;
            font-size: 16px;
        }
    }
    .dateLoaderSvg {
        position: absolute;
        top: 30px;
        right: 10px;
        height: 30px;
        width: 30px;
        font-size: 28px;
    }
    &.disabled {
        color: grey;
    }
    &.clickDisabled {
        cursor: no-drop;
    }
}
.modal {
    position: absolute;
    top: 5px;
    max-height: 500px;
    // max-width: 100%;
    min-width: min(100%, 570px);
    // max-width: min(100%, 570px);
}
@include responsive("smUp") {
    .modal {
        left: 50%;
        transform: translateX(-50%);
        &.isHeader {
            top: 45px;
        }
    }
}
@include responsive("sm") {
    .modal {
        left: 0;
    }
}
</style>
