var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    staticClass: "disableIcon",
    class: "icofont-lock " + _vm.accessLevelClass,
    attrs: { "v-tooltip": _vm.tooltip },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }