var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "absoluteModal",
    {
      staticClass: "settingsWrapper",
      attrs: { showDarkBackground: "" },
      on: { close: _vm.onClickOutside },
    },
    [
      _c(
        "div",
        {
          staticClass: "button",
          on: {
            click: function ($event) {
              return _vm.$emit("share")
            },
          },
        },
        [
          _vm._v(" Share dashboard with users "),
          _c("i", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value:
                  "This will share an existing dashboard into your coworkers list of dashboards.",
                expression:
                  "'This will share an existing dashboard into your coworkers list of dashboards.'",
              },
            ],
            staticClass: "infoIcon icofont-info",
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "button",
          on: {
            click: function ($event) {
              return _vm.$emit("save")
            },
          },
        },
        [
          _vm._v(" Save dashboard to company repository "),
          _c("i", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value:
                  "This will save an existing dashboard into your company's starting pre-made dashboards.<br/>So existing users can create a dashboard from this as a base.",
                expression:
                  "'This will save an existing dashboard into your company\\'s starting pre-made dashboards.<br/>So existing users can create a dashboard from this as a base.'",
              },
            ],
            staticClass: "infoIcon icofont-info",
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }