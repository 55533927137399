<template>
  <absoluteModal showShadow title="Table Filters" class="filterWrapper" @close="onClickOutside">
    <!-- <div class="checkWrapper"> -->
    <component :is="internalFooterTeleport ? 'portal' : 'div'" to="tableOptions">
      <draggable v-model="headersToOrder" @end="headerOrderChange" class="checkWrapper niceScroll" :key="resetNo">
        <div v-for="header in headersToOrder" :key="header.id" class="check card" :class="internalFooterTeleport ? 'darker' : 'midder'">
          <span v-if="!disableReorder" class="icofont-drag" v-tooltip="'Click and drag to reorder'" />
          <p-check
            name="check"
            class="p-icon p-square p-fill p-smooth"
            color="primary"
            v-model="columnsToShow[header.id]"
            @change="selectHeader(header.id)">
            <i slot="extra" class="icon icofont-check" />
            {{header.title}}
          </p-check>
        </div>
      </draggable>
      <div style="margin-top: 10px">
        <h3>Table Units:</h3>
        <buttonHolder
          smaller
          :initialSelectedId="tableUnit"
          :options="[{
            id: 'inherit', name: 'Inherit', info: 'Table will default to the site-wide setting (see Settings panel)',
          }, {
            id: 'cell', name: 'In Cell',
          }, {
            id: 'header', name: 'In Header',
          }, {
            id: 'hidden', name: 'Hidden',
          }]"
          @select="v => $emit('tableUnitValue', v.id)" />
      </div>
      <h2><span style="font-weight: 700">Note:</span> To filter a header, please select it on the table.</h2>
      <div class="flex">
        <div>
          <div class="button smaller" @click="resetHeaders" v-tooltip="'Remove all filters from each header on the table'">Reset Headers</div>
          <div class="button smaller" @click="reset" v-tooltip="'Reset order and hidden headers'">Reset Table</div>
        </div>
      </div>
    </component>
  </absoluteModal>
</template>

<script>

import Vue from 'vue';
import draggable from 'vuedraggable';
import PortalVue from 'portal-vue';
import absoluteModal from '@/components/layout/absoluteModal';
import buttonHolder from '@/components/layout/buttonHolder';

Vue.use(PortalVue);

export default {
    props: {
        firstHeaders: {
            type: Array,
            required: true,
        },
        secondHeaders: {
            type: Array,
            required: true,
        },
        headersToHide: {
            type: Array,
            required: true,
        },
        disableReorder: {
            type: Boolean,
            required: false,
            default: false,
        },
        tableUnit: {
            type: String,
            required: false,
            default: 'inherit',
        },
        internalFooterTeleport: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    created () {
        this.initialise();
    },
    data () {
        return {
            columnsToShow: {},
            headersToOrder: [],
            resetNo: 0,
        };
    },
    components: {
        absoluteModal,
        draggable,
        buttonHolder,
    },
    computed: {

    },
    methods: {
        initialise () {
            this.setInitialHeaders();

            this.headersToOrder.forEach(header => {
                this.columnsToShow[header.id] = !this.headersToHide.includes(header.id);
            });
        },
        setInitialHeaders () {
            if (this.firstHeaders == null) this.headersToOrder = this.secondHeaders;
            if (this.secondHeaders == null) this.headersToOrder = this.firstHeaders;
            else this.headersToOrder = [...this.firstHeaders, ...this.secondHeaders].filter(header => !header.isHeaderParent);
        },
        onClickOutside (event, el) {
            this.$emit('close');
        },
        selectHeader () {
            const columnsToEmitHide = Object.keys(this.columnsToShow).filter(el => !this.columnsToShow[el]);
            this.$emit('hide', columnsToEmitHide);
        },
        headerOrderChange () {
            if (this.disableReorder) return;
            this.$emit('orderChange', this.headersToOrder.map(header => header.id));
        },
        resetHeaders () {
            this.$emit('resetAllHeaderFilters');
            this.$emit('close');
        },
        reset () {
            this.headersToOrder.forEach(header => {
                Vue.set(this.columnsToShow, header.id, true);
            });
            this.selectHeader();
            this.headersToOrder = [];
            this.headerOrderChange();
            this.$emit('close');

            this.setInitialHeaders(); // For anything that doesn't listen to the emit
            this.resetNo++;
        },
    },
    watch: {
        firstHeaders () {
            this.initialise();
        },
        secondHeaders () {
            this.initialise();
        }
    }
};
</script>

<style lang="scss" scoped>
.checkWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: 300px;
    .check {
        // flex-basis: 33.333%;
        margin-bottom: 5px;
        padding: 5px;
        flex-grow: 1;
        // margin: 1px;
        margin-right: 5px;
        .icofont-drag {
            padding-right: 5px;
        }
    }
}
.flex {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .button {
        margin-right: 5px;
    }
}
h2 {
    color: $ALTERNATIVE1;
    font-weight: 500;
    margin-top: 10px;
}
h3 {
    font-weight: 600;
}
.filterWrapper {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
}
</style>
