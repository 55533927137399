<template>
    <div class="searchDiv section" :class="{ 'textInputted': !showPlaceholder }">
        <div v-show="showPlaceholder" class="placeholder">
            <span class="text">Search platform</span>
            <font-awesome-icon class="icon" :icon="['fas', 'search']" />
        </div>
        <div class="card card__modal searchModal" :class="{'setHeight': !showHelperText}">
            <div class="helperDiv" v-if="showHelperText">
                <div class="title">Try:</div>
                <div class="option">Search for a plant.</div>
                <div class="option">Search for an Enact series or chart.</div>
                <div class="option">Search for an energy definition in our database.</div>
            </div>
            <div class="searchResults" v-else>
                <div class="card searchSection" v-for="(sectionResults, sectionKey) in results" :key="sectionKey" v-if="sectionResults.optionHolder.options.length !== 0">
                    <div class="title">{{sectionResults.title}}</div>
                    <div class="results niceScroll">
                        <div class="result" v-for="(result, resultKey) in sectionResults.optionHolder.options" :key="resultKey" @click="selectResult(sectionResults, result)">
                            <div
                                class="icon"
                                :class="`icofont-${result.icon || 'light-bulb'}`"
                                :style="{ background: result.colour || 'rgb(0, 47, 95)' }"
                            ></div>
                            <div class="text">
                                <span class="id" v-if="sectionResults.showId">{{result.id}}</span>
                                <span class="name" :class="{ hideNameMobile: sectionResults.showId }">{{result.name}}</span>
                                <span class="owner" v-if="result.owner">{{result.owner}}</span>
                            </div>
                        </div>
                        <div class="capped" v-if="sectionResults.optionHolder.capped">
                            <strong>Note:</strong> Results shown are capped
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <input v-model="inputText" class="searchInput" @focus="inputFocussed = true" @blur="inputFocussed = false" />
    </div>
</template>

<script>


import { endpoints } from '@/components/inputs/countryInfo';

export default {
    name: 'enactSearch',
    props: {

    },
    created () {
        this.getSearchDefinitions();
    },
    components: {

    },
    data () {
        return {
            inputText: '',
            inputFocussed: false,
            searchDefinitions: [],
        };
    },
    methods: {
        filterOptions (options) {
            let filteredOptions = options;
            this.inputText.split(' ').forEach(searchTerm => {
                filteredOptions = filteredOptions.filter(option => option.searchQuery.toLowerCase().includes(searchTerm.toLowerCase()));
            });
            return {
                options: filteredOptions.slice(0, 100),
                capped: filteredOptions.length > 100,
            };
        },
        selectResult (resultHolder, result) {
            this.inputText = '';
            resultHolder.click(result);
        },
        getSearchDefinitions () {
            this.$axios.get(`${process.env.VUE_APP_STATIC_API}/staticApi/Definitions/Search`).then((response) => {
                if (response.data) {
                    response.data.forEach(def => {
                        this.searchDefinitions.push(
                            {
                                id: def.id,
                                searchQuery: def.searchQuery,
                                name: def.title,
                                icon: def.icon,
                                colour: def.colour,
                            },
                        );
                    });
                }
            });
        },
        getEndpointOptions (plantsForEndpoint, endpointName) {
            const plants = [];

            Object.keys(plantsForEndpoint).forEach(country => {
                plants.push(...plantsForEndpoint[country]);
            });

            const endpointOptions = {
                title: `${endpointName} Plants`,
                click: (option) => {
                    this.$router.push(`/plant/${option.id}`);
                },
                optionHolder: this.filterOptions(plants),
                showId: true,
            };

            return endpointOptions;
        },
    },
    computed: {
        showPlaceholder () {
            return !this.inputFocussed && this.inputText === '';
        },
        showHelperText () {
            return this.inputText === '';
        },
        results () {
            if (this.inputText === '') return null;

            const allSearchResults = [];

            const gbPlants = [];
            Object.keys(this.$staticStore.state.data.allPlants).forEach(category => {
                if (category === 'Owner') return;
                gbPlants.push(...this.$staticStore.state.data.allPlants[category].plants);
            });

            const gbOptions = {
                title: 'GB Plants',
                click: (option) => {
                    this.$router.push(`/plant/${option.id}`);
                },
                optionHolder: this.filterOptions(gbPlants),
                showId: true,
            };

            allSearchResults.push(gbOptions);

            const plantsByEndpoint = this.$staticStore.state.data.plantsByEndpoint;

            Object.keys(plantsByEndpoint).forEach(endpointId => {
                if (endpointId === 'gb') return;
                const endpointData = endpoints.find(x => x.id === endpointId);
                const endpointOptions = this.getEndpointOptions(plantsByEndpoint[endpointId], endpointData.name);
                if (endpointOptions === null) return;
                allSearchResults.push(endpointOptions);
            });

            const glossaryOptions = {
                title: 'Glossary',
                click: (option) => {
                    this.$store.commit('initiateSlideOutContent', {
                        data: { title: '', maxHeight: true },
                        component: 'components/slideOut/definition.vue',
                        props: {
                            id: option.id,
                        },
                    });
                },
                optionHolder: this.filterOptions(this.searchDefinitions),
                showId: false,
            };

            allSearchResults.push(glossaryOptions);

            return allSearchResults;
        },
    },
    watch: {

    },
};
</script>

<style lang="scss" scoped>
@import '@/components/dashboard/dashboardIconStyling.scss';

    .card {
        &.card__modal{
            background: var(--dark);
        }
    }
.searchDiv {
    transition: all $modal-transition ease-in-out;

    position: relative;
    width: 200px;
    @include responsive("sm") {
        width: 100%;
    }
    &:hover, &.textInputted {
        .searchModal {
            opacity: 1;
            transition-delay: 0.4s;
        }
    }
    .searchInput {
        background: transparent;
        border: none;
        color: var(--primaryFont);
        height: 30px;
        width: 100%;
        padding: 8px;
    }

    .searchModal {
        transition: all $modal-transition ease-in-out;
        transition-delay: 0s;
        opacity: 0;
        position: absolute;
        top: 40px;
        right: 1px;
        width: 400px;
        max-height: 500px;
        padding: 10px;
        pointer-events: none;
        @include responsive("sm") {
            z-index: 1;
            width: calc(100% + 20px);
            left: -10px;
            border-radius: 0 0 $border-radius $border-radius;
            padding: 0;
            height: 100%;
        }
        &.setHeight {
            height: 500px;
        }
        .helperDiv {
            color: var(--primaryFont);
            @include responsive("sm") {
                display: none;
            }
            .title {
                font-size: 18px;
                font-weight: 600;
                padding-bottom: 10px;
            }
            .option {
                font-style: italic;
                padding-top: 10px;
            }
        }
        .searchResults {
            pointer-events: auto;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            max-height: 100%;
            .searchSection {
                // height: 100%;
                margin: 5px;
                flex: 1 1 auto;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                min-height: 150px;
                .title {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }
                .results {
                    flex: 1 1 auto;
                    .result {
                        display: flex;
                        flex-direction: row;
                        height: 55px;
                        padding: 5px;
                        margin-bottom: 5px;
                        cursor: pointer;
                        &:hover {
                            background: var(--dark);
                        }
                        .icon {
                            border-radius: $border-radius;
                            font-size: 30px;
                            line-height: 35px;
                            padding: 5px;
                            margin-right: 10px;
                        }
                        .text {
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            .hideNameMobile {
                                @include responsive("sm") {
                                    display: none;
                                }
                            }
                            .owner {
                                @include responsive("sm") {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .placeholder {
        position: absolute;
        pointer-events: none;
        width: 100%;
        display: flex;
        justify-content: space-between;
        top: 0;
        left: 0;
        padding: 8px;
        .text {

        }
        .icon {
            margin-left: 10px;
        }
    }
}
</style>
