const routes = [{
    path: '/',
    name: 'currentHolder',
    meta: {
        requiresAuth: false,
        displayName: 'The Current',
    },
}, {
    path: '/current-news',
    name: 'currentNews',
    meta: {
        requiresAuth: false,
        displayName: 'Current News',
    },
}, {
    path: '/login',
    name: 'login',
    component: false,
    meta: {
        requiresAuth: false,
        displayName: 'Login',
    },
}, {
    path: '/logout',
    name: 'logout',
    component: false,
    meta: {
        requiresAuth: false,
        displayName: 'Logout',
    },
}, {
    path: '/monthly-summary',
    name: 'monthlySummary',
    meta: {
        requiresAuth: false,
        displayName: 'Monthly Summary',
    },
}, {
    path: '/winter-summary',
    name: 'winterSummary',
    meta: {
        requiresAuth: false,
        displayName: 'Winter Summary',
    },
}, {
    path: '/map-page',
    name: 'mapPage',
    meta: {
        requiresAuth: true,
        displayName: 'Map',
        videoLink: 'https://player.vimeo.com/video/508407148',
    },
},
{
    path: '/bingo-map',
    name: 'mapLive',
    meta: {
        requiresAuth: true,
        displayName: 'Bingo Map',
    },
},
{
    path: '/zonal-build-up',
    name: 'zonalBuildUp',
    meta: {
        requiresAuth: true,
        displayName: 'Zonal Build-Up',
    },
},
{
    path: '/europe-map-page',
    name: 'europeMapPage',
    meta: {
        requiresAuth: true,
        displayName: 'Europe Map',
        videoLink: 'https://player.vimeo.com/video/508407050',
    },
},
{
    path: '/wind-forecasting',
    name: 'windForecasting',
    meta: {
        requiresAuth: true,
        displayName: 'Wind Forecasting',
    },
},
{
    path: '/weather-forecasting',
    name: 'weatherForecasting',
    meta: {
        requiresAuth: true,
        displayName: 'Weather Forecasting',
    },
},
{
    path: '/news',
    name: 'news',
    meta: {
        requiresAuth: true,
        displayName: 'News',
    },
},
{
    path: '/leaderboard',
    name: 'bmLeaderboard',
    meta: {
        requiresAuth: false,
        displayName: 'Leaderboard',
        videoLink: 'https://player.vimeo.com/video/508408080',
    },
},
{
    path: '/plantActivityBoard',
    name: 'plantActivityBoard',
    meta: {
        requiresAuth: true,
        displayName: 'Plant Activity',
        videoLink: 'https://player.vimeo.com/video/508408410',
    },
},
{
    path: '/boa-dashboard/:date?',
    name: 'boa',
    meta: {
        requiresAuth: true,
        displayName: 'BOA Dashboard',
        videoLink: 'https://player.vimeo.com/video/508406655',
    },
},
{
    path: '/boa-comparer',
    name: 'boaComparer',
    meta: {
        requiresAuth: true,
        displayName: 'BOA Comparer',
        videoLink: 'https://player.vimeo.com/video/508406565',
    },
},
{
    path: '/terreDashboard',
    name: 'terreDashboard',
    meta: {
        requiresAuth: true,
        displayName: 'TERRE Dashboard',
    },
},
{
    path: '/terreMarginal',
    name: 'terreMarginalChart',
    meta: {
        requiresAuth: true,
        displayName: 'TERRE Marginal',
    },
},
{
    path: '/plant/:plantId/:dateFrom?/:dateTo?',
    name: 'Plant',
    meta: {
        requiresAuth: false,
        displayName: 'Plant',
    },
},
{
    path: '/glossary',
    name: 'glossaryPage',
    meta: {
        requiresAuth: true,
        displayName: 'Glossary',
    },
},
{
    path: '/dashboard/:dashboardId?',
    name: 'dashboard',
    meta: {
        requiresAuth: true,
        displayName: 'User Dashboard',
        videoLink: 'https://player.vimeo.com/video/508407727',
    },
},
{
    path: '/single-chart/:seriesId/:optionId/:countryId/:date',
    name: 'singleChart',
    meta: {
        requiresAuth: true,
        displayName: 'Single Chart',
    },
},
{
    path: '/mute-data-check/:seriesId?/:countryId?',
    name: 'muteDataCheck',
    meta: {
        requiresAuth: true,
        displayName: 'Mute Data Check',
    },
},
{
    path: '/remit-summary',
    name: 'Remit',
    meta: {
        requiresAuth: true,
        displayName: 'Unavailability',
        videoLink: 'https://player.vimeo.com/video/508408544',
    },
},
{
    path: '/notifications',
    name: 'NotificationsPage',
    meta: {
        requiresAuth: true,
        displayName: 'Notifications',
        videoLink: 'https://player.vimeo.com/video/508408268',
    },
},
{
    path: '/bingo-card',
    name: 'bingoCard',
    meta: {
        requiresAuth: true,
        displayName: 'Bingo',
        videoLink: 'https://player.vimeo.com/video/508406370',
    },
},
{
    path: '/historic-analysis',
    name: 'historicAnalysis',
    meta: {
        requiresAuth: true,
        displayName: 'Historic Analysis',
        videoLink: 'https://player.vimeo.com/video/508407243',
    },
},
{
    path: '/ancillary-contracts',
    name: 'ancillaryContracts',
    meta: {
        requiresAuth: true,
        displayName: 'Ancillary Contracts',
        videoLink: 'https://player.vimeo.com/video/508406072',
    },
},
{
    path: '/404',
    name: '404',
    meta: {
        requiresAuth: true,
        displayName: '404',
    },
},
{
    path: '/admin',
    name: 'admin',
    meta: {
        requiresAuth: true,
        displayName: 'Admin',
    },
},
{
    path: '/status',
    name: 'status',
    meta: {
        requiresAuth: true,
        displayName: 'Status',
    },
},
{
    path: '/forecast-history',
    name: 'forecastHistory',
    meta: {
        requiresAuth: true,
        displayName: 'Forecast History',
        videoLink: 'https://player.vimeo.com/video/508407496',
    },
},
{
    path: '/plant-table',
    name: 'plantTable',
    meta: {
        requiresAuth: false,
        displayName: 'Plant Table',
    },
},
{
    path: '/epex-grid',
    name: 'epexGrid',
    meta: {
        requiresAuth: true,
        displayName: 'EPEX Grid',
        videoLink: 'https://player.vimeo.com/video/508407007',
    },
},
{
    path: '/releases',
    name: 'releases',
    meta: {
        requiresAuth: true,
        displayName: 'Releases',
    },
},
{
    path: '/storage-da-analysis',
    name: 'dayAheadDashboard',
    meta: {
        requiresAuth: true,
        displayName: 'DA Analysis',
    },
},
{
    path: '/ffr-analysis',
    name: 'monthAheadDashboard',
    meta: {
        requiresAuth: true,
        displayName: 'FFR Analysis',
    },
},
{
    path: '/europe-ancillary-summary',
    name: 'europeAncillarySummary',
    meta: {
        requiresAuth: false,
        displayName: 'Ancillary Summary',
    },
},
{
    path: '/my-account',
    name: 'myAccount',
    meta: {
        requiresAuth: true,
        displayName: 'My Account',
    },
},
{
    path: '/twitterScrape/:day/:month/:year',
    name: 'twitterScrape',
    meta: {
        requiresAuth: true,
        displayName: 'Twitter Scrape',
    },
},
{
    path: '/access-denied',
    name: 'denied',
    meta: {
        displayName: 'Access Denied',
    },
},
{
    path: '/terms',
    name: 'termsInPlatform',
    meta: {
        requiresAuth: false,
        displayName: 'Ts&Cs',
    },
},
{
    path: '/externalinstructions',
    name: 'ApiInstructions',
    meta: {
        requiresAuth: true,
        displayName: 'API Instructions',
    },
},
{
    path: '/market-reports',
    name: 'marketReports',
    meta: {
        requiresAuth: false,
        displayName: 'Market Reports',
        isLongTerm: true,
    },
},
{
    path: '/asset-reports',
    name: 'assetReports',
    meta: {
        requiresAuth: false,
        displayName: 'Asset Reports',
        isLongTerm: true,
    },
},
{
    path: '/forecast-comparison',
    name: 'enVisionForecastComparison',
    meta: {
        requiresAuth: false,
        displayName: 'Forecast Comparison',
        isLongTerm: true,
    },
},
{
    path: '/freq-out',
    name: 'freqOut',
    meta: {
        requiresAuth: false,
        displayName: 'Freq Out',
    },
},
{
    path: '/nivBotReport',
    name: 'NivBotReportDownload',
    meta: {
        requiresAuth: true,
        displayName: 'NivBot Report Download',
    },
},
];

export { routes };
