var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _c("div", { staticClass: "modalWrapper" }, [
      _vm.showDarkBackground ? _c("div", { staticClass: "dark-bg" }) : _vm._e(),
      _c(
        "div",
        _vm._b(
          {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.onClickOutside,
                expression: "onClickOutside",
              },
            ],
            staticClass: "modal card",
            class: {
              popoverShadow: _vm.showShadow,
              boxShadow: _vm.showDarkBackground,
              midder: _vm.midCard,
              darker: _vm.darkCard,
              fitContent: _vm.fitContent,
            },
          },
          "div",
          _vm.$attrs,
          false
        ),
        [
          _c(
            "div",
            {
              staticClass: "header",
              style: { marginBottom: _vm.noTitleSpace ? "-10px" : "10px" },
            },
            [
              _c("span", { staticClass: "title bigTitle" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c("span", {
                staticClass: "close icofont-close",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.onClickOutside.apply(null, arguments)
                  },
                },
              }),
            ]
          ),
          _vm._t("default"),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }