<template>
<fixedModal showDarkBackground current @close="$emit('close')">
  <div class="getintouch">
    <section class="wrapper">
      <div class="container form">
        <section v-if="isSubmitted === false" class="form-holder">
        <div class="header section-heading">
          <h1 class="mainTitle">
            {{eworld ? 'Contact us about E-world' : 'Interested in LCP Energy?'}}
            <span v-if="eworld">
              Want to arrange a chat or join us for a drink at E-world?<br />A member of our team will get in touch.
            </span>
            <span v-else>
              Sign up to some insights from our Energy Market experts. Alternatively, the Enact team would be happy to arrange a demo for you.
            </span>
          </h1>
        </div>
        <div :class="errors.lenght ? 'error' : 'clean'" method="post">
          <form id="form" @submit.prevent="handleSubmit">
            <!-- <div class="form-content title">
                        <label for="title">Title:</label>
                        <select name="title" v-model="clientDetails.title">
                            <option value="Mr">Mr</option>
                            <option value="Miss">Miss</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Mrs">Other</option>
                        </select>
                    </div> -->
            <div class="form-content-holder">
              <div class="form-content firstname">
                <label for="first name"
                  >First Name<span class="required">*</span></label
                >
                <input
                  @keyup="checkForm"
                  type="text"
                  name="firstname"
                  v-model.trim="clientDetails.firstname"
                  @blur="checkForm"
                  :class="{ error: errors.fname }"
                />
              </div>
              <div v-if="errors.fname" class="error-highlight-message">
                {{ errors.fname }}
              </div>
            </div>
            <div class="form-content-holder">
              <div class="form-content lastname">
                <label for="last name"
                  >Last Name<span class="required">*</span></label
                >
                <input
                  @keyup="checkForm"
                  type="text"
                  name="lastname"
                  v-model.trim="clientDetails.lastname"
                  @blur="checkForm"
                  :class="{ error: errors.lname }"
                />
              </div>
              <div v-if="errors.lname" class="error-highlight-message">
                {{ errors.lname }}
              </div>
            </div>
            <div class="form-content-holder">
              <div class="form-content email">
                <label for="email">Email<span class="required">*</span></label>
                <input
                  @keyup="checkForm"
                  type="text"
                  name="email"
                  v-model.trim="clientDetails.email"
                  @blur="checkForm"
                  :class="{ error: errors.email }"
                />
              </div>
              <div v-if="errors.email" class="error-highlight-message">
                {{ errors.email }}
              </div>
            </div>
            <div class="form-content-holder">
              <div class="form-content company topSpace">
                <label for="company name"
                  >Company Name<span class="required">*</span></label
                >
                <input
                  @keyup="checkForm"
                  type="text"
                  name="company"
                  v-model.trim="clientDetails.companyName"
                  @blur="checkForm"
                  :class="{ error: errors.companyName }"
                />
              </div>
              <div v-if="errors.companyName" class="error-highlight-message">
                {{ errors.companyName }}
              </div>
            </div>
            <div class="form-content-holder">
              <div class="form-content jobtitle">
                <label for="job title"
                  >Job Title<span class="required">*</span></label
                >
                <input
                  @keyup="checkForm"
                  type="text"
                  name="jobtitle"
                  v-model.trim="clientDetails.jobTitle"
                  @blur="checkForm"
                  :class="{ error: errors.jobTitle }"
                />
              </div>
              <div v-if="errors.jobTitle" class="error-highlight-message">
                {{ errors.jobTitle }}
              </div>
            </div>
            <div class="form-content-holder">
              <div class="form-content country">
                <label for="country"
                  >Country<span class="required">*</span></label
                >
                <input
                  @keyup="checkForm"
                  type="text"
                  name="country"
                  v-model.trim="clientDetails.country"
                  @blur="checkForm"
                  :class="{ error: errors.country }"
                />
              </div>
              <div v-if="errors.country" class="error-highlight-message">
                {{ errors.country }}
              </div>
            </div>
            <div class="form-content-holder">
              <div class="form-content whereHeardFrom topSpace">
                <label for="whereHeardFrom" class="whereHeardFrom-content"
                  >How did you hear about Enact?<span class="required"
                    >*</span
                  ></label
                >
                <input
                  @keyup="checkForm"
                  type="text"
                  name="whereHeardFrom"
                  v-model.trim="clientDetails.whereHeardFrom"
                  @blur="checkForm"
                  :class="{ error: errors.whereHeardFrom }"
                />
              </div>
              <div v-if="errors.whereHeardFrom" class="error-highlight-message">
                {{ errors.whereHeardFrom }}
              </div>
            </div>
            <div class="form-content-holder">
              <div class="form-content checkboxHolder insight topSpace">
                <label for="enact" class="enact-content">
                  Find out more about LCP Enact</label
                >
                <input
                  @keyup="checkForm"
                  type="checkbox"
                  name="enact"
                  v-model.trim="clientDetails.enact"
                  @blur="checkForm"
                />
              </div>
            </div>
            <div class="form-content-holder">
              <div class="form-content checkboxHolder insight topSpace">
                <label for="insight" class="insight-content">
                  Receive further insights from our energy team</label
                >
                <input
                  @keyup="checkForm"
                  type="checkbox"
                  name="insight"
                  v-model.trim="clientDetails.insight"
                  @blur="checkForm"
                />
              </div>
            </div>
            <div class="form-content-holder">
              <div class="form-content checkboxHolder webinars">
                <label for="webinars" class="webinars-content">
                  Hear about future webinars and events from our energy team</label
                >
                <input
                  @keyup="checkForm"
                  type="checkbox"
                  name="webinars"
                  v-model.trim="clientDetails.webinars"
                  @blur="checkForm"
                />
              </div>
            </div>
            <div class="form-content privacy">
              <p>
                Please read our
                <span @click="handleModal"
                  ><strong>privacy policy</strong></span
                >
                to find out more about how we use your information.
              </p>
            </div>
            <!-- <div class="form-content button"> -->
              <button type="submit" class="form-content button cta link-color-lcp">
                <span>Submit</span
                >
              </button>
            <!-- </div> -->
          </form>
        </div>
         </section>
         <section v-if="isSubmitted" class="success-message">
           <h3>Thank you for getting in touch</h3>
           <p>If you requested a demo, the Enact team will get back to you as soon as possible.</p>
           <button type="button" @click="formReset" class="cta link-color-lcp"><span>Reset form</span
                ><svg
                  class="hover-arrow"
                  width="15"
                  height="15"
                  viewBox="0 0 10 10"
                  aria-hidden="true"
                >
                  <g fill-rule="evenodd">
                    <path class="hover-arrow-linePath" d="M0 5h7"></path>
                    <path class="hover-arrow-tipPath" d="M1 1l4 4-4 4"></path>
                  </g>
                </svg></button>
           </section>
      </div>
    </section>
  </div>
  </fixedModal>
</template>

<script>
// import { defineComponent, InputHTMLAttributes } from "vue";
import fixedModal from '@/components/layout/fixedModal';

export default {
    name: 'getInTouch',
    props: {
        eworld: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        fixedModal,
    },
    data () {
        return {
            clientDetails: {
                firstname: '',
                lastname: '',
                email: '',
                companyName: '',
                jobTitle: '',
                country: '',
                whereHeardFrom: '',
                insight: true,
                enact: false,
                webinars: true,
            },
            errors: {},
            isSubmitted: false,
            showPolicy: false,
        };
    },
    methods: {
        checkForm (event) {
            const elChanged = event
                ? (event.target).name
                : null;

            const {
                firstname,
                lastname,
                email,
                companyName,
                jobTitle,
                country,
                whereHeardFrom,
                insight,
                enact,
                webinars,
            } = this.clientDetails;

            const minLength = 3;

            let hasError = false;

            if (!elChanged || elChanged === 'firstname') {
                if (firstname === '') {
                    this.errors.fname = 'Please enter your first name.';
                    hasError = true;
                } else if (firstname.length < minLength) {
                    this.errors.fname = `Minimum length is ${minLength}`;
                    hasError = true;
                } else {
                    this.errors.fname = '';
                }
            }

            if (!elChanged || elChanged === 'lastname') {
                if (lastname === '') {
                    this.errors.lname = 'Please enter your last name.';
                    hasError = true;
                } else if (lastname.length < minLength) {
                    this.errors.lname = `Minimum length is ${minLength}`;
                    hasError = true;
                } else {
                    this.errors.lname = '';
                }
            }

            if (!elChanged || elChanged === 'email') {
                if (email === '') {
                    this.errors.email = 'Please enter your email';
                    hasError = true;
                } else if (email) {
                    const emailError = this.validateEmail(email);
                    if (emailError) hasError = true;
                }
            }

            if (!elChanged || elChanged === 'company') {
                if (companyName === '') {
                    this.errors.companyName = "Please enter your company's name";
                    hasError = true;
                } else if (companyName.length < minLength) {
                    this.errors.companyName = `Minimum length is ${minLength}`;
                    hasError = true;
                } else {
                    this.errors.companyName = '';
                }
            }

            if (!elChanged || elChanged === 'jobtitle') {
                if (jobTitle === '') {
                    this.errors.jobTitle = 'Please enter your job title';
                    hasError = true;
                } else if (jobTitle.length < minLength) {
                    this.errors.jobTitle = `Minimum length is ${minLength}`;
                    hasError = true;
                } else {
                    this.errors.jobTitle = '';
                }
            }

            if (!elChanged || elChanged === 'country') {
                if (country === '') {
                    this.errors.country = 'Please enter your country of operation';
                    hasError = true;
                } else if (country.length < minLength) {
                    this.errors.country = `Minimum length is ${minLength}`;
                    hasError = true;
                } else {
                    this.errors.country = '';
                }
            }

            if (!elChanged || elChanged === 'whereHeardFrom') {
                if (whereHeardFrom === '') {
                    this.errors.whereHeardFrom = 'Please enter how you heard about Enact';
                    hasError = true;
                } else if (whereHeardFrom.length < minLength) {
                    this.errors.whereHeardFrom = `Minimum length is ${minLength}`;
                    hasError = true;
                } else {
                    this.errors.whereHeardFrom = '';
                }
            }

            return hasError;
        },
        validateEmail (email) {
            const emailCheck = email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
            if (!emailCheck) {
                this.errors.email = 'Please enter a valid email';
                return true;
            }
            this.errors.email = '';
            return false;
        },
        handleSubmit () {
            const hasError = this.checkForm();
            if (hasError) return;

            this.clientDetails.firstname = `[CURRENT] ${this.clientDetails.firstname}`;
            if (this.eworld) this.clientDetails.firstname = `[E WORLD] ${this.clientDetails.firstname}`;

            fetch(
                'https://enact-functionapp-datachecks.azurewebsites.net/api/LandingDemoRequest',
                {
                    method: 'POST',
                    body: JSON.stringify(this.clientDetails),
                    mode: 'no-cors',
                },
            ).then((res) => {
                this.isSubmitted = true;
                // this.$gtag.event('Trial Enact', {
                //     event_category: 'Submission',
                //     event_label: 'Trial Enact',
                //     value: 50,
                // });
            });
        },

        handleModal () {
            this.showPolicy = true;
        // console.log('Show Policy:', this.showPolicy);
        },
        handleCloseModal () {
            this.showPolicy = false;
        // console.log('Show Policy:', this.showPolicy);
        },
        formReset () {
            this.isSubmitted = false;
            this.clientDetails = {
                firstname: '',
                lastname: '',
                email: '',
                companyName: '',
                jobTitle: '',
                country: '',
                whereHeardFrom: '',
                whereFrom: false,
                webinars: false,
                enact: false,
            };
            // console.log('I am clicked');
        },
    },
};
</script>

<style lang="scss" scoped>

.getintouch {
  // margin: 1rem;
  color: #0a2540;
  .wrapper {
    // margin-bottom: 10rem;
    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 1.5;
      .mainTitle {
        margin: 0px 7px 0px 7px;
        margin-right: 7px;
        font-size: 30px;
        font-weight: 1000;
      }
      h1 {
        text-align: center;
        span {
          display: block;
          margin: 40px 0 30px;
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
  }

  .form {
    width: 650px;
    margin-bottom: 20px;
    justify-content: center;
    // background: #ffffff;
    // padding: 2rem 5rem;
    border-radius: 5px;
    @include responsive(sm) {
      width: 100%;
      padding: 2rem 1rem;
    }

    label,
    input {
      display: block;
      font-size: 0.9rem;
    }

    label {
      font-weight: 600;
      margin-bottom: 10px;
      @include responsive(smUp) {
        margin-bottom: 0;
      }
    }

    input,
    select {
      height: 30px;
      border: none;
      padding: 1rem;
      background-color: #f0f1f1;
      border-radius: 2px;
      width: 70%;

      @include responsive(sm) {
        width: 100%;
      }

      &.error {
        // background-color: rgba($accentColourPrimary, 0.3);
      }
    }

    input:focus {
      outline: none;
      box-shadow: 0 0 0 1px $primary;
      background-color: transparent;
    }

    .form-content-holder {
      margin: 0px 20px 10px 20px;

      .form-content {
        &.checkboxHolder {
          input {
            width: auto;
            height: auto;
          }
        }

        &.topSpace {
          padding-top: 1.5rem;
        }
        display: flex;
        justify-content: space-between;
        .required {
          display: inline-block;
          color: #f00303;
          margin-left: 2px;
        }

        @include responsive(sm) {
          display: block;
        }
      }

      .whereHeardFrom-content {
        display: block;
        width: 150px;
        @include responsive(sm) {
          width: 100%;
        }
      }
    }

    .privacy {
      padding: 1em 0;
      font-size: 0.7rem;
      margin-left: 20px;

      p span {
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
      }
    }

    .formSubmitted {
      padding: 1em 0;
      font-size: 0.7rem;
      font-weight: 700;
      text-align: center;
    //   color: $blue;
    }

    .button {
      width: 200px;
      margin-left: 200px;
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      button {
        background: transparent;
        border: none;
        color: white;
        font-weight: 500;
        font-size: 1.2rem;
      }
    }
  }
  .error-highlight-message {
    // color: $accentColourPrimary;
    width: 100%;
    text-align: right;
  }
  .success-message {
    font-size: 1rem;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3{
      font-weight: 600;
    }
    p {
      text-align: center;
      margin: 20px 0;
    }
    button {
      border: none;
      cursor: pointer;
    }
  }
}
</style>
