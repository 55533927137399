import moment from 'moment-timezone';
import { uuid } from 'vue-uuid';
import store from '@/store';
import { staticStore } from '@/staticStore';
import cloneDeep from 'lodash/cloneDeep';

function getSeriesOptionId (seriesId, optionsId, country, calculatorIdArray = null) {
    if (calculatorIdArray !== null) return calculatorIdArray.join('');
    else {
        const id = `${country || 'Gb'}&${seriesId}&${optionsId || 'none'}`;
        if (staticStore.state.data.oldSeriesIdToNew.hasOwnProperty(id)) return staticStore.state.data.oldSeriesIdToNew[id];
        else return id;
    }
}

function deconstructId (id) {
    if (staticStore.state.data.oldSeriesIdToNew.hasOwnProperty(id)) id = staticStore.state.data.oldSeriesIdToNew[id];
    const splitId = id.split('&');
    return {
        seriesId: splitId[splitId.length === 3 ? 1 : 0],
        optionsId: splitId[splitId.length === 3 ? 2 : 1],
        country: splitId.length === 3 ? splitId[0] : 'Gb',
    };
}

function getConvertedSeries (seriesId, optionsId, country) {
    const joinedNewId = getSeriesOptionId(seriesId, optionsId, country);
    return deconstructId(joinedNewId);
}

function updateSeries (config, toAddTo) {
    config.forEach(configForId => {
        const seriesOptionString = deconstructId(configForId.id);
        seriesOptionString.isPlant = configForId.plantName !== null;
        seriesOptionString.multiplier = configForId.multiplier;
        if (configForId.backendColourOverride) seriesOptionString.colour = configForId.color;
        if (configForId.backendTypeOverride) seriesOptionString.chartType = configForId.type;
        if (configForId.nameOverride) seriesOptionString.name = configForId.name;
        if (configForId.yAxisUnitOverride) seriesOptionString.yAxisUnit = configForId.yAxisUnitEnum;
        toAddTo.push({ dates: [], series: seriesOptionString });
    });
}

function buildChart (chart, height = 'Medium', width = 'Medium', legendHeight = 'Medium') {
    const recentHour = chart.data.defaultTimeType === 'Hours';

    const daysBefore = chart.info.daysBefore === 0 ? chart.data.daysBefore : chart.info.daysBefore;
    const daysAfter = chart.info.daysAfter === 0 ? chart.data.daysAfter : chart.info.daysAfter;

    const date = (daysAfter === 0 && daysBefore === 0)
        ? { from: { type: 'date', value: 0 }, to: { type: 'date', value: 0 } }
        : {
            recentHour,
            from: { type: 'dynamic', value: recentHour ? 1 : daysBefore },
            to: { type: 'dynamic', value: recentHour ? 1 : daysAfter },
            fromHours: recentHour ? daysBefore : 1,
            toHours: recentHour ? daysAfter : 1,
        };

    const dbFormat = {
        chartSeries: [],
        date,
        id: chart.info.id,
        name: chart.info.name,
        height,
        width,
        legendHeight,
    };

    if (chart.data.axisToPercentage != null) {
        dbFormat.axisPositions = chart.data.axisToPercentage.map(el => ({ label: el.item1, value: el.item2, edited: true }));
    }
    if (chart.data.additionalDetails != null) {
        dbFormat.additionalDetails = chart.data.additionalDetails;
    }
    if (chart.data.axisSide != null) {
        dbFormat.axisSidePreset = chart.data.axisSide;
    }

    updateSeries(chart.data.config, dbFormat.chartSeries);

    return dbFormat;
}

function getStartingAdditionalDetails () {
    return {
        dashStyle: 'defaultBackend',
        dataLabelLocation: 'none',
        lineWidth: null,
        opacity: 1,
        suffix: null,
        prefix: null,
        summaryGradingGranularity: 'none',
        summaryGradingType: 'all',
        numberOfSeriesHof: 5,
        stackGroup: null,
    };
}

function buildAncillaryMetric (type) {
    return {
        info: {
            type: 'Metric',
            ancillary: type,
            id: uuid.v4(),
        },
    };
}

function buildOtherMetric (type) {
    return {
        info: {
            type: 'Metric',
            otherMetric: type,
            id: uuid.v4(),
        },
    };
}

function buildMetric (metricNames, metricType, fromDateOverride = null, toDateOverride = null) {
    const fromDate = fromDateOverride == null ? moment() : moment(fromDateOverride);
    const toDate = toDateOverride == null ? moment() : moment(toDateOverride);

    const fromDay = fromDate.format('DD');
    const fromMonth = fromDate.format('MM');
    const fromYear = fromDate.format('YYYY');
    const toDay = toDate.format('DD');
    const toMonth = toDate.format('MM');
    const toYear = toDate.format('YYYY');

    const id = uuid.v4();
    const dashboardFormat = {
        info: {
            metricNames,
            dates: {
                fromDay, fromMonth, fromYear, toDay, toMonth, toYear,
            },
            id,
            type: metricType,
        },
    };

    const databaseFormat = {
        metricNames,
        dates: { from: { type: 'date', value: 0 }, to: { type: 'date', value: 0 } },
        id,
    };

    return {
        databaseFormat,
        dashboardFormat,
    };
}

function addGroupPreset (preset, plantName, selectedCountry, parentId) {
    // eslint-disable-next-line no-undef
    const copiedPresets = cloneDeep(preset);

    const dbFormat = {
        modules: [],
        id: uuid.v4(),
        name: copiedPresets.info.name === 'Empty' || parentId !== null ? copiedPresets.info.name : `${copiedPresets.info.name} - ${selectedCountry.name}`,
        description: copiedPresets.info.description,
        favourite: false,
        parentId,
        colour: copiedPresets.info.colour,
        icon: `icofont-${copiedPresets.info.icon}`,
        enactType: store.state.ui.selectedEnactView,
    };

    copiedPresets.modules.forEach(dashboardModule => {
        switch (dashboardModule.type) {
        case 'Chart':
            dashboardModule.module.info.date = {
                from: { type: 'date', value: 0 },
                to: { type: 'date', value: 0 },
            };
            dashboardModule.module.info.id = uuid.v4();
            // if (parentId === null) dashboardModule.module.info.name += ` - ${selectedCountry.name}`;

            const chartDbFormat = buildChart(dashboardModule.module, dashboardModule.height, dashboardModule.width);
            if (plantName !== null) {
                chartDbFormat.chartSeries.forEach((seriesInfo) => {
                    seriesInfo.series.optionsId = plantName;
                });
            }
            dbFormat.modules.push(chartDbFormat);
            break;
        case 'ForecastHistoryChart':
            dbFormat.modules.push({
                info: {
                    id: uuid.v4(),
                    type: 'ForecastHistory',
                    height: dashboardModule.height,
                    width: dashboardModule.width,
                },
                data: {
                    seriesId: dashboardModule.module.series,
                    optionsId: dashboardModule.module.option,
                    daysAfter: dashboardModule.module.daysForward,
                    name: dashboardModule.module.name,
                    country: selectedCountry.id,
                },
            });
            break;
        case 'AncillaryChart':
            dbFormat.modules.push({
                info: {
                    id: uuid.v4(),
                    type: 'AncillaryChart',
                    height: dashboardModule.height,
                    width: dashboardModule.width,
                },
                data: {
                    seriesId: dashboardModule.module.series,
                    optionsId: dashboardModule.module.option,
                    type: dashboardModule.module.type,
                },
            });
            break;
        case 'LiveTable':
            dbFormat.modules.push({
                info: {
                    id: uuid.v4(),
                    type: 'LiveTable',
                    height: dashboardModule.height,
                    width: dashboardModule.width,
                },
                data: {
                    moduleType: dashboardModule.module.type,
                    country: dashboardModule.module.country,
                },
                presetFilters: dashboardModule.module.filters,
            });
            break;
        case 'BoaLeaderboard':
            dbFormat.modules.push({
                info: {
                    id: uuid.v4(),
                    type: 'BoaLeaderboard',
                    height: dashboardModule.height,
                    width: dashboardModule.width,
                },
                data: {
                    type: dashboardModule.module.type,
                },
            });
            break;
        case 'Metric':
            dbFormat.modules.push({
                metricNames: dashboardModule.module,
                height: dashboardModule.height,
                width: dashboardModule.width,
                dates: {
                    from: { type: 'date', value: 0 },
                    to: { type: 'date', value: 0 },
                },
                id: uuid.v4(),
            });
            break;
        case 'AncillaryMetric':
            dbFormat.modules.push(buildAncillaryMetric(dashboardModule.module));
            break;
        case 'DialMetric':
            dashboardModule.module.metricType = null;
            dbFormat.modules.push({
                metricNames: dashboardModule.module,
                dates: {
                    from: { type: 'date', value: 0 },
                    to: { type: 'date', value: 0 },
                },
                id: uuid.v4(),
            });
            break;
        case 'BoaDashboard':
            dbFormat.modules.push({
                info: {
                    id: uuid.v4(),
                    type: 'BoaDashboard',
                    height: dashboardModule.height || 'Small',
                    width: dashboardModule.width || 'Small',
                },
                data: { moduleName: dashboardModule.module },
            });
            break;
        case 'Treemap':
            dbFormat.modules.push({
                info: {
                    id: uuid.v4(),
                    type: 'Treemap',
                    height: dashboardModule.height || 'Large',
                    width: dashboardModule.width || 'Large',
                },
            });
        }
    });

    return dbFormat;
}

function addParentPreset (preset, selectedCountry, appendUniqueId) {
    const dbParentFormat = {
        modules: [],
        id: uuid.v4(),
        name: preset.info.name,
        description: preset.info.description,
        favourite: false,
        colour: preset.info.colour,
        icon: `icofont-${preset.info.icon}`,
        isParent: true,
        enactType: store.state.ui.selectedEnactView,
    };

    const dashboardChildren = [];
    preset.modules.forEach(childPreset => {
        let dashboardToAdd;
        staticStore.state.data.dashboardPresets.some(group => {
            dashboardToAdd = group.dashboards.find(dashboard => dashboard.info.id === childPreset.module.id);
            return dashboardToAdd != null;
        });
        const dbChildFormat = addGroupPreset(dashboardToAdd, null, selectedCountry, dbParentFormat.id);
        dbParentFormat.modules.push({
            id: dbChildFormat.id,
            name: dbChildFormat.name,
            type: 'Dashboard',
        });

        if (appendUniqueId) dbChildFormat.uniqueId = childPreset.module.id;

        dashboardChildren.push(dbChildFormat);
    });
    return {
        dashboardToAdd: dbParentFormat,
        dashboardChildren: dashboardChildren.reverse(),
    };
}

async function convertDbFormatToDashboardFormat (currentViews, currentViewCopy, forecastDate, granularity, fromToOverride) {
    const modules = currentViews.modules;
    const charts = modules.filter((i) => i.hasOwnProperty('chartSeries'));
    const metrics = modules.filter((i) => i.hasOwnProperty('metricNames'));

    const returnObj = {
        groupsWatched: new Set(),
    };

    if (metrics.length > 0) {
        for (let metric = 0; metric < metrics.length; metric++) {
            const newFormat = {
                info: metrics[metric],
            };
            newFormat.info.type = metrics[metric].metricNames.metricType != null ? 'Metric' : 'DialMetric';

            const moduleIndex = currentViewCopy.modules.findIndex((i) => i.hasOwnProperty('id') && i.id === metrics[metric].id);
            currentViewCopy.modules[moduleIndex] = newFormat;
        }
    }
    if (charts.length > 0) {
        const handleChart = async (newFormat) => {
            const moduleIndex = currentViewCopy.modules.findIndex((i) => i.hasOwnProperty('id') && i.id === newFormat.info.id);
            currentViewCopy.modules[moduleIndex] = newFormat;
        };
        const copyCharts = currentViewCopy.modules.filter((i) => i.hasOwnProperty('chartSeries'));
        await getConfigs(copyCharts, returnObj, forecastDate, granularity, fromToOverride, handleChart);
    }

    return returnObj;
}

async function getConfigs (charts, returnObj, forecastDate, granularity, fromToOverride, handleChart) {
    const plantGroups = store.state.user.chosenPlantsByGroup;
    const toSend = [];
    charts.forEach(chart => {
        chart.chartSeries.forEach(series => {
            series.isForDarkMode = store.getters['user/isDarkMode'];
            if (isGroupSeries(series.series)) {
                series.series.plantsForGroup = plantGroups.hasOwnProperty(series.series.optionsId) ? plantGroups[series.series.optionsId] : [];
                if (returnObj !== null) returnObj.groupsWatched.add(series.series.optionsId);
            }
            series.series.fromToOverride = fromToOverride;
            if (series.series.optionsId !== null && series.series.optionsId !== undefined) {
                series.series.optionsId = series.series.optionsId.replace('ForecastDatePlaceholder', forecastDate);
                series.series.optionsId = series.series.optionsId.replace('GranularityPlaceholder', granularity);
            }
            const id = getSeriesOptionId(series.series.seriesId, series.series.optionsId, series.series.country, series.series.calculatorIdArray);
            const addDetails = chart.additionalDetails == null ? null : chart.additionalDetails[id];
            if (addDetails && addDetails.missingCalculatorOption) {
                series.missingCalculatorOption = addDetails.missingCalculatorOption;
            }
            if (addDetails && addDetails.numberOfSeriesHof) {
                series.numberToGet = addDetails.numberOfSeriesHof;
            }
        });
        toSend.push(chart);
    });
    await store.dispatch('series/jointSeriesConfig', toSend).then(async (response) => {
        for (let chart = 0; chart < toSend.length; chart++) {
            const newFormat = {
                data: response[toSend[chart].id],
                info: toSend[chart],
            };
            await handleChart(newFormat);
        }
    });
}

function getConvertedNumberAndPrefixSuffix (number, prefix, suffix) {
    if (number > 1000 && suffix.includes('MW')) {
        number = Math.round(((number / 1000) + Number.EPSILON) * 100) / 100;
        number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        suffix = suffix.replace('MW', 'GW');
    }
    return {
        number,
        prefix,
        suffix,
    };
}

function isAggregateSeries (series) {
    const type = getAggregateType(series);
    return type === 'fuel' || type === 'owner' || type === 'group' || type === 'zone' || type === 'historyOfForecast';
}

function getAggregateType (series) {
    if (series.isGroup || series.plantsForGroup != null) return 'group';
    if (series.isOwner) return 'owner';
    if (series.isFuel) return 'fuel';
    if (series.isZone) return 'zone';
    if (series.calculatorIdArray) return 'calculator';
    if (series.aggregateType) return series.aggregateType;
    return 'plain';
}

function isGroupSeries (series) {
    return getAggregateType(series) === 'group' || series.plantsForGroup != null;
}

function dashboardAllowedOnEnactType (dashboard, childInsteadOfParent) {
    if (childInsteadOfParent) {
        if (store.state.ui.selectedEnactView === 'EnVision' && dashboard.isParent) return false;
    } else {
        if (store.state.ui.selectedEnactView === 'EnVision' && dashboard.parentId != null) return false;
    }
    if (store.state.ui.selectedEnactView === 'Enact' && dashboard.enactType == null) return true;
    if (dashboard.enactType !== store.state.ui.selectedEnactView) return false;
    return true;
}

function getCountryNameFromId (countryId) {
    let countryName;
    staticStore.state.data.countries.forEach(country => {
        if (country.subCountries.length === 0) {
            if (country.id === countryId) {
                countryName = country.name;
                return true;
            }
            return false;
        } else {
            for (let index = 0; index < country.subCountries.length; index++) {
                const subCountry = country.subCountries[index];
                if (subCountry.id === countryId) {
                    countryName = subCountry.name;
                    return true;
                }
            }
            return false;
        }
    });

    return countryName;
}

function getSeriesNameOptionsNameCountryName (seriesId, optionsId, country, isCalculator, isPlant = false) {
    let seriesName, optionsName;

    const countryName = getCountryNameFromId(country);
    if (!isCalculator) {
        const convertedSeries = getConvertedSeries(seriesId, optionsId, country);
        seriesId = convertedSeries.seriesId;
        optionsId = convertedSeries.optionsId;
        country = convertedSeries.country;

        if (isPlant) {
            const series = staticStore.state.data.plantSeries.series[seriesId];

            seriesName = series.name;
            optionsName = optionsId;
        } else { // WAM TODO OptionsName
            const series = staticStore.state.data.dynamicSeriesNew.series[seriesId];
            seriesName = series.name;

            if (Object.keys(series.optionsByCountry).length > 0) {
                optionsName = '';
                Object.values(series.optionsByCountry[country].options).forEach((optionsGroup, index) => {
                    if (Object.keys(optionsGroup).length !== 1) {
                        const option = Object.values(optionsGroup).find(z => z.id === optionsId.split('*')[index]);
                        optionsName += option.name;
                    }
                });
            } else {
                optionsName = null;
            }
        }
    }

    return {
        seriesName,
        optionsName,
        countryName,
    };
}

export {
    buildChart,
    buildMetric,
    buildAncillaryMetric,
    buildOtherMetric,
    getSeriesOptionId,
    addGroupPreset,
    addParentPreset,
    convertDbFormatToDashboardFormat,
    getConvertedNumberAndPrefixSuffix,
    deconstructId,
    getSeriesNameOptionsNameCountryName,

    isGroupSeries,
    getConfigs,
    getAggregateType,
    isAggregateSeries,
    getStartingAdditionalDetails,

    dashboardAllowedOnEnactType,
};
