const tradingWindowOptions = [{
    id: 'all', name: 'Site Wide',
}, {
    id: 'boa', name: 'BOA Dashboard',
}, {
    id: 'boaComparer', name: 'BOA Comparer',
}, {
    id: 'dashboard', name: 'User Dashboard',
}, {
    id: 'plantActivityBoard', name: 'Plant Activity',
}]

export {
    tradingWindowOptions,
};
