var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "loader" },
      [
        _vm._t("default"),
        _vm.showLoader
          ? _c("img", {
              staticClass: "image",
              attrs: {
                src: require("@/assets/imgs/Logos/" +
                  (_vm.userSettings.colour === "Dark" ? "Dark" : "Light") +
                  "/enact-animated.svg"),
              },
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }