<template>
    <div class="headerRight">
        <feedback class="nonMobile" v-if="$store.state.ui.feedbackOpen" @close="toggleFeedback" />
        <dashboardShare class="nonMobile" v-if="dashboardShareOpen" @closeShare="toggleShare" />
        <dashboardSave class="nonMobile" v-if="dashboardSaveOpen" @closeShare="toggleSave"/>
        <div class="routerLinks nonMobile">
            <div class="headerIcon" style="cursor: pointer;" v-for="(icon, key) in iconsToShow" :key="key">
                <span @click="icon.click()" v-if="shouldShowOnHeader(icon)" v-tooltip="icon.name">
                    <font-awesome-icon :icon="['fad', icon.icon]" style="marginLeft: 10px" v-if="icon.fad"/>
                    <font-awesome-icon :icon="['fas', icon.icon]" style="marginLeft: 10px" v-else/>
                </span>
            </div>
            <sharePopout v-if="sharePopoutOpen" @share="toggleShare" @save="toggleSave" @close="sharePopoutOpen = false" />
        </div>
        <div
            class="notificationsIcon animated nonMobile"
            @click="toggleNotifications"
            v-tooltip="`Notifications`"
            :class="[{ active: unseenNotifications > 0 }, heartbeatClass]"
            :style="$store.state.ui.notificationsOpen ? 'z-index: 101' : ''"
            v-if="hasNotifications().hasAccess && pageName !== 'NotificationsPage' && $store.state.ui.selectedEnactView ==='Enact'"
        >
            <font-awesome-icon :icon="['fas', 'bell']" />
            <span class="count" v-if="unseenNotifications > 0">{{
                unseenNotifications
            }}</span>
        </div>
        <div
            class="notificationsIcon iconDisabled nonMobile"
            v-tooltip="`Notifications (Requires ${hasNotifications().accessRequired})`"
            v-if="!hasNotifications().hasAccess"
        >
            <span class="icofont-alarm icon_disable"></span>
            <disabled-icon
                class="disabledOverlay"
                :accessLevel="hasNotifications().accessRequired"
                :showTooltip="false"
            />
        </div>
        <div v-if="loggedIn">
            <font-awesome-icon class="userIcon" :icon="['fad', 'user']" @click="toggleUserModal"  v-tooltip="`My Account`"/>
            <userModal @close="toggleUserModal" v-if="userModalOpen" />
        </div>
        <div class="plantSearch nonMobile" v-if="loggedIn">
            <enactSearch2
                light
                :categoryOverrides="['BmPlant', 'NonBmPlant']"
                v-if="$store.state.ui.selectedEnactView ==='Enact'"
                @select="goToPlantPage">
            </enactSearch2>
        </div>
        <div v-if="!loggedIn">
            <div class="button smaller loginBtn" id="login" @click="login">
                Login
            </div>
            <a href="http://lcpenact.com" class="button smaller loginBtn grapefruit" target="_blank">
                Get Access
            </a>
        </div>
    </div>
</template>

<script>

import enactSearch2 from '@/components/inputs/enactSearch2';
import disabledIcon from '@/components/layout/disabledIcon';
import userModal from '@/components/header/userModal';
import feedback from '@/components/layout/feedback';
import dashboardShare from '@/components/layout/dashboardShare';
import dashboardSave from '@/components/layout/dashboardSave';
import sharePopout from '@/components/layout/sharePopout';

export default {
    name: 'RightHeader',
    components: {
        enactSearch2,
        disabledIcon,
        userModal,
        feedback,
        dashboardShare,
        dashboardSave,
        sharePopout,
    },
    data () {
        return {
            defaultAccess: {
                hasAccess: false,
                accessRequired: 'Enact Visualise',
            },
            currentPlant: null,
            heartbeatClass: '',
            userModalOpen: false,
            dashboardShareOpen: false,
            dashboardSaveOpen: false,
            sharePopoutOpen: false,
            icons: [{
                name: 'Share',
                icon: 'share-alt',
                availableOnMobile: false,
                click: () => {
                    if (this.pageName === 'dashboard') this.sharePopoutOpen = !this.sharePopoutOpen;
                    else this.toggleShare();
                },
                fad: false,
            },
            {
                name: 'Settings',
                icon: 'cogs',
                availableOnMobile: false,
                click: () => this.toggleSettings(),
                condition: '',
                fad: true,
            }, {
                name: 'Feedback',
                icon: 'comment-dots',
                availableOnMobile: false,
                click: () => this.toggleFeedback(),
                fad: false,
            }, {
                name: 'Tutorial',
                icon: 'question',
                availableOnMobile: false,
                click: () => this.toggleTutorial(),
                fad: false,
            }],
        };
    },
    methods: {
        shouldShowOnHeader (icon) {
            switch (icon.name) {
            case 'Tutorial':
                return this.loggedIn && this.$route.meta.videoLink;
            case 'Share':
                return this.$route.name === 'dashboard' || this.$route.name === 'bingoCard' || this.$route.name === 'NotificationsPage';
            default:
                return this.loggedIn;
            }
        },
        toggleSettings () {
            this.$store.commit('initiateSlideOutContent', {
                data: { title: '' },
                component: 'components/slideOut/settings.vue',
            });
        },
        toggleFeedback () {
            this.$store.commit('toggleFeedback');
        },
        toggleShare () {
            this.sharePopoutOpen = false;
            this.dashboardShareOpen = !this.dashboardShareOpen;
        },
        toggleSave () {
            this.sharePopoutOpen = false;
            this.dashboardSaveOpen = !this.dashboardSaveOpen;
        },
        toggleTutorial () {
            this.$store.commit('initiateSlideOutContent', {
                data: { title: `${this.$route.meta.displayName} Tutorial`, width: 50 },
                component: 'components/slideOut/tutorial.vue',
                props: {
                    videoLink: this.$route.meta.videoLink,
                },
            });
        },
        login () {
            this.$router.push('/login');
        },
        toggleNotifications () {
            this.$store.commit('toggleNotifications');
        },
        toggleFullscreen () {
            this.$emit('toggleFullscreen');
        },
        goToPlantPage (plant) {
            this.currentPlant = null;
            this.$router.push({ path: `/plant/${plant.id}` });
        },
        hasNotifications () {
            const access = this.$store.state.ui.pageAccessLevels.notifications;
            return access == null ? this.defaultAccess : access;
        },
        toggleUserModal () {
            this.userModalOpen = !this.userModalOpen;
        },
        notificationIsUnseen (notification) {
            if (notification.row.Unseen.hasOwnProperty('value')) return notification.row.Unseen.value === 'False';

            if (notification.row.Unseen.hasOwnProperty('values')) {
                return notification.row.Unseen.values[0] === 'False';
            }
            return false;
        },
    },
    computed: {
        loggedIn () {
            return !this.$store.state.ui.isFreeUser;
        },
        pageName () {
            return this.$store.state.ui.pageName;
        },
        unseenNotifications () {
            const tableData = this.$store.state.liveTable.storeTables;
            return tableData.hasOwnProperty('Notifications') ? tableData.Notifications.data.filter(
                (o) => this.notificationIsUnseen(o),
            ).length : 0;
        },
        iconsToShow () {
            return this.icons.filter(i => this.shouldShowOnHeader(i));
        },
    },
    watch: {
        unseenNotifications () {
            this.heartbeatClass = '';
            this.heartbeatClass = 'heartBeat';
        },
        // $route (to, from) {
        //     // if (from !== to) this.searchOpen = false;
        // },
    },
};
</script>

<style lang="scss" scoped>
.nonMobile{
    @include responsive("sm") {
        display: none !important;
    }
}
.headerRight {
    display: flex;
}
.notificationsIcon,
.headerIcon,
.userIcon {
    color: $primary;
    cursor: pointer;
    // float: right;
    // height: 40px;
    margin-top: 4px;
    padding: 0 10px;
    text-align: center;
    height: 25px;
    width: 50px;
    &.active {
        color: $ALTERNATIVE1;
    }
    svg, span {
        display: block;
        font-size: 24px;
        line-height: 22px;
    }
    .count {
        background: $BRIGHT;
        border-radius: 5px;
        color: black;
        font-size: 10px;
        font-weight: bold;
        height: 15px;
        line-height: 14px;
        position: absolute;
        right: 10px;
        top: 5px;
        /* width: 15px; */
        padding: 2px 2px;
    }
    &.fullscreen {
        margin-top: 4px;
    }
}
.userIcon,
.iconDisabled {
    position: relative;
    cursor: help;
    .icon_disable {
        color: grey;
        cursor: help;
    }
    .disabledOverlay {
        cursor: help;
        top: -5px;
        right: 0px;
        font-size: 20px;
        position: absolute;
    }
}
.notificationsIcon {
    position: relative;
}
.loginBtn {
    margin-left: 10px;
    padding: 0px 10px 5px 10px;
    text-decoration: none;
    @include responsive('sm') {
        font-size: 10px;
        font-weight: 600;
    }
    &.grapefruit {
        background-color: $ALTERNATIVE1;
    }
}
.routerLinks {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	// overflow-y: auto;
}
.plantSearch {
    display: inline-block;
    z-index: 11;
    .multiselect__option {
        padding: 5px;
    }
    .option__icon {
        width: 60px;
        display: inline-block;
        line-height: 30px;
        vertical-align: middle;
        margin-top: -20px;
        .icon {
            font-size: 30px;
            border-radius: 4px;
            padding: 5px;
            line-height: 30px;
        }
    }
    .option__desc {
        display: inline-block;
        width: calc(100% - 60px);
        .option__id {
            display: block;
            margin-bottom: 5px;
        }
        .option__name {
            display: block;
            font-size: 12px;
            opacity: 0.8;
        }
    }
}
</style>
